/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import RatePerHour from '../components/RatePerHour';


const UseStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      overflowX: 'auto'
    },
}));


const ratePerHour = () => {
    const classes = UseStyles();
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(localStorage.getItem('Token')){
            setLoggedIn(true);
        }
        
        setLoading(false);
    },[]);

    return(
        <div className={classes.root}>
            {!loggedIn && !loading ? <Navigate  to='/signin'/> : null}
            <Navbar />
            <Container maxWidth="lg" className={classes.container}>
            <div xs={12} md={12} lg={12}>
                <RatePerHour/>
            </div>
            </Container>
        </div>
    );
}

export default ratePerHour;
