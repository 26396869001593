import React, {useState, useEffect} from 'react';
import apiClient from "../../apiClient";

import { Document, Page, Text, View, Image, StyleSheet, Link, Font } from '@react-pdf/renderer';
import { TableHeader, DataTableCell, Table, TableBody, TableCell } from '@david.kucsai/react-pdf-table';

import signature from '../../assets/images/signature.png';
import logo1 from '../../assets/logos/8.1.png';
import moment from 'moment';

import source from '../../assets/fonts/Roboto/Roboto-Bold.ttf';

Font.register({
  family: "Roboto",
  src: source,
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingBottom: 55,
  },
  header: {
    width: "100%",
    backgroundColor: "#BDBDBD",
  },
  titleBox: {
    width: "250px",
    textAlign: "center",
    padding: 10,
    position: "absolute",
    top: 20,
    right: 20,
    backgroundColor: "#fed700",
  },
  titleBoxBPD: {
    width: "250px",
    textAlign: "center",
    padding: 10,
    position: "absolute",
    top: 20,
    right: 20,
    border: "1px dashed #BDBDBD",
    color: "#BDBDBD"
  },
  image: {
    width: "60px",
    height: "60px",
  },
  signature: {
    width: "160px",
    height: "60px",
    marginBottom: "-23px",
    marginLeft: "-10px"
  },
  company: {
    fontSize: "8px",
    position: "absolute",
    top: 23,
    left: 80,
  },
  links: {
    fontSize: "8px",
    position: "absolute",
    top: 23,
    left: 210,
  },
  link: {
    color: "#ad9300",
    textDecoration: "underline",
    paddingBottom: 3,
  },
  linkFMK: {
    color: "#2f5598",
    textDecoration: "underline",
    paddingBottom: 3,
  },
  flex: {
    flexDirection: "row",
    margin: "10px",
  },
  infoBox: {
    width: "50%",
    fontSize: "10px",
    padding: 10,
  },
  tableHeader: {
    fontSize: "8px",
    backgroundColor: "#BDBDBD",
    padding: 5,
    fontFamily: 'Roboto'
  },
  tableBody: {
    fontSize: "8px",
    padding: 5,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  topInfoBox: {
    paddingBottom: "10px",
    marginTop: "15px",
  },
  smallFont: {
    fontSize: "8px",
  },
  tinyFont: {
    fontSize: "5px",
    marginBottom: "-10px"
  },
  topBorder: {
    borderTop: "2px solid #000",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  invisibleBorderTableHeadRight: {
    borderRight: "1px solid #BDBDBD",
  },
  invisibleBorderTableHeadLeft: {
    borderLeft: "1px solid #BDBDBD",
  },
  invisibleBorderTableRight: {
    borderRight: "1px solid #FFFFFF",
  },
  invisibleBorderTableLeft: {
    borderLeft: "1px solid #FFFFFF",
  },
  invisibleBorder: {
    borderBottom: "3px solid white",
    borderLeft: "2px solid white",
    margin: "0",
    marginBottom: "-2px",
    marginLeft: "-2px",
    borderCollapse: "collapse!important",
    borderStyle: "hidden!important",
    outline: "none!important",
  },
});

function ccyFormat(num) {
  if(typeof num === 'number')
    return `${num.toFixed(2)}`;
  else
    return num;
}



export default function PayslipGenerator(props) {
  const [user, setUser] = useState({});
  const [roles, setRoles] = useState([]);
  var rolesArray = [];


  useEffect(() => {
    apiClient.get(
      `/api/users-by-id/${props.rowData.user_id}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    
    apiClient.get(
      `/api/roles/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        res.data.map((item) => {
          rolesArray[item.id] = item.name;
          return null;
        });
        setRoles(rolesArray);
      })
      .catch((e) => {
        console.log(e);
      });
  },[]);


  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={logo1} alt="" style={styles.image}/>
        <View style={styles.titleBoxBPD}>
          <Text>PAYSLIP</Text>
        </View>
        <View style={styles.company}>
          <Text style={{ fontFamily: 'Roboto' }}>BPD INVESTMENTS (PTY) LTD</Text>
          <Text>R7/60438</Text>
          <Text>TIN: 106-533-950</Text>
          <Text>Plot 40 Somhlolo Road</Text>
          <Text>Mbabane</Text>
          <Text>Eswatini</Text>
        </View>
        <View style={styles.links}>
          <Link src="https://bpdteam.com" style={styles.linkFMK}>www.bpdteam.com</Link>
          <Link src="mailto:info@bpdteam.com" style={styles.linkFMK}>info@bpdteam.com</Link>
          <Link src="tel:79360602" style={styles.linkFMK}>79360602</Link>
        </View>
        <View style={[styles.topInfoBox, styles.flex, {marginBottom: "-20px"}]}>
          <View style={[ styles.flex]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Employee Number: </Text>
            <Text style={styles.smallFont}>{user.code}</Text>
          </View>
        </View>
        <View style={[styles.topInfoBox, styles.flex, {marginBottom: "-20px"}]}>
          <View style={[ styles.flex]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Full Name: </Text>
            <Text style={styles.smallFont}>{user.title} {user.name}</Text>
          </View>
        </View>
        <View style={[styles.topInfoBox, styles.flex]}>
          <View style={[ styles.flex, {width: '180px'}]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Role: </Text>
            <Text style={styles.smallFont}>{roles[user.role_id]}</Text>
          </View>
          <View style={[ styles.flex, {width: '180px'}]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Employment Type: </Text>
            <Text style={styles.smallFont}>{user.employmentType}</Text>
          </View>
          <View style={[ styles.flex, {width: '180px'}]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Payment Date: </Text>
            <Text style={styles.smallFont}>{moment(props.rowData.date).format("DD MMM YYYY")}</Text>
          </View>
        </View>
        <View style={styles.cutLine}>
          <Text style={styles.tinyFont}>Confidencial Payslip Fold Line</Text>
          <Text>-------------------------------------------------------------------------------------------</Text>
        </View>
        <View style={[styles.flex, {marginBottom: "-20px"}]}>
          <View style={[ styles.flex]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Company Name: </Text>
            <Text style={styles.smallFont}>BPD INVESTMENTS (PTY) LTD t/a BPD TEAM</Text>
          </View>
        </View>
        <View style={[styles.flex, {marginBottom: "-20px"}]}>
          <View style={[ styles.flex]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>ID Number: </Text>
            <Text style={styles.smallFont}>{user.pin}</Text>
          </View>
        </View>
        <View style={[styles.flex, {marginBottom: "-20px"}]}>
          <View style={[ styles.flex]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Full Name: </Text>
            <Text style={styles.smallFont}>{user.title} {user.name}</Text>
          </View>
        </View>
        <View style={[styles.flex, {marginBottom: "-20px"}]}>
          <View style={[ styles.flex, {width: '180px'}]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Date of Birth: </Text>
            <Text style={styles.smallFont}>{moment(user.dateOfBirth).format("DD MMM YYYY")}</Text>
          </View>
          <View style={[ styles.flex, {width: '180px'}]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Employment Date: </Text>
            <Text style={styles.smallFont}>{moment(user.employmentDate).format("DD MMM YYYY")}</Text>
          </View>
          <View style={[ styles.flex, {width: '180px'}]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Graded Tax Number: </Text>
            <Text style={styles.smallFont}>{user.gradedTax}</Text>
          </View>
        </View>
        <View style={[styles.flex, {marginBottom: "-20px"}]}>
          <View style={[ styles.flex, {width: '180px'}]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Address: </Text>
            <View>
              <Text style={styles.smallFont}>{user.address}</Text>
              <Text style={styles.smallFont}>{user.area}</Text>
              <Text style={styles.smallFont}>{user.town}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.flex]}>
          <View style={[ styles.flex, {width: '180px'}]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Bank Name: </Text>
            <Text style={styles.smallFont}>{user.bankName}</Text>
          </View>
          <View style={[ styles.flex, {width: '180px'}]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Account Number: </Text>
            <Text style={styles.smallFont}>{user.accountNumber}</Text>
          </View>
          <View style={[ styles.flex, {width: '180px'}]}>
            <Text style={[{ fontFamily: 'Roboto' }, styles.smallFont]}>Branch Code: </Text>
            <Text style={styles.smallFont}>{user.branchCode}</Text>
          </View>
        </View>
        <Table
          data={[
            {income: "Gross Salary", hours: 176, amount: props.rowData.gross_salary }
          ]}
        >
            <TableHeader textAlign={"center"}>
                <TableCell style={styles.tableHeader} weighting={0.7}>
                    INCOME
                </TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>
                    HOURS
                </TableCell>
                <TableCell style={styles.tableHeader} weighting={0.2}>
                    AMOUNT (E)
                </TableCell>
            </TableHeader>
            <TableBody>
                {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
                <DataTableCell style={styles.tableBody} weighting={0.7} getContent={(r) => r.income}/>
                <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.hours}/>
                <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
            </TableBody>
        </Table>
        <Table
          data={[
            {deductions: "PAYE", amount: props.rowData.paye },
            {deductions: "ENPF", amount: props.rowData.snpf }
          ]}
        >
            <TableHeader textAlign={"center"}>
                <TableCell style={[styles.tableHeader, styles.invisibleBorderTableHeadRight]} weighting={0.7}>
                    DEDUCTIONS
                </TableCell>
                <TableCell style={[styles.tableHeader, styles.invisibleBorderTableHeadLeft]} weighting={0.1}>
                </TableCell>
                <TableCell style={styles.tableHeader} weighting={0.2}>
                    AMOUNT (E)
                </TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={[styles.tableBody, styles.invisibleBorderTableRight]} weighting={0.7} getContent={(r) => r.deductions}/>
                <DataTableCell style={[styles.tableBody, styles.invisibleBorderTableLeft]} weighting={0.1} getContent={(r) => ''}/>
                <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
            </TableBody>
        </Table>
        <Table
            data={[
                {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
            ]}
        >
            <TableBody>
              <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
              <TableCell style={[styles.tableBody, styles.center, styles.topBorder, { fontFamily: 'Roboto', backgroundColor: '#BDBDBD' }]} weighting={0.1}>Net Salary</TableCell>
              <TableCell style={[styles.tableBody, styles.center, styles.topBorder, { fontFamily: 'Roboto' }]} weighting={0.2}>{props.rowData.net_salary? ccyFormat(props.rowData.net_salary) : 0.00}</TableCell>
            </TableBody>
        </Table>
        <View style={[styles.cutLine, {marginTop: '20px'}]}>
          <Image src={signature} alt="" style={styles.signature}/>
          <Text style={styles.smallFont}>--------------------------------------------------</Text>
          <Text style={styles.smallFont}>Bandile Dlamini (Director of BPD Team)</Text>
        </View>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Page ${pageNumber} of ${totalPages}`
          )} fixed />
      </Page>
    </Document>
  );
}
