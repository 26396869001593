import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator={true}
            valueIsNumericString
            prefix="E"
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function DelayedChargeDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Delayed Charge</DialogTitle>
            <DialogContent>
                <DialogContentText>Fill in the form to add the Delayed Charge</DialogContentText>
                <Grid style={{ marginTop: '10px' }} container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            value={props.name}
                            onChange={(e) => props.setName(e.target.value)}
                            margin="dense"
                            id="name"
                            label="Client Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.chargeNumber}
                            onChange={(e) => props.setChargeNumber(e.target.value)}
                            margin="dense"
                            id="delayed"
                            label="Delayed Charge No."
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={props.date}
                            onChange={(e) => props.setDate(e.target.value)}
                            margin="dense"
                            id="date"
                            label="Date"
                            type="text"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Subsidiary</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.subsidiary}
                                label="Subsidiary"
                                onChange={(e) => props.setSubsidiary(e.target.value)}
                            >
                                <MenuItem value={'FMK'}>
                                    FetchMyKid
                                </MenuItem>
                                <MenuItem value={'BPDdev'}>
                                    BPDdev
                                </MenuItem>
                                <MenuItem value={'BPDrobotics'}>
                                    BPDRobotics
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            value={props.description}
                            onChange={(e) => props.setDescription(e.target.value)}
                            name="description"
                            margin="dense"
                            id="description"
                            label="Description"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            value={props.amount}
                            onChange={(e) => props.setAmount(e.target.value)}
                            margin="dense"
                            id="amount"
                            label="Amount"
                            type="tel"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                   
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={props.handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
