
import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";

// import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./pages/home";
import Accounting from "./pages/accounting";

import Clients from "./pages/clients";
import Charges from "./pages/charges";
import Invoice from "./pages/invoice";
import Receipt from "./pages/receipt";
import Staff from "./pages/staff";
import Budget from "./pages/budget";
import Email from "./pages/email";
import Trello from "./pages/trello";
import SignUp from "./pages/signup";
import SignIn from "./pages/signin";
import PaymentMethod from "./pages/paymentmethod";
import Roles from "./pages/role";
import BusinessType from "./pages/businessType";
import RatePerHour from "./pages/ratePerHour";
import RatePerKm from "./pages/ratePerKm";
import TripFrequency from "./pages/tripFrequency";
import Terms from "./pages/terms";
import Quotation from "./pages/quotation";
import Settings from "./pages/settings";
import Balances from "./pages/balances";
import Payments from "./pages/payments";
import RefundReciept from "./pages/refundreciept";
import DelayedCredit from "./pages/delayedcredit";
import DelayedCharge from "./pages/delayedcharge";
import Bills from "./pages/bills";
import SupplierCredit from "./pages/supplierCredit";
import CreditCardCredit from "./pages/creditcardcredit";
import Suppliers from "./pages/suppliers";
import Services from "./pages/services";
import SingleTimeSheet from "./pages/singleTimeSheet";
import Assets from "./pages/assets";
import Liabilities from "./pages/liabilities";
import Profile from "./pages/profile";


function App() {
  const history = createBrowserHistory ? createBrowserHistory() : [];

  return (
    <div>
      <Routes>
        <Route path="/dashboard" element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/accounting" element={<Accounting />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/charges" element={<Charges />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/receipt" element={<Receipt />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/budget" element={<Budget />} />
        <Route path="/email" element={<Email />} />
        <Route path="/trello" element={<Trello />} />
        <Route path="/payment-method" element={<PaymentMethod />} />
        <Route path="/roles" element={<Roles />} />
        <Route path="/business-type" element={<BusinessType />} />
        <Route path="/rate-per-hour" element={<RatePerHour />} />
        <Route path="/rate-per-km" element={<RatePerKm />} />
        <Route path="/trip-frequency" element={<TripFrequency />} />
        <Route path="/term" element={<Terms />} />
        <Route path="/quotation" element={<Quotation />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/balances" element={<Balances />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/refundreciept" element={<RefundReciept/>} />
        <Route path="/delayedcredit" element={<DelayedCredit/>} />
        <Route path="/delayedcharge" element={<DelayedCharge/>} />
        <Route path="/bills" element={<Bills/>} />
        <Route path="/supplierCredit" element={<SupplierCredit/>} />
        <Route path="/creditCardCredit" element={<CreditCardCredit/>} />
        <Route path="/suppliers" element={<Suppliers/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/assets" element={<Assets/>} />
        <Route path="/liabilities" element={<Liabilities/>} />
        {/* <Route path="/profile" element={<Profile/>} /> */}
      </Routes>
    </div>
  );
}

export default App;
