import React, {useState, useEffect, forwardRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { PDFViewer, PDFDownloadLink, Document, Page, Text, View, Image, StyleSheet, Link, Font } from '@react-pdf/renderer';
import { TableHeader, DataTableCell, Table, TableBody, TableCell } from '@david.kucsai/react-pdf-table';

import Slide from '@material-ui/core/Slide';

import logo from '../../assets/logos/1.png';
import logo1 from '../../assets/logos/2.png';
import logo10 from '../../assets/logos/10.png';
import moment from 'moment';



Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  page: {
    // backgroundColor: '#E4E4E4'
    padding: 20,
    paddingBottom: 55,
  },
  header: {
    width: '100%',
    backgroundColor: "#BDBDBD"
  },
  titleBox:{
    width: '250px',
    textAlign: 'center',
    padding: 10,
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: '#fed700'
  },
  titleBoxFMK:{
    width: '250px',
    textAlign: 'center',
    padding: 10,
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: '#4479d9'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  image: {
    width: '50px',
    height: '50px'
  },
  company: {
    fontSize: '8px',
    position: 'absolute',
    top: 23,
    left: 80
  },
  links: {
    fontSize: '8px',
    position: 'absolute',
    top: 23,
    left: 210
  },
  link: {
    color: '#ad9300',
    textDecoration: 'underline',
    paddingBottom: 3
  },
  linkFMK: {
    color: '#2f5598',
    textDecoration: 'underline',
    paddingBottom: 3
  },
  flex: {
    flexDirection: 'row',
    margin: '10px'
  },
  infoBox: {
    width: '50%',
    fontSize: '10px',
    // marginTop: '10px',
    // border: '0.7px solid #000',
    padding: 10
  },
  smallIndent: {
    textIndent: '60px'
  },
  bold : {
    
  },
  tableHeader: {
    fontSize: '9px',
    backgroundColor: '#ffec82',
    padding: 5,
  },
  lightYellowBg: {
    backgroundColor: '#ffec82',
  },
  tableHeaderFMK: {
    fontSize: '9px',
    backgroundColor: '#95b4ea',
    padding: 5,
  },
  lightYellowBgFMK: {
    backgroundColor: '#95b4ea',
  },
  tableBody: {
    fontSize: '8px',
    padding: 5,
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  blankCell1: {
    border: 'unset'
  },
  blankCell2: {
    borderLeft: 'unset'
  },
  singleCellHeader: {
    backgroundColor: '#ffec82',
    border: '1px solid #000',
    pading: '10px',
    width: '100px',
    heiight: '80px',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  singleCellHeaderFMK: {
    backgroundColor: '#95b4ea',
    border: '1px solid #000',
    pading: '10px',
    width: '100px',
    heiight: '80px',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  singleCellBody: {
    border: '1px solid #000',
    pading: '10px',
    width: '100px',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  topInfoBox: {
    paddingBottom: '10px',
    marginTop: '15px',
  },
  paymentInfoBox: {
    width: '290px',
  },
  smallFont: {
    fontSize: '8px'
  },
  topBorder: {
    borderTop: '2px solid #000'
  },
  margLeft: {
    marginLeft: '10px'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  invisibleBorder: {
    borderBottom: '3px solid white',
    borderLeft: '2px solid white',
    margin: '0',
    marginBottom: '-2px',
    marginLeft: '-2px',
    // border: '0!important',
    borderCollapse: 'collapse!important',
    borderStyle : 'hidden!important',
    outline: 'none!important'
  },
});


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}



export default function InvoiceGenerators(props) {
  const classes = useStyles();

  const [currClient, setCurrClient] = useState();
  const [currService, setCurrService] = useState("");
  const [subTotalFMK, setSubTotalFMK] = useState(0);
  const [subTotalDEV, setSubTotalDEV] = useState(0);
  const [subTotalROB, setSubTotalROB] = useState(0);
  const [totalFMK, setTotalFMK] = useState(0);
  const [totalDEV, setTotalDEV] = useState(0);
  const [totalROB, setTotalROB] = useState(0);
  const [data, setData] = useState([]);
  const [ratePerHour, setRatePerHour] = useState(1);
  const [frequency, setFrequency] = useState(2);
  const [ratePerKM, setRatePerKM] = useState(2);
  const [ratePerHourValue, setRatePerHourValue] = useState(300);
  const [ratePerMonthValue, setRatePerMonthValue] = useState(350);
  const [ratePerKMValue, setRatePerKMValue] = useState(1.8);
  const [frequencyValue, setFrequencyValue] = useState(22);
  const [receipts, setReceipts] = useState();
  const [terms, setTerms] = useState("Due upon receipt");
  const [receiptNo, setReceiptNo] = useState();
  const [clientNo, setClientNo] = useState();
  const [date, setDate] = useState();
  const [receiptTotal, setReceiptTotal] = useState();



  function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = (tempSubTotal + (item.hours * ratePerHourValue));
      tempTotal = (tempTotal + (item.hours * ratePerHourValue));
      setSubTotalDEV(tempSubTotal);
      setTotalDEV(tempTotal);
      return null;
    });
  }

  function calculateROBTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = (tempSubTotal + (item.months * ratePerMonthValue));
      tempTotal = (tempTotal + (item.months * ratePerMonthValue));
      setSubTotalDEV(tempSubTotal);
      setTotalDEV(tempTotal);
      return null;
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateFMKTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = (tempSubTotal + (Math.ceil((item.distance * ratePerKMValue * frequencyValue)/10)*10));
      tempTotal = (tempTotal + (Math.ceil((item.distance * ratePerKMValue * frequencyValue)/10)*10));
      setSubTotalFMK(tempSubTotal);
      setTotalFMK(tempTotal);
      return null;
    });
  }



  useEffect(() => {
    console.log("Generator ",props.rowData.date);
    setData(JSON.parse(props.rowData.items));
    if(props.rowData.client_dev_id != null){
      setCurrService("DEV");
    }
    else if(props.rowData.client_fmk_id != null){
      setCurrService("FMK");
    }
    else{
      setCurrService("ROB");
    }
    setReceiptNo(props.rowData.receipt_no);
    setClientNo(props.rowData.client_no);
    setDate(props.rowData.date);
    setCurrClient(props.client);

    setReceiptTotal(props.rowData.amount);
  }, []);
  
  useEffect(() => {
    if(ratePerHour === 0){
      setRatePerHourValue(200);
    }
    else{
      setRatePerHourValue(300);
    }

    calculateTotal();
    // 

  }, [ratePerHour, calculateTotal]);

  useEffect(() => {
    if(ratePerKM === 0){
      setRatePerKMValue(3);
    }
    else if(ratePerKM === 1){
      setRatePerKMValue(2);
    }
    else if(ratePerKM === 2){
      setRatePerKMValue(1.8);
    }
    else {
      setRatePerKMValue(1);
    }

    calculateFMKTotal();
    // 

  }, [ratePerKM, calculateFMKTotal]);

  useEffect(() => {
    if(frequency === 0){
      setFrequencyValue(1);
    }
    else if(frequency === 1){
      setFrequencyValue(5);
    }
    else if(frequency === 2){
      setFrequencyValue(22);
    }
    else {
      setFrequencyValue(264);
    }

    calculateFMKTotal();
    // 

  }, [frequency, calculateFMKTotal]);



  const DevDoc = () => (
    <Document>
          <Page size="A4" style={styles.page}>
            <Image src={logo} alt="" style={styles.image}/>
            <View style={styles.titleBox}>
              <Text>RECEIPT</Text>
            </View>
            <View style={styles.company}>
              <Text style={styles.bold}>BPD INVESTMENTS (PTY) LTD</Text>
              <Text>R7/60438</Text>
              <Text>Plot 409 Somhlolo Road</Text>
              <Text>Mbabane</Text>
              <Text>Eswatini</Text>
            </View>
            <View style={styles.links}>
              <Link src="https://dev.bpdteam.com" style={styles.link}>dev.bpdteam.com</Link>
              <Link src="mailto:dev@bpdteam.com" style={styles.link}>dev@bpdteam.com</Link>
              <Link src="tel:79360602" style={styles.link}>79360602</Link>
            </View>
            <View style={[styles.topInfoBox, styles.flex]}>
              {/* <Table
                  data={[
                      {date: "14 Jul 2021"},
                  ]}
              >
                  <TableHeader textAlign={"center"}>
                      <TableCell style={[styles.tableHeader, styles.smallFont]}>
                          DATE
                      </TableCell>
                  </TableHeader>
                  <TableBody>
                      <DataTableCell style={[styles.tableBody, styles.center, styles.smallFont]} getContent={(r) => r.date}/>
                  </TableBody>
              </Table> */}
              <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>DATE</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment(date).format("DD-MMM-YYYY")}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>RECEIPT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{receiptNo}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>CLIENT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{clientNo}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.flex}>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>RECEIPT FOR:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.name : ""}</Text>
                {currClient ?
                  currClient.address.split(',').map((item,index) => {
                    return <Text key={"address-key-"+index} style={[styles.smallFont, styles.smallIndent, styles.bold]}>{item}</Text>
                  })
                  :
                  ""
                }
              </View>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
                <Link style={[styles.smallFont, styles.smallIndent, styles.link]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
              </View>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>BUSINESS TYPE:</Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{ratePerHour === 0 ? "Start-Up" : "Coporate"}</Text>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>RATE PER HOUR (E):</Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{ratePerHour === 0 ? "200" : "300"}</Text>
            </View>
            <Table
                data={data}
            >
                <TableHeader textAlign={"center"}>
                    {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                        TASK #
                    </TableCell> */}
                    <TableCell style={styles.tableHeader} weighting={0.7}>
                        DESCRIPTION
                    </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>
                        HOURS
                    </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.2}>
                        AMOUNT (E)
                    </TableCell>
                </TableHeader>
                <TableBody>
                    {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
                    <DataTableCell style={styles.tableBody} weighting={0.7} getContent={(r) => r.desc}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.hours}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightYellowBg]} weighting={0.1}>Sub-Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder]} weighting={0.2}>{receiptTotal? ccyFormat(receiptTotal) : 0.00}</TableCell>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.center, styles.invisibleBorder]} weighting={0.7}>Thank you for your business!</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg]} weighting={0.1}>|</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}></TableCell>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
                style={[styles.blankCell1]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg]} weighting={0.1}>Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}>{receiptTotal? ccyFormat(receiptTotal) : 0.00}</TableCell>
                </TableBody>
            </Table>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} of ${totalPages}`
              )} fixed />
          </Page>
        </Document>
  );

  const FMKDoc = () => (
        <Document>
          <Page size="A4" style={styles.page}>
            <Image src={logo1} alt="" style={styles.image}/>
            <View style={styles.titleBoxFMK}>
              <Text>RECEIPT</Text>
            </View>
            <View style={styles.company}>
              <Text style={styles.bold}>BPD INVESTMENTS (PTY) LTD</Text>
              <Text>R7/60438</Text>
              <Text>Plot 409 Somhlolo Road</Text>
              <Text>Mbabane</Text>
              <Text>Eswatini</Text>
            </View>
            <View style={styles.links}>
              <Link src="https://fetchmykid.bpdteam.com" style={styles.linkFMK}>fetchmykid.bpdteam.com</Link>
              <Link src="mailto:fetchmykid@bpdteam.com" style={styles.linkFMK}>fetchmykid@bpdteam.com</Link>
              <Link src="tel:79360602" style={styles.linkFMK}>79360602</Link>
            </View>
            <View style={[styles.topInfoBox, styles.flex]}>
              <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>DATE</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment(date).format("DD-MMM-YYYY")}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>RECEIPT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{receiptNo}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>CLIENT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{clientNo}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.flex}>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>RECEIPT FOR:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Parent/Guardian: {currClient ? currClient.name + " " + currClient.surname : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Kid: {currClient ? currClient.kid : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.home : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.phone : ""}</Text>
              </View>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
                <Link style={[styles.smallFont, styles.smallIndent, styles.linkFMK]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
              </View>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>FREQUENCY:</Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{ratePerKM === 0 ? "Daily" : ratePerKM === 1 ? "Weekly" : ratePerKM === 2 ? "Monthly" : "Annually"}</Text>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>RATE PER KM (E):</Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{ratePerKM === 0 ? "3" : ratePerKM === 1 ? "2" : ratePerKM === 2 ? "1.8" : "1"}</Text>
            </View>
            <Table
                data={data}
            >
                <TableHeader textAlign={"center"}>
                    {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                        TASK #
                    </TableCell> */}
                    <TableCell style={styles.tableHeaderFMK} weighting={0.7}>
                        DESCRIPTION
                    </TableCell>
                    <TableCell style={styles.tableHeaderFMK} weighting={0.1}>
                        DISTANCE (KM)
                    </TableCell>
                    <TableCell style={styles.tableHeaderFMK} weighting={0.2}>
                        AMOUNT (E)
                    </TableCell>
                </TableHeader>
                <TableBody>
                    {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
                    <DataTableCell style={styles.tableBody} weighting={0.7} getContent={(r) => r.desc}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.distance}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightYellowBgFMK]} weighting={0.1}>Sub-Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder]} weighting={0.2}>{receiptTotal? ccyFormat(receiptTotal) : 0.00}</TableCell>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.center, styles.invisibleBorder]} weighting={0.7}>Thank you for your business!</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBgFMK]} weighting={0.1}>|</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}></TableCell>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
                ]}
                style={[styles.blankCell1]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBgFMK]} weighting={0.1}>Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}>{receiptTotal? ccyFormat(receiptTotal) : 0.00}</TableCell>
                </TableBody>
            </Table>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} of ${totalPages}`
              )} fixed />
          </Page>
        </Document>
  );

  const ROBDoc = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={logo10} alt="" style={styles.image}/>
        <View style={styles.titleBox}>
          <Text>RECEIPT</Text>
        </View>
        <View style={styles.company}>
          <Text style={styles.bold}>BPD INVESTMENTS (PTY) LTD</Text>
          <Text>R7/60438</Text>
          <Text>Plot 409 Somhlolo Road</Text>
          <Text>Mbabane</Text>
          <Text>Eswatini</Text>
        </View>
        <View style={styles.links}>
          <Link src="https://robotics.bpdteam.com" style={styles.link}>robotics.bpdteam.com</Link>
          <Link src="mailto:robotics@bpdteam.com" style={styles.link}>robotics@bpdteam.com</Link>
          <Link src="tel:79360602" style={styles.link}>79360602</Link>
        </View>
        <View style={[styles.topInfoBox, styles.flex]}>
          <View style={{flexDirection: 'column'}}>
            <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
              <View>
                <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>DATE</Text>
                <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment(date).format("DD-MMM-YYYY")}</Text>
              </View>
              <View>
                <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>RECEIPT No.</Text>
                <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{receiptNo}</Text>
              </View>
              <View>
                <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>CLIENT No.</Text>
                <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{clientNo}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.flex}>
          <View style={styles.infoBox}>
            <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>RECEIPT FOR:</Text>
            <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Parent/Guardian: {currClient ? currClient.name + " " + currClient.surname : ""}</Text>
            <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Kid: {currClient ? currClient.kid : ""}</Text>
            <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.school : ""}</Text>
            <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.phone : ""}</Text>
          </View>
          <View style={styles.infoBox}>
            <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
            <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
            <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
            <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
            <Link style={[styles.smallFont, styles.smallIndent, styles.link]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
          </View>
        </View>
        <View style={{flexDirection: 'row', margin: '5px'}}>
          <Text style={[styles.smallFont, styles.margLeft]}>RATE PER MONTH:</Text>
          <Text style={[styles.smallFont, styles.margLeft]}>E{ratePerMonthValue}</Text>
        </View>
        <Table
            data={data}
        >
            <TableHeader textAlign={"center"}>
                {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                    TASK #
                </TableCell> */}
                <TableCell style={styles.tableHeader} weighting={0.7}>
                    DESCRIPTION
                </TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>
                    MONTHS
                </TableCell>
                <TableCell style={styles.tableHeader} weighting={0.2}>
                    AMOUNT (E)
                </TableCell>
            </TableHeader>
            <TableBody>
                {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
                <DataTableCell style={styles.tableBody} weighting={0.7} getContent={(r) => r.desc}/>
                <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.months}/>
                <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
            </TableBody>
        </Table>
        <Table
            data={[
                {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
            ]}
        >
            <TableBody>
              {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
              <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
              <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightYellowBg]} weighting={0.1}>Sub-Total</TableCell>
              <TableCell style={[styles.tableBody, styles.center, styles.topBorder]} weighting={0.2}>{receiptTotal? ccyFormat(receiptTotal) : 0.00}</TableCell>
            </TableBody>
        </Table>
        <Table
            data={[
                {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
            ]}
        >
            <TableBody>
              {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
              <TableCell style={[styles.tableBody, styles.center, styles.invisibleBorder]} weighting={0.7}>Thank you for your business!</TableCell>
              <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg]} weighting={0.1}>|</TableCell>
              <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}></TableCell>
            </TableBody>
        </Table>
        <Table
            data={[
                {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
            ]}
            style={[styles.blankCell1]}
        >
            <TableBody>
              {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
              <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
              <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg]} weighting={0.1}>Total</TableCell>
              <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}>{receiptTotal? ccyFormat(receiptTotal) : 0.00}</TableCell>
            </TableBody>
        </Table>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Page ${pageNumber} of ${totalPages}`
          )} fixed />
      </Page>
    </Document>
);

  return (
    <>
      {currService === "DEV" ? DevDoc() : currService === "FMK" ? FMKDoc() : ROBDoc()}
    </>
  );
}
