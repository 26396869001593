import React, { createRef, forwardRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Table from "../../elements/Table";

import Logo2 from "../../assets/logos/2.png";
import apiClient from "../../apiClient";
import ClientFMKDialog from "../../elements/Dialog/clientFMK";
import DeleteDialog from "../../elements/Dialog/delete";

import AddBox from "@material-ui/icons/AddBox";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function ClientsFMK() {
  const classes = useStyles();
  const clientTableRef = createRef();
  const [clientFMKOpen, setClientFMKOpen] = useState(false);
  const [clientFMKDeleteOpen, setClientFMKDeleteOpen] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [kid, setKid] = useState("");
  const [payment_amount, setPaymentAmount] = useState("0.0");
  const [payment_frequency, setPaymentFrequency] = useState("");
  const [next_payment_due, setNextPaymentDue] = useState("1");
  const [credit, setCredit] = useState("0.0");
  const [outstanding, setOutstanding] = useState("0.0");
  const [route_type, setRouteType] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [home, setHome] = useState("");
  const [school, setSchool] = useState("");
  const [distance, setDistance] = useState(null);
  const [emergency_person, setEmergencyPerson] = useState("");
  const [emergency_phone, setemergencyPhone] = useState("");
  const [gardian_name, setGardianName] = useState("");
  const [gardian_phone, setGardianPhone] = useState("");
  const [clientFMKOperation, setClientFMKOperation] = useState("Add");
  const [clientFMKUuid, setClientFMKUuid] = useState("");
  const [clientFMKLabel, setClientFMKLabel] = useState("");

  const handleClickSaveClient = () => {
    setClientFMKOperation("Add");
    setClientFMKOpen(true);
  };

  const handleClickEditClient = (rowData) => {
    setClientFMKOperation("Edit");

    setClientFMKUuid(rowData.uuid);
    setName(rowData.name);
    setSurname(rowData.surname);
    setKid(rowData.kid);
    setPaymentAmount(rowData.payment_amount);
    setPaymentFrequency(rowData.payment_frequency);
    setNextPaymentDue(rowData.next_payment_due);
    setCredit(rowData.credit);
    setOutstanding(rowData.outstanding);
    setRouteType(rowData.route_type);
    setPhone(rowData.phone);
    setEmail(rowData.email);
    setHome(rowData.home);
    setSchool(rowData.school);
    setDistance(rowData.distance);
    setEmergencyPerson(rowData.emergency_person);
    setemergencyPhone(rowData.emergency_phone);
    setGardianName(rowData.gardian_name);
    setGardianPhone(rowData.gardian_phone);

    setClientFMKOpen(true);
  };

  const handleCloseDeleteClient = () => {
    setClientFMKDeleteOpen(false);
  };

  const handleClickDeleteClient = (rowData) => {
    setClientFMKDeleteOpen(true);
    setClientFMKOperation("Delete");
    setClientFMKUuid(rowData.uuid);
    setClientFMKLabel(
      rowData.name + " " + rowData.surname + " " + `(${rowData.surname})`
    );
  };

  const handleCloseClientDialog = () => {
    setClientFMKOpen(false);
    setClientFMKUuid("");
    setName("");
    setSurname("");
    setKid("");
    setPaymentAmount("0.0");
    setPaymentFrequency("");
    setNextPaymentDue("1");
    setCredit("0.0");
    setOutstanding("0.0");
    setRouteType("");
    setPhone("");
    setEmail("");
    setHome("");
    setSchool("");
    setDistance(null);
    setEmergencyPerson("");
    setemergencyPhone("");
    setGardianName("");
    setGardianPhone("");
  };

  const handleSaveClient = () => {
    let newData = {
      name: name,
      surname: surname,
      kid: kid,
      payment_amount: payment_amount,
      payment_frequency: payment_frequency,
      next_payment_due: next_payment_due,
      credit: credit,
      outstanding: outstanding,
      route_type: route_type,
      phone: phone,
      email: email,
      home: home,
      school: school,
      distance: distance,
      emergency_person: emergency_person,
      emergency_phone: emergency_phone,
      gardian_name: gardian_name,
      gardian_phone: gardian_phone,
    };
    if (clientFMKOperation === "Add") {
      apiClient
        .post("/api/clients-fmk", newData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseClientDialog();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    } else if (clientFMKOperation === "Edit") {
      apiClient
        .put("/api/clients-fmk/" + clientFMKUuid, newData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseClientDialog();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    } else if (clientFMKOperation === "Delete") {
      apiClient
        .delete("/api/clients-fmk/" + clientFMKUuid, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseDeleteClient();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    }
  };

  useEffect(() => {
    // console.log
  }, []);

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Surname",
      field: "surname",
    },
    { title: "Kid", field: "kid" },
    { title: "Payment Amount", field: "payment_amount" },
    { title: "Payment Frequency", field: "payment_frequency" },
    { title: "Next Payment Due", field: "next_payment_due" },
    { title: "Credit", field: "credit" },
    { title: "Outstanding", field: "outstanding" },
    { title: "Route Type", field: "route_type" },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    { title: "Home", field: "home" },
    { title: "School", field: "school" },
    { title: "Distance", field: "distance" },
    { title: "Emergency Person", field: "emergency_person" },
    { title: "Emergency Phone", field: "emergency_phone" },
    { title: "Gardian Name", field: "gardian_name" },
    { title: "Gardian Phone", field: "gardian_phone" },
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Table
        title={
          <>
            <img src={Logo2} style={{ width: 40, marginTop: "20px" }} alt="" />
            <p style={{ fontSize: "20px" }}>FetchMyKid Clients</p>
          </>
        }
        columns={columns}
        api="/api/clients-fmk"
        tableRef={clientTableRef}
        actionsColumnIndex={0}
        //customActions={true}
        actions={[
          {
            icon: AddBox,
            tooltip: "Add Bond",
            isFreeAction: true,
            onClick: (event) => handleClickSaveClient(),
          },
          {
            icon: Edit,
            tooltip: "Edit Bond",
            onClick: (event, rowData) => {
              handleClickEditClient(rowData);
            },
          },
          {
            icon: DeleteOutline,
            tooltip: "Delete Bond",
            onClick: (event, rowData) => {
              handleClickDeleteClient(rowData);
            },
          },
        ]}
      />
      <ClientFMKDialog
        open={clientFMKOpen}
        handleClose={handleCloseClientDialog}
        handleSave={handleSaveClient}
        name={name}
        setName={setName}
        surname={surname}
        setSurname={setSurname}
        kid={kid}
        setKid={setKid}
        payment_amount={payment_amount}
        setPaymentAmount={setPaymentAmount}
        payment_frequency={payment_frequency}
        setPaymentFrequency={setPaymentFrequency}
        next_payment_due={next_payment_due}
        setNextPaymentDue={setNextPaymentDue}
        credit={credit}
        setCredit={setCredit}
        outstanding={outstanding}
        setOutstanding={setOutstanding}
        route_type={route_type}
        setRouteType={setRouteType}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        home={home}
        setHome={setHome}
        school={school}
        setSchool={setSchool}
        distance={distance}
        setDistance={setDistance}
        emergency_person={emergency_person}
        setEmergencyPerson={setEmergencyPerson}
        emergency_phone={emergency_phone}
        setemergencyPhone={setemergencyPhone}
        gardian_name={gardian_name}
        setGardianName={setGardianName}
        gardian_phone={gardian_phone}
        setGardianPhone={setGardianPhone}
        clientFMKOperation={clientFMKOperation}
        setClientFMKOperation={setClientFMKOperation}
        clientFMKUuid={clientFMKUuid}
      />
      <DeleteDialog
        open={clientFMKDeleteOpen}
        handleClose={handleCloseDeleteClient}
        handleSave={handleSaveClient}
        label={clientFMKLabel}
      />
    </Container>
  );
}
