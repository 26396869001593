import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PeopleIcon from '@material-ui/icons/People';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DescriptionIcon from '@material-ui/icons/Description';
import IsoIcon from '@mui/icons-material/Iso';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import HailIcon from '@mui/icons-material/Hail';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import UpdateIcon from '@material-ui/icons/Update';
import SettingsIcon from '@mui/icons-material/Settings';
import PinDropIcon from '@material-ui/icons/PinDrop';
import CategoryIcon from '@mui/icons-material/Category';
import WcIcon from '@material-ui/icons/Wc';
import MoneyIcon from '@material-ui/icons/Money';
import EmailIcon from '@material-ui/icons/Email';
import TableChartIcon from '@material-ui/icons/TableChart';
import LockIcon from '@material-ui/icons/Lock';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PaymentsIcon from '@mui/icons-material/Payments';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import GavelIcon from '@material-ui/icons/Gavel';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { Link } from 'react-router-dom';
import InventoryIcon from '@mui/icons-material/Inventory';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import GroupIcon from '@mui/icons-material/Group';


export const MainListItems = () => {
  return(
    <div>
      <Link to="/payslips" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <RequestQuoteIcon />
          </ListItemIcon>
          <ListItemText primary="Payslips" />
        </ListItem>
      </Link>
      <Link to="/profile" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIndIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItem>
      </Link>
    </div>
  );
};

export const AccountsMainListItems = () => {

  const [openTalent, setOpenTalent] = useState(false);
  const [openAccounts, setOpenAccounts] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openSuppliers, setOpenSuppliers] = useState(false);
  const [openEmployees, setOpenEmployees] = useState(false);
  const [openReports, setOpenReports] = useState(false);

  const handleClickReports = () => {
    setOpenReports(!openReports);
  };

  const handleClickTalent = () => {
    setOpenTalent(!openTalent);
  };
  
  const handleClickAccounts = () => {
    setOpenAccounts(!openAccounts);
  };

  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
  };
  const handleClickSuppliers = () => {
    setOpenSuppliers(!openSuppliers);
  };

  

  return(
    <div>
      <Link to="/dashboard" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </Link>
      <Link to="/payslips" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <RequestQuoteIcon />
          </ListItemIcon>
          <ListItemText primary="Payslips" />
        </ListItem>
      </Link>

      <ListItemButton onClick={handleClickTalent}>
        <ListItemIcon>
          <HailIcon />
        </ListItemIcon>
        <ListItemText primary="Talent" />
        {openTalent ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openTalent} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/staff" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <WcIcon />
              </ListItemIcon>
              <ListItemText primary="Staff" />
            </ListItemButton>
          </Link>
          <Link to="/payments" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PaymentsIcon />
              </ListItemIcon>
              <ListItemText primary="Payments" />
            </ListItemButton>
          </Link>
          <Link to="/roles" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary="Roles" />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>

    

      <ListItemButton onClick={handleClickAccounts}>
        <ListItemIcon>
          <AccountBalanceWalletIcon />
        </ListItemIcon>
        <ListItemText primary="Accounting" />
        {openAccounts ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openAccounts} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/clients" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Clients" />
            </ListItemButton>
          </Link>
          <Link to="/invoice" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Invoice" />
            </ListItemButton>
          </Link>
          <Link to="/receipt" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="Receipt" />
            </ListItemButton>
          </Link>
          <Link to="/refundreciept" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary="Refund Reciepts"/>
            </ListItemButton>
          </Link>
          <Link to="/quotation" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Quotation" />
            </ListItemButton>
          </Link>
          <Link to="/balances" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <MoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Balances" />
            </ListItemButton>
          </Link>
          <Link to="/accounting" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <IsoIcon />
              </ListItemIcon>
              <ListItemText primary="Income/Expenses" />
            </ListItemButton>
          </Link>
          <Link to="/budget" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DonutLargeIcon />
              </ListItemIcon>
              <ListItemText primary="Budget" />
            </ListItemButton>
          </Link>
          <Link to="/delayedcredit" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary="Delayed Credit"/>
            </ListItemButton>
          </Link>
          <Link to="/delayedcharge" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PendingActionsIcon />
              </ListItemIcon>
              <ListItemText primary="Delayed Charge"/>
            </ListItemButton>
          </Link>
        </List>
      </Collapse>

      <ListItemButton onClick={handleClickSettings}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
        {openSettings ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openSettings} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/charges" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Categories" />
            </ListItemButton>
          </Link>
          <Link to="/services" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LocalLibraryIcon />
              </ListItemIcon>
              <ListItemText primary="Services" />
            </ListItemButton>
          </Link>
          <Link to="/payment-method" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <MoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Payment Methods" />
            </ListItemButton>
          </Link>
          <Link to="/business-type" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Busines Types" />
            </ListItemButton>
          </Link>
          <Link to="/rate-per-hour" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <HourglassFullIcon />
              </ListItemIcon>
              <ListItemText primary="Rates Per Hour" />
            </ListItemButton>
          </Link>
          <Link to="/trip-frequency" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <UpdateIcon />
              </ListItemIcon>
              <ListItemText primary="Trip Frequencies" />
            </ListItemButton>
          </Link>
          <Link to="/rate-per-km" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PinDropIcon />
              </ListItemIcon>
              <ListItemText primary="Rates Per KM" />
            </ListItemButton>
          </Link>
          <Link to="/term" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Terms" />
            </ListItemButton>
          </Link>
          <Link to="/assets" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Assets" />
            </ListItemButton>
          </Link>
          <Link to="/liabilities" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Liabilities" />
            </ListItemButton>
          </Link>
          <Link to="/suppliers" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <FlipCameraAndroidIcon/>
              </ListItemIcon>
              <ListItemText primary="Suppliers"/>
            </ListItemButton>
          </Link>
        </List>
      </Collapse>

      <ListItemButton onClick={handleClickSuppliers}>
        <ListItemIcon>
          <SyncAltIcon/>
        </ListItemIcon>
        <ListItemText primary="Supplier" />
        {openSuppliers ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openSuppliers} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to="/bills" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LocalAtmIcon/>
              </ListItemIcon>
              <ListItemText primary="Bills"/>
            </ListItemButton>
          </Link>
          <Link to="/supplierCredit" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <InventoryIcon/>
              </ListItemIcon>
              <ListItemText primary="Supplier Credit"/>
            </ListItemButton>
          </Link>
          <Link to="/creditCardCredit" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CreditCardIcon/>
              </ListItemIcon>
              <ListItemText primary="Credit Card Credit"/>
            </ListItemButton>
          </Link>
      
        </List>
      </Collapse>

      <ListItemButton onClick={handleClickReports}>
        <ListItemIcon>
          <SyncAltIcon/>
        </ListItemIcon>
        <ListItemText primary="Reports" />
        {openReports ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openReports} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to="/bills" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LocalAtmIcon/>
              </ListItemIcon>
              <ListItemText primary="Bills"/>
            </ListItemButton>
          </Link>
          <Link to="/supplierCredit" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <InventoryIcon/>
              </ListItemIcon>
              <ListItemText primary="Supplier Credit"/>
            </ListItemButton>
          </Link>
          <Link to="/creditCardCredit" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CreditCardIcon/>
              </ListItemIcon>
              <ListItemText primary="Credit Card Credit"/>
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
    </div>
  );
};

export const AdminMainListItems = () => {

  const [openTalent, setOpenTalent] = useState(false);
  const [openAccounts, setOpenAccounts] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openSuppliers, setOpenSuppliers] = useState(false);
  const [openReports, setOpenReports] = useState(false);

  const handleClickReports = () => {
    setOpenReports(!openReports);
  };
  const handleClickTalent = () => {
    setOpenTalent(!openTalent);
  };

  const handleClickAccounts = () => {
    setOpenAccounts(!openAccounts);
  };

  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
  };

  const handleClickSuppliers = () => {
    setOpenSuppliers(!openSuppliers);
  };

 

  return(
    <div>
      <Link to="/dashboard" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </Link>
      <Link to="/payslips" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <RequestQuoteIcon />
          </ListItemIcon>
          <ListItemText primary="Payslips" />
        </ListItem>
      </Link>
      <ListItemButton onClick={handleClickTalent}>
        <ListItemIcon>
          <HailIcon />
        </ListItemIcon>
        <ListItemText primary="Talent" />
        {openTalent ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openTalent} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/staff" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <WcIcon />
              </ListItemIcon>
              <ListItemText primary="Staff" />
            </ListItemButton>
          </Link>
          <Link to="/payments" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PaymentsIcon />
              </ListItemIcon>
              <ListItemText primary="Payments" />
            </ListItemButton>
          </Link>
          <Link to="/roles" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary="Roles" />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>

      <ListItemButton onClick={handleClickAccounts}>
        <ListItemIcon>
          <AccountBalanceWalletIcon />
        </ListItemIcon>
        <ListItemText primary="Accounting" />
        {openAccounts ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openAccounts} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/clients" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Clients" />
            </ListItemButton>
          </Link>
          <Link to="/invoice" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Invoice" />
            </ListItemButton>
          </Link>
          <Link to="/receipt" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="Receipt" />
            </ListItemButton>
          </Link>
          <Link to="/refundreciept" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary="Refund Reciepts"/>
            </ListItemButton>
          </Link>
          
          <Link to="/quotation" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Quotation" />
            </ListItemButton>
          </Link>
          <Link to="/balances" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <MoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Balances" />
            </ListItemButton>
          </Link>
          
          <Link to="/accounting" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <IsoIcon />
              </ListItemIcon>
              <ListItemText primary="Income/Expenses" />
            </ListItemButton>
          </Link>
          <Link to="/budget" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DonutLargeIcon />
              </ListItemIcon>
              <ListItemText primary="Budget" />
            </ListItemButton>
          </Link>
          <Link to="/delayedcredit" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary="Delayed Credit"/>
            </ListItemButton>
          </Link>
          <Link to="/delayedcharge" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PendingActionsIcon />
              </ListItemIcon>
              <ListItemText primary="Delayed Charge"/>
            </ListItemButton>
          </Link>
        </List>
      </Collapse>

      <ListItemButton onClick={handleClickSettings}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
        {openSettings ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openSettings} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/charges" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Categories" />
            </ListItemButton>
          </Link>
          <Link to="/services" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LocalLibraryIcon />
              </ListItemIcon>
              <ListItemText primary="Services" />
            </ListItemButton>
          </Link>
          <Link to="/payment-method" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <MoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Payment Methods" />
            </ListItemButton>
          </Link>
          <Link to="/business-type" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Busines Types" />
            </ListItemButton>
          </Link>
          <Link to="/rate-per-hour" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <HourglassFullIcon />
              </ListItemIcon>
              <ListItemText primary="Rates Per Hour" />
            </ListItemButton>
          </Link>
          <Link to="/trip-frequency" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <UpdateIcon />
              </ListItemIcon>
              <ListItemText primary="Trip Frequencies" />
            </ListItemButton>
          </Link>
          <Link to="/rate-per-km" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PinDropIcon />
              </ListItemIcon>
              <ListItemText primary="Rates Per KM" />
            </ListItemButton>
          </Link>
          <Link to="/term" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Terms" />
            </ListItemButton>
          </Link>
          <Link to="/suppliers" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Supplier" />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>


      <ListItemButton onClick={handleClickSuppliers}>
        <ListItemIcon>
          <SyncAltIcon/>
        </ListItemIcon>
        <ListItemText primary="Suppliers" />
        {openSuppliers ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openSuppliers} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to="/bills" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LocalAtmIcon/>
              </ListItemIcon>
              <ListItemText primary="Bills"/>
            </ListItemButton>
          </Link>
          <Link to="/supplierCredit" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <InventoryIcon/>
              </ListItemIcon>
              <ListItemText primary="Supplier Credit"/>
            </ListItemButton>
          </Link>
          <Link to="/creditCardCredit" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CreditCardIcon/>
              </ListItemIcon>
              <ListItemText primary="Credit Card Credit"/>
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
      <ListItemButton onClick={handleClickReports}>
        <ListItemIcon>
          <SyncAltIcon/>
        </ListItemIcon>
        <ListItemText primary="Reports" />
        {openReports ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openReports} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to="/bills" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LocalAtmIcon/>
              </ListItemIcon>
              <ListItemText primary="Bills"/>
            </ListItemButton>
          </Link>
          <Link to="/supplierCredit" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <InventoryIcon/>
              </ListItemIcon>
              <ListItemText primary="Supplier Credit"/>
            </ListItemButton>
          </Link>
          <Link to="/creditCardCredit" style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <CreditCardIcon/>
              </ListItemIcon>
              <ListItemText primary="Credit Card Credit"/>
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
    </div>
  );
};

export const SecondaryListItems = () => {

  return(
    <div>
      <ListSubheader inset>Integrations</ListSubheader>
      <a href="https://webmail.bpdteam.com" target="_blank" rel="noreferrer" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="Email" />
        </ListItem>
      </a>
      <a href="https://trello.com/bpd81/home" target="_blank" rel="noreferrer" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <TableChartIcon />
          </ListItemIcon>
          <ListItemText primary="Trello" />
        </ListItem>
      </a>
    </div>
  );
};

export const UserListItems = () => {

  return(
    <div>
      <ListSubheader inset>User</ListSubheader>
      <Link to="/signin" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText primary="Sign In" />
        </ListItem>
      </Link>
      <Link to="/signup" style={{color: 'unset', textDecoration: 'unset'}}>
        <ListItem button>
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Up" />
        </ListItem>
      </Link>
    </div>
  );
};