import React, { createRef, forwardRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from '../../elements/Table';
import Logo11 from '../../assets/logos/4.png';
import apiClient from '../../apiClient';

import DeleteDialog from '../../elements/Dialog/delete';

import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DelayedChargeDialog from '../../elements/Dialog/delayedCharge';



const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function DelayedCharge() {
    const classes = useStyles();
    const clientTableRef = createRef();
    const [delayedChargeOpen, setDelayedChargeOpen] = useState(false);
    const [DelayedChargeDeleteOpen, setDelayedChargeDeleteOpen] = useState(false);
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [chargeNumber, setChargeNumber ] = useState('');
    const [subsidiary, setSubsidiary] = useState('');
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('0.0');
    const [delayedChargeOperation, setDelayedChargeOperation] = useState('Add');
    const [delayedChargeUuid,setDelayedChargeUuid] = useState('');
    const [delayedChargeLabel,setDelayedChargeLabel] = useState('');

    const handleClickSaveClient = () => {
      setDelayedChargeOperation('Add');
      setDelayedChargeOpen(true);
    };

    const handleClickEditDelayedCharge = (rowData) => {
      setDelayedChargeOperation('Edit');

      setDelayedChargeUuid(rowData.uuid);
      setName(rowData.name);
      setDate(rowData.date);
      setAmount(rowData.amount);
      setChargeNumber(rowData.chargeNumber)
      setDescription(rowData.description);
      setSubsidiary(rowData.subsidiary);
    
      setDelayedChargeOpen(true);
    };

    const handleCloseDeleteClient = () => {
      setDelayedChargeOpen(false);
    };

    const handleClickDeleteClient = (rowData) => {
      setDelayedChargeOpen(true);

        setDelayedChargeOperation('Delete');
        setDelayedChargeUuid(rowData.uuid);
        setDelayedChargeLabel(rowData.name + ' ' + rowData.date + ' ' + `(${rowData.name})`);
    };

    const handleCloseClientDialog = () => {
      setDelayedChargeOpen(false);
      setDelayedChargeUuid('');
      setName('');
      setDate('');
      setDescription('');
      setAmount('0.0');
      setSubsidiary('');
      setChargeNumber ('1');
    };

    const handleSaveDelayedCharge = () => {
        let newData = {
          name: name,
          date: date,
          chargeNumber: chargeNumber,
          subsidiary: subsidiary,
          description: description,
          amount: amount,
        };
        if (delayedChargeOperation === 'Add') {
            apiClient
                .post('/api/delayedCharges/', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseClientDialog();
                    clientTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (delayedChargeOperation === 'Edit') {
            apiClient
                .put('/api/delayedCharges/' + delayedChargeUuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseClientDialog();
                    clientTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (delayedChargeOperation === 'Delete') {
            apiClient
                .delete('/api/delayedCharges/' + delayedChargeUuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseDeleteClient();
                    clientTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        }
    };

    useEffect(() => {
        
      }, [])
  
    const columns = [
        { 
          title: 'Clint Name', 
          field: 'name',
        },
        { 
          title: 'Date', 
          field: 'date',
        },
        { title: 'Delayed Charge No.', field: 'chargeNo.' },
        { title: 'Subsidiary', field: 'subsidiary' }, 
        { title: 'Description', field: 'description' },
        { title: 'Amount', field: 'Amount' },
    ];
  
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Table 
              title={<>
                <img src={Logo11} style={{width: 40, marginTop: "20px"}} alt=""/>
                <p style={{fontSize: "20px"}}> Delayed Charge</p>
              </>}
              columns={columns}
              api="/api/delayedCharges"
              tableRef={clientTableRef}
              actionsColumnIndex={0}
              customActions={true}
              actions={[
                  {
                      icon: AddBox,
                      tooltip: 'Add Bond',
                      isFreeAction: true,
                      onClick: (event) => handleClickSaveClient()
                  },
                  {
                      icon: Edit,
                      tooltip: 'Edit Bond',
                      onClick: (event, rowData) => {
                        handleClickEditDelayedCharge(rowData);
                      }
                  },
                  {
                      icon: DeleteOutline,
                      tooltip: 'Delete Bond',
                      onClick: (event, rowData) => {
                        handleClickDeleteClient(rowData);
                      }
                  }
              ]}
            />
            <DelayedChargeDialog
              open={delayedChargeOpen}
              handleClose={handleCloseClientDialog}
              handleSave={handleSaveDelayedCharge}
              name={name}
              setName={setName}
             date={date}
             setDate={setDate}
              chargeNumber={chargeNumber}
              setChargeNumber={setChargeNumber}
             subsidiary={subsidiary}
              setSubsidiary={setSubsidiary}
              description={description}
              setDescription={setDescription}
              amount={amount}
             setAmount={setAmount}
              delayedChargeOperation={delayedChargeOperation}
              setDelayedChargeOperation={setDelayedChargeOperation}
              delayedChargeUuid={delayedChargeUuid}
            />
            <DeleteDialog open={delayedChargeOpen} handleClose={handleCloseDeleteClient} handleSave={handleSaveDelayedCharge} label={delayedChargeLabel} />
        </Container>
    )
}
