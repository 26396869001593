import React, {useState, useEffect, forwardRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableMUI from '@material-ui/core/Table';
import TableBodyMUI from '@material-ui/core/TableBody';
import TableCellMUI from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRowMUI from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//import MaterialTable, { MTableToolbar } from 'material-table/core';
import MaterialTable, {MTableToolbar} from '@material-table/core';

import apiClient from "../../apiClient";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { PDFViewer, PDFDownloadLink, Document, Page, Text, View, Image, StyleSheet, Link, Font } from '@react-pdf/renderer';
import { TableHeader, DataTableCell, Table, TableBody, TableCell } from '@david.kucsai/react-pdf-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import logo from '../../assets/logos/1.png';
import logo1 from '../../assets/logos/2.png';
import moment from 'moment';
import PatchedPagination from '../../elements/PatchedPagination';
import Autocomplete from '@mui/material/Autocomplete';


Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingBottom: 55,
  },
  header: {
    width: '100%',
    backgroundColor: "#BDBDBD"
  },
  titleBox:{
    width: '250px',
    textAlign: 'center',
    padding: 10,
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: '#fed700'
  },
  titleBoxFMK:{
    width: '250px',
    textAlign: 'center',
    padding: 10,
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: '#4479d9'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  image: {
    width: '60px',
    height: '60px'
  },
  company: {
    fontSize: '8px',
    position: 'absolute',
    top: 23,
    left: 80
  },
  links: {
    fontSize: '8px',
    position: 'absolute',
    top: 23,
    left: 210
  },
  link: {
    color: '#ad9300',
    textDecoration: 'underline',
    paddingBottom: 3
  },
  linkFMK: {
    color: '#2f5598',
    textDecoration: 'underline',
    paddingBottom: 3
  },
  flex: {
    flexDirection: 'row',
    margin: '10px'
  },
  infoBox: {
    width: '50%',
    fontSize: '10px',
    padding: 10
  },
  smallIndent: {
    textIndent: '60px'
  },
  bold : {
    
  },
  tableHeader: {
    fontSize: '9px',
    backgroundColor: '#ffec82',
    padding: 5,
  },
  lightYellowBg: {
    backgroundColor: '#ffec82',
  },
  tableHeaderFMK: {
    fontSize: '9px',
    backgroundColor: '#95b4ea',
    padding: 5,
  },
  lightYellowBgFMK: {
    backgroundColor: '#95b4ea',
  },
  tableBody: {
    fontSize: '8px',
    padding: 5,
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  blankCell1: {
    border: 'unset'
  },
  blankCell2: {
    borderLeft: 'unset'
  },
  singleCellHeader: {
    backgroundColor: '#ffec82',
    border: '1px solid #000',
    pading: '10px',
    width: '100px',
    heiight: '80px',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  singleCellHeaderFMK: {
    backgroundColor: '#95b4ea',
    border: '1px solid #000',
    pading: '10px',
    width: '100px',
    heiight: '80px',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  singleCellBody: {
    border: '1px solid #000',
    pading: '10px',
    width: '100px',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  topInfoBox: {
    paddingBottom: '10px',
    marginTop: '15px',
  },
  paymentInfoBox: {
    width: '290px',
  },
  smallFont: {
    fontSize: '8px'
  },
  topBorder: {
    borderTop: '2px solid #000'
  },
  margLeft: {
    marginLeft: '10px'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  invisibleBorder: {
    borderBottom: '3px solid white',
    borderLeft: '2px solid white',
    margin: '0',
    marginBottom: '-2px',
    marginLeft: '-2px',
    borderCollapse: 'collapse!important',
    borderStyle : 'hidden!important',
    outline: 'none!important'
  },
});


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ccyFormat(num) {
  if(typeof num === 'number')
    return `${num.toFixed(2)}`;
}


export default function Quotation() {
  const classes = useStyles();

  const [openPreview, setOpenPreview] = React.useState(false);
  const [openQuickInvoice, setOpenQuickInvoice] = React.useState(false);
  const [currClientID, setCurrClientID] = useState();
  const [currClientValue, setCurrClientValue] = useState("");
  const [currClient, setCurrClient] = useState();
  const [FMKclients, setFMKclients] = useState([]);
  const [DEVclients, setDEVclients] = useState([]);
  const [subTotalFMK, setSubTotalFMK] = useState(0);
  const [subTotalDEV, setSubTotalDEV] = useState(0);
  const [totalFMK, setTotalFMK] = useState(0);
  const [totalDEV, setTotalDEV] = useState(0);
  const [data, setData] = useState([]);
  const [businessType, setBusinessType] = useState(1);
  const [ratePerHour, setRatePerHour] = useState(1);
  const [frequency, setFrequency] = useState(3);
  const [ratePerKM, setRatePerKM] = useState(5);
  const [ratePerKMArr, setRatePerKMArr] = useState([]);
  const [tripFrequencies, setTripFrequencies] = useState([]);
  const [ratePerHourValue, setRatePerHourValue] = useState(300);
  const [ratePerKMValue, setRatePerKMValue] = useState(2.2);
  const [frequencyValue, setFrequencyValue] = useState(22);
  const [frequencyStr, setFrequencyStr] = useState("Monthly");
  const [invoices, setInvoices] = useState();
  const [terms, setTerms] = useState("Due upon receipt");
  const [refreshInvoices, setRefreshInvoices] = useState(0);
  const [service, setService] = useState("FMK");
  const [clientName, setClientName] = useState("");
  const [clientNameDoc, setClientNameDoc] = useState("");
  const [transportRoute, setTransportRoute] = useState("");

  const handleClickOpenPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleClickOpenQuickInvoice = () => {
    setOpenQuickInvoice(true);
  };

  const handleCloseQuickInvoice = () => {
    setOpenQuickInvoice(false);
  };

  const handleSaveInvoice = () => {

    // var datapack;

    // if(service === "DEV"){
    //   datapack = {
    //     client_dev_id: currClientID,
    //     client_no: "DEV-" + currClient.id,
    //     invoice_no: "DEV-" + pad(invoices.length+1, 4),
    //     date: moment().format("DD-MMM-YYYY"),
    //     items: JSON.stringify(data),
    //     amount: totalDEV
    //   };
    // }
    // else{
    //   datapack = {
    //     client_fmk_id: currClientID,
    //     client_no: "FMK-" + currClient.id,
    //     invoice_no: "FMK-" + pad(invoices.length+1, 4),
    //     date: moment().format("DD-MMM-YYYY"),
    //     items: JSON.stringify(data),
    //     amount: totalFMK
    //   };
    // }

    // axios.post('/api/invoices', datapack)
    //     .then(response => {console.log("You're Good"); setRefreshInvoices(refreshInvoices+1)});
  };


  function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  function setCurrentClient(client) {
    setCurrClientValue(client);
    var result;
    if (client.charAt(0) === "D") {
      result = client.slice(1);
      setCurrClientID(parseInt(result, 10));
      setCurrClient(
        DEVclients[DEVclients.findIndex((x) => x.id === parseInt(result, 10))]
      );
      setService("DEV");
    } else {
      result = client.slice(1);
      setCurrClientID(parseInt(result, 10));
      setCurrClient(
        FMKclients[FMKclients.findIndex((x) => x.id === parseInt(result, 10))]
      );
      setService("FMK");
    }
  }

  function setTransportRouteData(index) {
    if (index === 0) {
      setTransportRoute("To and From School");
      setData([
        {
          taskNumber: 1,
          desc: `From ${currClient.home} to ${currClient.school}`,
          distance: currClient.distance,
          amount: Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: 0 },
        },
        {
          taskNumber: 2,
          desc: `From ${currClient.school} to ${currClient.home}`,
          distance: currClient.distance,
          amount: Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: 1 },
        },
      ]);
    }
    else if (index === 1) {
      setTransportRoute("From School to Home");
      setData([
        {
          taskNumber: 1,
          desc: `From ${currClient.school} to ${currClient.home}`,
          distance: currClient.distance,
          amount: currClient.distance <= 5 ? Math.ceil((currClient.distance * (ratePerKMValue + 0.3) * frequencyValue) / 10) * 10 : Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: 0 },
        }
      ]);
    }
    else if (index === 2) {
      setTransportRoute("From Home to School");
      setData([
        {
          taskNumber: 1,
          desc: `From ${currClient.home} to ${currClient.school}`,
          distance: currClient.distance,
          amount: currClient.distance <= 5 ? Math.ceil((currClient.distance * (ratePerKMValue + 0.3) * frequencyValue) / 10) * 10 : Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: 0 },
        }
      ]);
    }
  }

  function setRate(id) {
    setRatePerKM(ratePerKMArr[ratePerKMArr.findIndex(x => x.id === tripFrequencies[tripFrequencies.findIndex(y => y.id === id)].rate_per_km_id)].id);
    setRatePerKMValue(parseInt(ratePerKMArr[ratePerKMArr.findIndex(x => x.id === tripFrequencies[tripFrequencies.findIndex(y => y.id === id)].rate_per_km_id)].name));
    setFrequencyStr(tripFrequencies[tripFrequencies.findIndex(y => y.id === id)].name);
    // alert(ratePerKMArr[ratePerKMArr.findIndex(x => x.id === tripFrequencies[tripFrequencies.findIndex(y => y.id === id)].rate_per_km_id)].name);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = (tempSubTotal + (item.hours * ratePerHourValue));
      tempTotal = (tempTotal + (item.hours * ratePerHourValue));
      setSubTotalDEV(tempSubTotal);
      setTotalDEV(tempTotal);
      return null;
    });
  }

 // eslint-disable-next-line react-hooks/exhaustive-deps
 function calculateFMKTotal() {
  var tempTotal = 0;
  var tempSubTotal = 0;
  return data.map((item) => {

    if (transportRoute === "To and From School") {
      tempSubTotal =
        tempSubTotal +
        Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
      tempTotal =
        tempTotal +
        Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
    }
    else {
      if(item.distance <= 5){
        tempSubTotal =
          tempSubTotal +
          Math.ceil((item.distance * (ratePerKMValue + 0.3) * frequencyValue) / 10) * 10;
        tempTotal =
          tempTotal +
          Math.ceil((item.distance * (ratePerKMValue + 0.3) * frequencyValue) / 10) * 10;
      }
      else{
        tempSubTotal =
          tempSubTotal +
          Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
        tempTotal =
          tempTotal +
          Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
      }
    }

    setSubTotalFMK(tempSubTotal);
    setTotalFMK(tempTotal);
    return null;
  });
}

  function reCalculateAmounts(){
    // alert("re calculating...");
    var tempData = [...data];
    
    tempData.map((item) => {
      if(item.hours === 0){
        return null;
      }
      else{
        return item.amount = item.hours * ratePerHourValue;
      }
    });

    setData(tempData);
  }

  function reCalculateFMKAmounts(){
    var tempData = [...data];
    
    tempData.map((item) => {
      return item.amount = Math.ceil((item.distance * ratePerKMValue * frequencyValue)/10)*10;
    });

    setData(tempData);
  }

  function getFMKclients() {
    return FMKclients.map((item, index) => {
      return (
        <MenuItem key={"FMKClient" + index} value={"F" + item.id}>
          {item.name + " " + item.surname + " (" + item.kid + ") "}
        </MenuItem>
      );
    });
  }

  function getDevClients() {
    return DEVclients.map((item, index) => {
      return (
        <MenuItem key={"DevClient" + index} value={"D" + item.id}>
          {item.name}
        </MenuItem>
      );
    });
  }

  useEffect(() => {
    apiClient.get(
      `/api/clients-fmk/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setFMKclients(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/clients-dev/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setDEVclients(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/invoices/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setInvoices(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/ratePerKms/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setRatePerKMArr(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/tripFrequencies/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setTripFrequencies(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  
  
  useEffect(() => {
    apiClient.get(
      `/api/invoices/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setInvoices(res.data)
      })
      .catch((e) => {
        console.log(e);
      });
  }, [refreshInvoices]);

  useEffect(() => {
    if(ratePerHour === 0){
      setRatePerHourValue(200);
    }
    else{
      setRatePerHourValue(300);
    }

    calculateTotal();
    // 

  }, [ratePerHour, calculateTotal]);

  useEffect(() => {
    // if(ratePerKM === 0){
    //   setRatePerKMValue(3);
    // }
    // else if(ratePerKM === 1){
    //   setRatePerKMValue(2);
    // }
    // else if(ratePerKM === 2){
    //   setRatePerKMValue(1.8);
    // }
    // else {
    //   setRatePerKMValue(1);
    // }

    calculateFMKTotal();
    // 

  }, [ratePerKM, calculateFMKTotal]);

  useEffect(() => {
    if(frequencyStr === "Daily"){
      setFrequencyValue(1);
    }
    else if(frequencyStr === "Weekly"){
      setFrequencyValue(5);
    }
    else if(frequencyStr === "Monthly"){
      setFrequencyValue(22);
    }
    else {
      setFrequencyValue(264);
    }

    calculateFMKTotal();
    // 

  }, [frequencyStr, calculateFMKTotal]);


  const DEVcolumns = [
    { title: 'TASK #', field: 'taskNumber', initialEditValue: (data.length+1), editable: 'never' },
    { title: 'DESCRIPTION', field: 'desc'},
    { title: 'HOURS', field: 'hours', type: 'numeric', align: 'left' },
    {
      title: 'AMOUNT (E)',
      field: 'amount',
      type: 'numeric',
      align: 'left',
      render: rowData => ccyFormat(rowData.hours === 0 ? rowData.amount : rowData.hours * ratePerHourValue),
      editable: 'onUpdate'
    },
  ];

  const FMKColumns = [
    { title: 'TASK #', field: 'taskNumber', initialEditValue: (data.length+1), editable: 'never' },
    { title: 'DESCRIPTION', field: 'desc'},
    { title: 'DISTANCE (KM)', field: 'distance', type: 'numeric', align: 'left' },
    {
      title: 'AMOUNT (E)',
      field: 'amount',
      type: 'numeric',
      align: 'left',
      render: rowData => ccyFormat(Math.ceil((rowData.distance * ratePerKMValue * frequencyValue)/10)*10),
      editable: 'never'
    },
  ];

  const DevDoc = () => (
    <Document>
          <Page size="A4" style={styles.page}>
            <Image src={logo} alt="" style={styles.image}/>
            <View style={styles.titleBox}>
              <Text>QUOTATION</Text>
            </View>
            <View style={styles.company}>
              <Text style={styles.bold}>BPD INVESTMENTS (PTY) LTD</Text>
              <Text>R7/60438</Text>
              <Text>TIN: 106-533-950</Text>
              <Text>Plot 409 Somhlolo Road</Text>
              <Text>Mbabane</Text>
              <Text>Eswatini</Text>
            </View>
            <View style={styles.links}>
              <Link src="https://dev.bpdteam.com" style={styles.link}>dev.bpdteam.com</Link>
              <Link src="mailto:dev@bpdteam.com" style={styles.link}>dev@bpdteam.com</Link>
              <Link src="tel:79360602" style={styles.link}>79360602</Link>
            </View>
            <View style={[styles.topInfoBox, styles.flex]}>
              <View style={[styles.paymentInfoBox]}>
                <Table
                    data={[
                        {lable: "Bank:", value: "FNB Eswatini"},
                        {lable: "Account Name:", value: "BPD Investments (PTY) LTD"},
                        {lable: "Account Number:", value: "62903359554"},
                        {lable: "Account Type:", value: "Business Cheque Account"},
                        {lable: "Branch Code:", value: "281264"},
                        {lable: "Branch Name:", value: "Coporate Place Branch"},
                        // {lable: "Reference:", value: currClient? "BPDev-"+currClient.alias : ""},
                    ]}
                >
                    <TableHeader textAlign={"center"}>
                        <TableCell style={[styles.tableHeader, styles.blankCell1]} weighting={0.3}>
                            
                        </TableCell>
                        <TableCell style={[styles.tableHeader, styles.blankCell1, styles.left, styles.smallFont]} weighting={0.7}>
                            PAYMENT INFORMATION
                        </TableCell>
                    </TableHeader>
                    <TableBody>
                        <DataTableCell style={[styles.tableBody, styles.right, styles.smallFont]} weighting={0.3} getContent={(r) => r.lable}/>
                        <DataTableCell style={[styles.tableBody, styles.smallFont]} weighting={0.7} getContent={(r) => r.value}/>
                    </TableBody>
                </Table>
              </View>
              {/* <Table
                  data={[
                      {date: "14 Jul 2021"},
                  ]}
              >
                  <TableHeader textAlign={"center"}>
                      <TableCell style={[styles.tableHeader, styles.smallFont]}>
                          DATE
                      </TableCell>
                  </TableHeader>
                  <TableBody>
                      <DataTableCell style={[styles.tableBody, styles.center, styles.smallFont]} getContent={(r) => r.date}/>
                  </TableBody>
              </Table> */}
              <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>DATE</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment().format("DD-MMM-YYYY")}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>QUOTATION No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{invoices ? ("DEV-" + pad(invoices.length+1, 4)) : null}</Text>
                  </View>
                </View>
                <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>TERMS</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{"Valid For 30 days"}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.flex}>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>QUOTATION FOR:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.name : ""}</Text>
                {currClient ?
                  currClient.address.split(',').map((item,index) => {
                    return <Text key={"address-key-"+index} style={[styles.smallFont, styles.smallIndent, styles.bold]}>{item}</Text>
                  })
                  :
                  ""
                }
              </View>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
                <Link style={[styles.smallFont, styles.smallIndent, styles.link]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
              </View>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>BUSINESS TYPE:</Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{ratePerHour === 0 ? "Start-Up" : "Coporate"}</Text>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>RATE PER HOUR (E):</Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{ratePerHour === 0 ? "200" : "300"}</Text>
            </View>
            <Table
                data={data}
            >
                <TableHeader textAlign={"center"}>
                    {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                        TASK #
                    </TableCell> */}
                    <TableCell style={styles.tableHeader} weighting={0.7}>
                        DESCRIPTION
                    </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>
                        HOURS
                    </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.2}>
                        AMOUNT (E)
                    </TableCell>
                </TableHeader>
                <TableBody>
                    {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
                    <DataTableCell style={styles.tableBody} weighting={0.7} getContent={(r) => r.desc}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.hours}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightYellowBg]} weighting={0.1}>Sub-Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder]} weighting={0.2}>{ccyFormat(subTotalDEV)}</TableCell>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.center, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg]} weighting={0.1}>|</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}></TableCell>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
                style={[styles.blankCell1]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg]} weighting={0.1}>Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}>{ccyFormat(totalDEV)}</TableCell>
                </TableBody>
            </Table>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} of ${totalPages}`
              )} fixed 
            />
          </Page>
        </Document>
  );

  const FMKDoc = () => (
    <Document>
          <Page size="A4" style={styles.page}>
            <Image src={logo1} alt="" style={styles.image}/>
            <View style={styles.titleBoxFMK}>
              <Text>QUOTATION</Text>
            </View>
            <View style={styles.company}>
              <Text style={styles.bold}>BPD INVESTMENTS (PTY) LTD</Text>
              <Text>R7/60438</Text>
              <Text>TIN: 106-533-950</Text>
              <Text>Plot 409 Somhlolo Road</Text>
              <Text>Mbabane</Text>
              <Text>Eswatini</Text>
            </View>
            <View style={styles.links}>
              <Link src="https://dev.bpdteam.com" style={styles.linkFMK}>fetchmykid.bpdteam.com</Link>
              <Link src="mailto:dev@bpdteam.com" style={styles.linkFMK}>fetchmykid@bpdteam.com</Link>
              <Link src="tel:79360602" style={styles.linkFMK}>79360602</Link>
            </View>
            <View style={[styles.topInfoBox, styles.flex]}>
              <View style={[styles.paymentInfoBox]}>
                <Table
                    data={[
                        {lable: "Bank:", value: "FNB Eswatini"},
                        {lable: "Account Name:", value: "BPD Investments (PTY) LTD"},
                        {lable: "Account Number:", value: "62903359554"},
                        {lable: "Account Type:", value: "Business Cheque Account"},
                        {lable: "Branch Code:", value: "281264"},
                        {lable: "Branch Name:", value: "Coporate Place Branch"},
                        // {lable: "Reference:", value: currClient? "FMK-"+currClient.kid : ""},
                    ]}
                >
                    <TableHeader textAlign={"center"}>
                        <TableCell style={[styles.tableHeaderFMK, styles.blankCell1]} weighting={0.3}>
                            
                        </TableCell>
                        <TableCell style={[styles.tableHeaderFMK, styles.blankCell1, styles.left, styles.smallFont]} weighting={0.7}>
                            PAYMENT INFORMATION
                        </TableCell>
                    </TableHeader>
                    <TableBody>
                        <DataTableCell style={[styles.tableBody, styles.right, styles.smallFont]} weighting={0.3} getContent={(r) => r.lable}/>
                        <DataTableCell style={[styles.tableBody, styles.smallFont]} weighting={0.7} getContent={(r) => r.value}/>
                    </TableBody>
                </Table>
              </View>
              {/* <Table
                  data={[
                      {date: "14 Jul 2021"},
                  ]}
              >
                  <TableHeader textAlign={"center"}>
                      <TableCell style={[styles.tableHeader, styles.smallFont]}>
                          DATE
                      </TableCell>
                  </TableHeader>
                  <TableBody>
                      <DataTableCell style={[styles.tableBody, styles.center, styles.smallFont]} getContent={(r) => r.date}/>
                  </TableBody>
              </Table> */}
              <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>DATE</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment().format("DD-MMM-YYYY")}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>QUOTATION No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{invoices ? ("FMK-" + pad(invoices.length+1, 4)) : null}</Text>
                  </View>
                </View>
                <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>TERMS</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{"Valid For 30 days"}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.flex}>
            <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>QUOTATION FOR:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Parent/Guardian: {currClient ? currClient.name + " " + currClient.surname : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Kid: {currClient ? currClient.kid : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.home : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.phone : ""}</Text>
              </View>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
                <Link style={[styles.smallFont, styles.smallIndent, styles.linkFMK]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
              </View>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>FREQUENCY:</Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{frequencyStr}</Text>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>RATE PER KM: </Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{`E${ccyFormat(ratePerKMValue)}`}</Text>
            </View>
            <Table
                data={data}
            >
                <TableHeader textAlign={"center"}>
                    {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                        TASK #
                    </TableCell> */}
                    <TableCell style={styles.tableHeaderFMK} weighting={0.7}>
                        DESCRIPTION
                    </TableCell>
                    <TableCell style={styles.tableHeaderFMK} weighting={0.1}>
                        DISTANCE (KM)
                    </TableCell>
                    <TableCell style={styles.tableHeaderFMK} weighting={0.2}>
                        AMOUNT (E)
                    </TableCell>
                </TableHeader>
                <TableBody>
                    {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
                    <DataTableCell style={styles.tableBody} weighting={0.7} getContent={(r) => r.desc}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.distance}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightYellowBgFMK]} weighting={0.1}>Sub-Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder]} weighting={0.2}>{ccyFormat(subTotalFMK)}</TableCell>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.center, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBgFMK]} weighting={0.1}>|</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}></TableCell>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
                ]}
                style={[styles.blankCell1]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBgFMK]} weighting={0.1}>Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}>{ccyFormat(totalFMK)}</TableCell>
                </TableBody>
            </Table>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} of ${totalPages}`
              )} fixed />
          </Page>
        </Document>
  );

  return (
    <>
    <div style={{ width: "100%"}}>
    <div>
      <Button variant="outlined" color="primary" onClick={() => handleClickOpenPreview()}>
        Preview Quotation
      </Button>
      <Button variant="outlined" color="primary" onClick={() => handleClickOpenQuickInvoice()}>
        Quick Quotation
      </Button>
      <Dialog
        fullScreen
        open={openPreview}
        onClose={() => handleClosePreview()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClosePreview()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {currClient ? currClient.name : "Client "} Quotation
            </Typography>
            <PDFDownloadLink
              document={service === "DEV" ? <DevDoc /> : <FMKDoc />}
              fileName={
                invoices
                  ? service === "DEV"
                    ? "BPDev-Quotation-" + pad(invoices.length + 1, 4)
                    : "FMK-Quotation-" + pad(invoices.length + 1, 4)
                  : "Quotation"
              }
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<GetAppIcon />}
                  >
                    Download
                  </Button>
                )
              }
            </PDFDownloadLink>
          </Toolbar>
        </AppBar>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          {service === "DEV" ? DevDoc() : FMKDoc()}
        </PDFViewer>
      </Dialog>

      <Dialog
        fullScreen
        open={openQuickInvoice}
        onClose={() => handleCloseQuickInvoice()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCloseQuickInvoice()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Quick Quotation
            </Typography>
            <PDFDownloadLink
              document={service === "DEV" ? <DevDoc /> : <FMKDoc />}
              fileName={
                invoices
                  ? service === "DEV"
                    ? "BPDev-Quotation-" + pad(invoices.length + 1, 4)
                    : "FMK-Quotation-" + pad(invoices.length + 1, 4)
                  : "Quotation"
              }
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<GetAppIcon />}
                  >
                    Download
                  </Button>
                )
              }
            </PDFDownloadLink>
          </Toolbar>
        </AppBar>
        <div style={{ display: "flex" }}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-select">Client</InputLabel>
            <Select
              defaultValue=""
              value={currClientValue}
              id="grouped-select"
              onChange={(e) => setCurrentClient(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <ListSubheader>BPDev</ListSubheader>
              {getDevClients()}
              <ListSubheader>FetchMyKid</ListSubheader>
              {getFMKclients()}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-select">
              Transport Route
            </InputLabel>
            <Select
              defaultValue={0}
              value={transportRoute}
              id="grouped-select-route"
              onChange={(e) => setTransportRouteData(e.target.value)}
            >
              <MenuItem value={0}>To and From School</MenuItem>
              <MenuItem value={1}>From School to Home</MenuItem>
              <MenuItem value={2}>From Home to School</MenuItem>
            </Select>
          </FormControl>
        </div>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          {service === "DEV" ? DevDoc() : FMKDoc()}
        </PDFViewer>
      </Dialog>
    </div>
    
    </div>
      <MaterialTable
        title="Quotation"
        columns={service === "DEV" ? DEVcolumns : FMKColumns}
        data={data}
        icons={tableIcons}
        options={{
          search: false,
          actionsColumnIndex: 0,
          exportButton: false,
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              var totalV = 0;
              
              if(service === "DEV"){
                totalV = newData.hours * ratePerHourValue;
                newData.amount = totalV;
                setSubTotalDEV(subTotalDEV + (totalV));
                setTotalDEV(totalDEV + (totalV));
              }
              else{
                totalV = Math.ceil((newData.distance * ratePerKMValue * frequencyValue)/10)*10;
                newData.amount = totalV;
                setSubTotalFMK(subTotalFMK + (totalV));
                setTotalFMK(totalFMK + (totalV));
              }

              setData([...data, newData]);
              
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              
              var totalV = 0;
              
              if(service === "DEV"){
                totalV = newData.hours * ratePerHourValue;
                newData.amount = totalV;
              }
              else{
                totalV = Math.ceil((newData.distance * ratePerKMValue * frequencyValue)/10)*10;
                newData.amount = totalV;
              }

              dataUpdate[index] = newData;
              setData([...dataUpdate]);

              
              if(service === "DEV"){
                calculateTotal();
              }
              else{
                calculateFMKTotal();
              }

              resolve();
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setData([...dataDelete]);

              if(service === "DEV"){
                calculateTotal();
              }
              else{
                calculateFMKTotal();
              }
              
              resolve();
            }),
        }}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              var totalV = 0;
              if(columnDef.title === 'HOURS'){
                totalV = newValue * ratePerHourValue;
                setSubTotalDEV(subTotalDEV + totalV);
                setTotalDEV(totalDEV + totalV);

                rowData.hours = newValue;
                rowData.amount = totalV;

                calculateTotal();
              }

              if(columnDef.title === 'DISTANCE (KM)'){
                totalV = Math.ceil((newValue * ratePerKMValue * frequencyValue)/10)*10;
                setSubTotalFMK(subTotalFMK + totalV);
                setTotalFMK(totalFMK + totalV);

                rowData.distance = newValue;
                rowData.amount = totalV;

                calculateFMKTotal();
              }

              if(columnDef.title === 'DESCRIPTION'){
                rowData.desc = newValue;
              }

              const dataUpdate = [...data];
              const index = rowData.tableData.id;
              dataUpdate[index] = rowData;
              setData([...dataUpdate]);
              resolve();

            });
          }
        }}
        components={{
          Pagination: PatchedPagination,
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <div style={{padding: '0px 10px'}}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Service</InputLabel>
                  <Select defaultValue={"FMK"} value={service} onChange={(e) => {setService(e.target.value); reCalculateAmounts()}} id="grouped-select">
                    <MenuItem value={"DEV"}>
                      BPDev
                    </MenuItem>
                    <MenuItem value={"FMK"}>
                      FetchMyKid
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  {service === "DEV" ?
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={DEVclients}
                      sx={{ width: 300 }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="For" />}
                      onChange={(event, newValue) => {
                        setCurrClient(newValue);
                      }}
                    />
                    :
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={FMKclients}
                      sx={{ width: 300 }}
                      getOptionLabel={(option) => option.name + " " + option.surname}
                      renderInput={(params) => <TextField {...params} label="For" />}
                      onChange={(event, newValue) => {
                        setCurrClient(newValue);
                      }}
                    />
                  }
                </FormControl>
                {service === "DEV" ?
                  <>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">Business Type</InputLabel>
                      <Select defaultValue={1} value={businessType} onChange={(e) => {setRatePerHour(e.target.value); setBusinessType(e.target.value); reCalculateAmounts()}} id="grouped-select">
                        <MenuItem value={0}>
                          Start-Up
                        </MenuItem>
                        <MenuItem value={1}>
                          Corporate
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">Rate Per Hour (E)</InputLabel>
                      <Select defaultValue={1} value={ratePerHour} onChange={(e) => {setRatePerHour(e.target.value); setBusinessType(e.target.value)}} id="grouped-select">
                        <MenuItem value={0}>
                          200
                        </MenuItem>
                        <MenuItem value={1}>
                          300
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                  :
                  <>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">Frequency</InputLabel>
                      <Select defaultValue={3} value={frequency} onChange={(e) => { setRate(e.target.value); setFrequency(e.target.value); reCalculateFMKAmounts()}} id="grouped-select">
                        {
                          tripFrequencies.length !== 0 ? 
                            tripFrequencies.map(item => {
                              return <MenuItem key={"Freq"+item.id} value={item.id}>{item.name}</MenuItem>
                            })
                            :
                            null
                        }
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">Rate Per KM (E)</InputLabel>
                      <Select disabled={true} defaultValue={5} value={ratePerKM}  id="grouped-select">
                        {
                          ratePerKMArr.length !== 0 ? 
                            ratePerKMArr.map(item => {
                              return <MenuItem key={"RPKM"+item.id} value={item.id}>{item.name}</MenuItem>
                            })
                            :
                            null
                        }
                      </Select>
                    </FormControl>
                  </>
                }
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Terms</InputLabel>
                  <Select defaultValue={0} onChange={(e) => {e.target.value === 0 ? setTerms("Due upon receipt") : setTerms("Due end of the month")}} id="grouped-select">
                    <MenuItem value={0}>
                      Due upon receipt
                    </MenuItem>
                    <MenuItem value={1}>
                      Due end of the month
                    </MenuItem>
                  </Select>
                </FormControl>
                <PDFDownloadLink document={service === "DEV" ? <DevDoc /> : <FMKDoc />} fileName={invoices ? service === "DEV" ? ("BPDev-Quotation-" + pad(invoices.length+1, 4)) : ("FMK-Quotation-" + pad(invoices.length+1, 4)) : "Quotation"}>
                  {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 
                    <IconButton
                      variant="contained"
                      color="default"
                      className={classes.button}
                    >
                      <GetAppIcon />
                    </IconButton>
                  }
                </PDFDownloadLink>
                <IconButton
                  color="default"
                  className={classes.button}
                  onClick={() => handleSaveInvoice()}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  color="default"
                  className={classes.button}
                  onClick={() => window.location.reload()}
                >
                  <RefreshIcon />
                </IconButton>
                
              </div>
            </div>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <TableMUI className={classes.table} aria-label="spanning table">
          <TableBodyMUI>
            <TableRowMUI>
              <TableCellMUI rowSpan={3} />
              <TableCellMUI colSpan={2}>Subtotal</TableCellMUI>
              <TableCellMUI align="right">{ccyFormat(service === "DEV" ? subTotalDEV : subTotalFMK)}</TableCellMUI>
            </TableRowMUI>
            <TableRowMUI>
              {/* <TableCell>Tax</TableCell> */}
              {/* <TableCell align="right">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell> */}
              {/* <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell> */}
            </TableRowMUI>
            <TableRowMUI>
              <TableCellMUI colSpan={2}>Total</TableCellMUI>
              <TableCellMUI align="right">{ccyFormat(service === "DEV" ? totalDEV : totalFMK)}</TableCellMUI>
            </TableRowMUI>
          </TableBodyMUI>
        </TableMUI>
      </TableContainer>
    </>
  );
}
