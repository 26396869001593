import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from "@mui/material";
  import React from "react";
  import { NumericFormat } from "react-number-format";
  import PropTypes from "prop-types";
  //import SupplierCredit from "../../components/SupplyCredit";
  
  const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        valueIsNumericString
        prefix="E"
      />
    );
  });
  
  NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
  export default function CreditCardCreditDialog(props) {
    return (
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Credit Card Credit</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in the form to add the Credit Card Credit
          </DialogContentText>
          <Grid style={{ marginTop: "10px" }} container spacing={2}>
            <Grid item xs={6}>
              <TextField
                value={props.payee}
                onChange={(e) => props.setPayee(e.target.value)}
                margin="dense"
                id="payee"
                label="Payee"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={props.paymentDate}
                onChange={(e) => props.setPaymentDate(e.target.value)}
                margin="dense"
                id="paymentDate"
                label="Payment Date"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={props.refNo}
                onChange={(e) => props.setRefNo(e.target.value)}
                margin="dense"
                id="refNo"
                label="Ref No."
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Subsidiary
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.subsidiary}
                  label="Select Subsidiary"
                  onChange={(e) => props.setSubsidiary(e.target.value)}
                >
                  <MenuItem value={"Fmk"}>FMK</MenuItem>
                  <MenuItem value={"Bpdev"}>BPDdev</MenuItem>
                  <MenuItem value={"Bpdrobotics"}>BPDRobotics</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Credit Account Number
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.creditAccount}
                  label="Credit Account"
                  onChange={(e) => props.setcreditAccount(e.target.value)}
                >
                  <MenuItem value={"FNB"}>FNB</MenuItem>
                  <MenuItem value={"StandardBank"}>Standard Bank</MenuItem>
                  <MenuItem value={"EswatiniBank"}>Eswatini Bank</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={props.description}
                onChange={(e) => props.setDescription(e.target.value)}
                margin="dense"
                id="description"
                label="Description"
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                value={props.amount}
                onChange={(e) => props.setAmount(e.target.value)}
                name="amount"
                margin="dense"
                id="amount"
                label="Amount"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={props.handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }
  