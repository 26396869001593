import React, { useState, useEffect, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableMUI from "@material-ui/core/Table";
import TableBodyMUI from "@material-ui/core/TableBody";
import TableCellMUI from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRowMUI from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MaterialTable, {MTableToolbar} from '@material-table/core';
import apiClient from "../../apiClient";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Link,
  Font,
} from "@react-pdf/renderer";
import {
  TableHeader,
  DataTableCell,
  Table,
  TableBody,
  TableCell,
} from "@david.kucsai/react-pdf-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import SaveIcon from "@material-ui/icons/Save";
import RefreshIcon from "@material-ui/icons/Refresh";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import moment from "moment";
import PatchedPagination from "../../elements/PatchedPagination";

import source from '../../assets/fonts/Roboto/Roboto-Bold.ttf';
import InvoiceGenerator from "../InvoiceGenerator";

Font.register({
  family: "Roboto",
  src: source,
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingBottom: 55,
  },
  header: {
    width: "100%",
    backgroundColor: "#BDBDBD",
  },
  titleBox: {
    width: "250px",
    textAlign: "center",
    padding: 10,
    position: "absolute",
    top: 20,
    right: 20,
    backgroundColor: "#fed700",
  },
  titleBoxFMK: {
    width: "250px",
    textAlign: "center",
    padding: 10,
    position: "absolute",
    top: 20,
    right: 20,
    backgroundColor: "#4479d9",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: "60px",
    height: "60px",
  },
  company: {
    fontSize: "8px",
    position: "absolute",
    top: 23,
    left: 80,
  },
  links: {
    fontSize: "8px",
    position: "absolute",
    top: 23,
    left: 210,
  },
  link: {
    color: "#ad9300",
    textDecoration: "underline",
    paddingBottom: 3,
  },
  linkFMK: {
    color: "#2f5598",
    textDecoration: "underline",
    paddingBottom: 3,
  },
  flex: {
    flexDirection: "row",
    margin: "10px",
  },
  infoBox: {
    width: "50%",
    fontSize: "10px",
    padding: 10,
  },
  smallIndent: {
    textIndent: "60px",
  },
  bold: {},
  tableHeader: {
    fontSize: "8px",
    backgroundColor: "#ffec82",
    padding: 5,
    fontFamily: 'Roboto'
  },
  lightYellowBg: {
    backgroundColor: "#ffec82",
  },
  tableHeaderFMK: {
    fontSize: "8px",
    backgroundColor: "#95b4ea",
    padding: 5,
    fontFamily: 'Roboto'
  },
  lightBlueBg: {
    backgroundColor: "#95b4ea",
  },
  tableBody: {
    fontSize: "8px",
    padding: 5,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  blankCell1: {
    border: "unset",
  },
  blankCell2: {
    borderLeft: "unset",
  },
  singleCellHeader: {
    backgroundColor: "#ffec82",
    border: "1px solid #000",
    pading: "10px",
    width: "100px",
    heiight: "80px",
    textAlign: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  singleCellHeaderFMK: {
    backgroundColor: "#95b4ea",
    border: "1px solid #000",
    pading: "10px",
    width: "100px",
    heiight: "80px",
    textAlign: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  singleCellBody: {
    border: "1px solid #000",
    pading: "10px",
    width: "100px",
    textAlign: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  topInfoBox: {
    paddingBottom: "10px",
    marginTop: "15px",
  },
  paymentInfoBox: {
    width: "290px",
  },
  smallFont: {
    fontSize: "8px",
  },
  topBorder: {
    borderTop: "2px solid #000",
  },
  margLeft: {
    marginLeft: "10px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  invisibleBorder: {
    borderBottom: "3px solid white",
    borderLeft: "2px solid white",
    margin: "0",
    marginBottom: "-2px",
    marginLeft: "-2px",
    borderCollapse: "collapse!important",
    borderStyle: "hidden!important",
    outline: "none!important",
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

const PETROL_PRICE = 23.55;

export default function Invoice() {
  const classes = useStyles();
  const [openPreview, setOpenPreview] = React.useState(false);
  const [openQuickInvoice, setOpenQuickInvoice] = React.useState(false);
  const [currClientID, setCurrClientID] = useState();
  const [currClientValue, setCurrClientValue] = useState("");
  const [currClient, setCurrClient] = useState();
  const [currService, setCurrService] = useState("");
  const [FMKclients, setFMKclients] = useState([]);
  const [DEVclients, setDEVclients] = useState([]);
  const [ROBclients, setROBclients] = useState([]);
  const [subTotalFMK, setSubTotalFMK] = useState(0);
  const [subTotalDEV, setSubTotalDEV] = useState(0);
  const [subTotalROB, setSubTotalROB] = useState(0);
  const [totalFMK, setTotalFMK] = useState(0);
  const [totalDEV, setTotalDEV] = useState(0);
  const [totalROB, setTotalROB] = useState(0);
  const [data, setData] = useState([]);
  const [businessType, setBusinessType] = useState(1);
  const [ratePerHour, setRatePerHour] = useState(1);
  const [frequency, setFrequency] = useState(3);
  const [ratePerKM, setRatePerKM] = useState(5);
  const [ratePerKMArr, setRatePerKMArr] = useState([]);
  const [tripFrequencies, setTripFrequencies] = useState([]);
  const [ratePerHourValue, setRatePerHourValue] = useState(300);
  const [ratePerMonthValue, setRatePerMonthValue] = useState(350);
  const [ratePerKMValue, setRatePerKMValue] = useState(PETROL_PRICE/10.7); 
  const [oneWayRatePerKMValue, setOneWayRatePerKMValue] = useState(PETROL_PRICE/10.7); 
  const [frequencyValue, setFrequencyValue] = useState(22);
  const [frequencyStr, setFrequencyStr] = useState("Monthly");
  const [invoices, setInvoices] = useState();
  const [terms, setTerms] = useState("Due upon receipt");
  const [termsId, setTermsId] = useState(1);
  const [refreshInvoices, setRefreshInvoices] = useState(0);
  const [transportRoute, setTransportRoute] = useState("");
  const [discount, setDiscount] = useState(0);
  const [credit, setCredit] = useState(0);
  const [outstanding, setOutstanding] = useState(0);

  const handleClickOpenPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleClickOpenQuickInvoice = () => {
    setOpenQuickInvoice(true);
  };

  const handleCloseQuickInvoice = () => {
    setOpenQuickInvoice(false);
  };

  const handleSaveInvoice = () => {
    var datapack;

    if (currService === "DEV") {
      datapack = {
        client_dev_id: currClientID,
        client_no: "DEV-" + currClient.id,
        invoice_no: "DEV-" + pad(invoices.length + 1, 4),
        date: moment().format("DD-MMM-YYYY"),
        items: JSON.stringify(data),
        amount: totalDEV,
        terms_id: termsId,
        discount: discount
      };

      apiClient.put(
        `/api/clients-dev/${currClient.uuid}`,
        {
          ...currClient,
          outstanding: totalDEV
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        });
    } else if (currService === "FMK"){
      datapack = {
        client_fmk_id: currClientID,
        client_no: "FMK-" + currClient.id,
        invoice_no: "FMK-" + pad(invoices.length + 1, 4),
        date: moment().format("DD-MMM-YYYY"),
        items: JSON.stringify(data),
        amount: totalFMK,
        terms_id: termsId,
        discount: discount
      };

      apiClient.put(
        `/api/clients-fmk/${currClient.uuid}`,
        {
          ...currClient,
          outstanding: totalFMK
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        });
    } else if (currService === "ROB"){
      datapack = {
        client_rob_id: currClientID,
        client_no: "ROB-" + currClient.id,
        invoice_no: "ROB-" + pad(invoices.length + 1, 4),
        date: moment().format("DD-MMM-YYYY"),
        items: JSON.stringify(data),
        amount: totalROB,
        terms_id: termsId,
        discount: discount
      };

      apiClient.put(
        `/api/clients-rob/${currClient.uuid}`,
        {
          ...currClient,
          outstanding: totalROB
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        });
    }
    
    apiClient.post(
      `/api/invoices/`,
      datapack,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      .then((res) => {
        setRefreshInvoices(refreshInvoices + 1);
        alert("Invoice Saved Successfully");
      })
      .catch((e) => {
        alert("Something went wrong while saving invoice. Please try again");
      });
  };

  function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  function setCurrentClient(client) {
    setData([]);
    setCurrClientValue(client);
    var result;
    var tempCurrClient;

    if (client.charAt(0) === "D") {
      result = client.slice(1);
      tempCurrClient = DEVclients[DEVclients.findIndex((x) => x.id === parseInt(result, 10))];
      setCurrClientID(parseInt(result, 10));
      setCurrClient(tempCurrClient);
      setCurrService("DEV");
    } else if (client.charAt(0) === "F") {
      result = client.slice(1);
      tempCurrClient = FMKclients[FMKclients.findIndex((x) => x.id === parseInt(result, 10))]
      setCurrClientID(parseInt(result, 10));
      setCurrClient(tempCurrClient);
      setCurrService("FMK");
    } else if (client.charAt(0) === "R") {
      result = client.slice(1);
      tempCurrClient = ROBclients[ROBclients.findIndex((x) => x.id === parseInt(result, 10))]
      setCurrClientID(parseInt(result, 10));
      setCurrClient(tempCurrClient);
      setCurrService("ROB");
    }

    if(tempCurrClient.outstanding !== 0 && tempCurrClient.outstanding !== null){
      setData([
        ...data,
        {
          taskNumber: data.length + 1,
          desc: `Outstanding amount brought forward`,
          distance: (tempCurrClient.outstanding/(ratePerKMValue * frequencyValue)).toFixed(1),
          amount: tempCurrClient.outstanding,
          tableData: { id: data.length + 1 },
        }
      ]);
    }

    if(tempCurrClient.credit !== 0 && tempCurrClient.credit !== null){
      setData([
        ...data,
        {
          taskNumber: data.length + 1,
          desc: `Credit amount brought forward`,
          distance: (tempCurrClient.credit/(ratePerKMValue * frequencyValue)).toFixed(1),
          amount: tempCurrClient.credit,
          tableData: { id: data.length + 1 },
        }
      ]);
    }
  }

  function setTransportRouteData(index) {
    if (index === 0) {
      setTransportRoute("To and From School");
      setData([
        ...data,
        {
          taskNumber: data.length + 1,
          desc: `From ${currClient.home} to ${currClient.school}`,
          distance: currClient.distance,
          amount: Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: data.length + 1 },
        },
        {
          taskNumber: data.length + 2,
          desc: `From ${currClient.school} to ${currClient.home}`,
          distance: currClient.distance,
          amount: Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: data.length + 2 },
        },
      ]);
    }
    else if (index === 1) {
      setTransportRoute("From School to Home");
      setData([
        ...data,
        {
          taskNumber: data.length + 1,
          desc: `From ${currClient.school} to ${currClient.home}`,
          distance: currClient.distance,
          amount: currClient.distance <= 5 ? Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10 : Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: data.length + 1 },
        }
      ]);
    }
    else if (index === 2) {
      setTransportRoute("From Home to School");
      setData([
        ...data,
        {
          taskNumber: data.length + 1,
          desc: `From ${currClient.home} to ${currClient.school}`,
          distance: currClient.distance,
          amount: currClient.distance <= 5 ? Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10 : Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: data.length + 1 },
        }
      ]);
    }
  }

  function setRate(id) {
    setRatePerKM(
      ratePerKMArr[
        ratePerKMArr.findIndex(
          (x) =>
            x.id ===
            tripFrequencies[tripFrequencies.findIndex((y) => y.id === id)]
              .rate_per_km_id
        )
      ].id
    );
    setRatePerKMValue(
      parseInt(
        ratePerKMArr[
          ratePerKMArr.findIndex(
            (x) =>
              x.id ===
              tripFrequencies[tripFrequencies.findIndex((y) => y.id === id)]
                .rate_per_km_id
          )
        ].name
      )
    );
    setFrequencyStr(
      tripFrequencies[tripFrequencies.findIndex((y) => y.id === id)].name
    );
    // alert(ratePerKMArr[ratePerKMArr.findIndex(x => x.id === tripFrequencies[tripFrequencies.findIndex(y => y.id === id)].rate_per_km_id)].name);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = tempSubTotal + item.hours * ratePerHourValue;
      tempTotal = tempTotal + item.hours * ratePerHourValue;
      setSubTotalDEV(tempSubTotal);
      setTotalDEV(discount !== null && discount !== 0 ?  tempTotal * (discount/100) : tempTotal);
      return null;
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateROBTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = tempSubTotal + item.months * ratePerMonthValue;
      tempTotal = tempTotal + item.months * ratePerMonthValue;
      setSubTotalROB(tempSubTotal);
      setTotalROB(discount !== null && discount !== 0 ?  tempTotal * (discount/100) : tempTotal);
      return null;
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateFMKTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {

      if (transportRoute === "To and From School") {
        tempSubTotal =
          tempSubTotal +
          Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
        tempTotal =
          tempTotal +
          Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
      }
      else {
        if(item.distance <= 5){
          tempSubTotal =
            tempSubTotal +
            Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
          tempTotal =
            tempTotal +
            Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
        }
        else{
          tempSubTotal =
            tempSubTotal +
            Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
          tempTotal =
            tempTotal +
            Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
        }
      }

      setSubTotalFMK(tempSubTotal);
      setTotalFMK(discount !== null && discount !== 0 ?  (tempSubTotal - (tempTotal * (discount/100))) : tempTotal);
      return null;
    });
  }

  function reCalculateAmounts() {
    // alert("re calculating...");
    var tempData = [...data];

    tempData.map((item) => {
      if (item.hours === 0) {
        return null;
      } else {
        return (item.amount = item.hours * ratePerHourValue);
      }
    });

    setData(tempData);
  }

  function reCalculateFMKAmounts() {
    var tempData = [...data];

    tempData.map((item) => {
      return (item.amount =
        Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10);
    });

    setData(tempData);
  }

  function reCalculateROBAmounts() {
    var tempData = [...data];

    tempData.map((item) => {
      return (item.amount =
        Math.ceil(item.months * ratePerMonthValue));
    });

    setData(tempData);
  }

  function getFMKclients() {
    return FMKclients.map((item, index) => {
      return (
        <MenuItem key={"FMKClient" + index} value={"F" + item.id}>
          {item.name + " " + item.surname + " (" + item.kid + ") "}
        </MenuItem>
      );
    });
  }

  function getROBclients() {
    return ROBclients.map((item, index) => {
      return (
        <MenuItem key={"ROBClient" + index} value={"R" + item.id}>
          {item.name + " " + item.surname + " (" + item.kid + ") "}
        </MenuItem>
      );
    });
  }

  function getDevClients() {
    return DEVclients.map((item, index) => {
      return (
        <MenuItem key={"DevClient" + index} value={"D" + item.id}>
          {item.name}
        </MenuItem>
      );
    });
  }

  function getROBClients() {
    return ROBclients.map((item, index) => {
      return (
        <MenuItem key={"ROBClient" + index} value={"R" + item.id}>
          {item.name + " " + item.surname + " (" + item.kid + ") "}
        </MenuItem>
      );
    });
  }

  useEffect(() => {
    apiClient.get(
      `/api/clients-fmk/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setFMKclients(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/clients-dev/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setDEVclients(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/clients-rob/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setROBclients(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/invoices/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setInvoices(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/ratePerKms/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setRatePerKMArr(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/tripFrequencies/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setTripFrequencies(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    apiClient.get(
      `/api/invoices/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setInvoices(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [refreshInvoices]);

  useEffect(() => {
    if (ratePerHour === 0) {
      setRatePerHourValue(200);
    } else {
      setRatePerHourValue(300);
    }

    calculateTotal();
    //
  }, [ratePerHour, calculateTotal]);

  useEffect(() => {
    // if(ratePerKM === 0){
    //   setRatePerKMValue(3);
    // }
    // else if(ratePerKM === 1){
    //   setRatePerKMValue(2);
    // }
    // else if(ratePerKM === 2){
    //   setRatePerKMValue(1.8);
    // }
    // else {
    //   setRatePerKMValue(1);
    // }

    calculateFMKTotal();
    //
  }, [ratePerKM, calculateFMKTotal]);

  useEffect(() => {
    if (frequencyStr === "Daily") {
      setFrequencyValue(1);
    } else if (frequencyStr === "Weekly") {
      setFrequencyValue(5);
    } else if (frequencyStr === "Monthly") {
      setFrequencyValue(22);
    } else {
      setFrequencyValue(264);
    }

    calculateFMKTotal();
    //
  }, [frequencyStr, calculateFMKTotal]);

  const DEVcolumns = [
    {
      title: "TASK #",
      field: "taskNumber",
      initialEditValue: data.length + 1,
      editable: "never",
    },
    { title: "DESCRIPTION", field: "desc" },
    { title: "HOURS", field: "hours", type: "numeric", align: "left" },
    {
      title: "AMOUNT (E)",
      field: "amount",
      type: "numeric",
      align: "left",
      render: (rowData) =>
        ccyFormat(
          rowData.hours === 0
            ? rowData.amount
            : rowData.hours * ratePerHourValue
        ),
      editable: "onUpdate",
    },
  ];

  const FMKColumns = [
    {
      title: "TASK #",
      field: "taskNumber",
      initialEditValue: data.length + 1,
      editable: "never",
    },
    { title: "DESCRIPTION", field: "desc" },
    {
      title: "DISTANCE (KM)",
      field: "distance",
      type: "numeric",
      align: "left",
    },
    {
      title: "AMOUNT (E)",
      field: "amount",
      type: "numeric",
      align: "left",
      render: (rowData) =>
        ccyFormat(
          Math.ceil((rowData.distance * ratePerKMValue * frequencyValue) / 10) *
            10
        ),
      editable: "never",
    },
  ];

  const BPDevRoboticsColumns = [
    {
      title: "TASK #",
      field: "taskNumber",
      initialEditValue: data.length + 1,
      editable: "never",
    },
    { title: "DESCRIPTION", field: "desc" },
    {
      title: "MONTHS",
      field: "months",
      type: "numeric",
      align: "left",
    },
    {
      title: "AMOUNT (E)",
      field: "amount",
      type: "numeric",
      align: "left",
      render: (rowData) =>
        ccyFormat(Math.ceil((rowData.months * ratePerMonthValue))),
      editable: "never",
    },
  ];

  return (
    <>
      <div style={{ width: "100%" }}>
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClickOpenPreview()}
          >
            Preview Invoice
          </Button>
          {/* <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClickOpenQuickInvoice()}
          >
            Quick Invoice
          </Button> */}

          <Dialog
            fullScreen
            open={openPreview}
            onClose={() => handleClosePreview()}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => handleClosePreview()}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {currClient ? currClient.name : "Client "} Invoice
                </Typography>
                <PDFDownloadLink
                  document={
                    <InvoiceGenerator 
                      currentService={currService} 
                      invoiceNo={
                        currService === "FMK" ?
                          (invoices ? "FMK-" + pad(invoices.length + 1, 4) : null)
                          :
                          currService === "DEV" ?
                            (invoices ? "DEV-" + pad(invoices.length + 1, 4) : null)
                            :
                            (invoices ? "ROB-" + pad(invoices.length + 1, 4) : null)
                      }
                      clientNo={
                        currService === "FMK" ?
                          (currClient ? "FMK-" + currClient.id : null)
                          :
                          currService === "DEV" ?
                            (currClient ? "DEV-" + currClient.id : null)
                            :
                            (currClient ? "ROB-" + currClient.id : null)
                      }
                      date={new Date()}
                      subTotalAmount={
                        currService === "FMK" ?
                          ccyFormat(subTotalFMK)
                          :
                          currService === "DEV" ?
                            ccyFormat(subTotalDEV)
                            :
                            ccyFormat(subTotalROB)
                      }
                      totalAmount={
                        currService === "FMK" ?
                          ccyFormat(totalFMK)
                          :
                          currService === "DEV" ?
                            ccyFormat(totalDEV)
                            :
                            ccyFormat(totalROB)
                      }
                      businessTypeId={
                        currService === "DEV" ?
                          ratePerHour === 0 ? "Start-Up" : "Corporate"
                          :
                          null
                      }
                      discount={discount}
                      data={JSON.stringify(data)} 
                      client={currClient}/>
                  }
                  // document={<InvoiceGenerators rowData={rowData} client={getCurrentClient(rowData.client_no)}/>}
                  fileName={
                    invoices
                      ? currService === "DEV" ? 
                          "BPDev-Invoice-" + pad(invoices.length + 1, 4)
                          : 
                          currService === "FMK" ? 
                            "FMK-Invoice-" + pad(invoices.length + 1, 4)
                            :
                            "Robotics-Invoice-" + pad(invoices.length + 1, 4)
                      : 
                      "Invoice"
                  }
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                      >
                        Download
                      </Button>
                    )
                  }
                </PDFDownloadLink>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={() => handleSaveInvoice()}
                >
                  Save
                </Button>
              </Toolbar>
            </AppBar>
            <PDFViewer style={{ width: "100%", height: "100vh" }}>
            {
                    <InvoiceGenerator 
                      currentService={currService} 
                      invoiceNo={
                        currService === "FMK" ?
                          (invoices ? "FMK-" + pad(invoices.length + 1, 4) : null)
                          :
                          currService === "DEV" ?
                            (invoices ? "DEV-" + pad(invoices.length + 1, 4) : null)
                            :
                            (invoices ? "ROB-" + pad(invoices.length + 1, 4) : null)
                      }
                      clientNo={
                        currService === "FMK" ?
                          (currClient ? "FMK-" + currClient.id : null)
                          :
                          currService === "DEV" ?
                            (currClient ? "DEV-" + currClient.id : null)
                            :
                            (currClient ? "ROB-" + currClient.id : null)
                      }
                      date={new Date()}
                      subTotalAmount={
                        currService === "FMK" ?
                          ccyFormat(subTotalFMK)
                          :
                          currService === "DEV" ?
                            ccyFormat(subTotalDEV)
                            :
                            ccyFormat(subTotalROB)
                      }
                      totalAmount={
                        currService === "FMK" ?
                          ccyFormat(totalFMK)
                          :
                          currService === "DEV" ?
                            ccyFormat(totalDEV)
                            :
                            ccyFormat(totalROB)
                      }
                      businessTypeId={
                        currService === "DEV" ?
                          ratePerHour === 0 ? "Start-Up" : "Corporate"
                          :
                          null
                      }
                      discount={discount}
                      data={JSON.stringify(data)} 
                      client={currClient}/>
                  }
            </PDFViewer>
          </Dialog>

          <Dialog
            fullScreen
            open={openQuickInvoice}
            onClose={() => handleCloseQuickInvoice()}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => handleCloseQuickInvoice()}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Quick Invoice
                </Typography>
                <PDFDownloadLink
                  document={
                    <InvoiceGenerator 
                      currentService={currService} 
                      invoiceNo={
                        currService === "FMK" ?
                          (invoices ? "FMK-" + pad(invoices.length + 1, 4) : null)
                          :
                          currService === "DEV" ?
                            (invoices ? "DEV-" + pad(invoices.length + 1, 4) : null)
                            :
                            (invoices ? "ROB-" + pad(invoices.length + 1, 4) : null)
                      }
                      clientNo={
                        currService === "FMK" ?
                          (currClient ? "FMK-" + currClient.id : null)
                          :
                          currService === "DEV" ?
                            (currClient ? "DEV-" + currClient.id : null)
                            :
                            (currClient ? "ROB-" + currClient.id : null)
                      }
                      date={new Date()}
                      subTotalAmount={
                        currService === "FMK" ?
                          ccyFormat(subTotalFMK)
                          :
                          currService === "DEV" ?
                            ccyFormat(subTotalDEV)
                            :
                            ccyFormat(subTotalROB)
                      }
                      totalAmount={
                        currService === "FMK" ?
                          ccyFormat(totalFMK)
                          :
                          currService === "DEV" ?
                            ccyFormat(totalDEV)
                            :
                            ccyFormat(totalROB)
                      }
                      businessTypeId={
                        currService === "DEV" ?
                          ratePerHour === 0 ? "Start-Up" : "Corporate"
                          :
                          null
                      }
                      discount={discount}
                      data={JSON.stringify(data)} 
                      client={currClient}/>
                  }
                  fileName={
                    invoices
                      ? currService === "DEV" ? 
                          "BPDev-Invoice-" + pad(invoices.length + 1, 4)
                          : 
                          currService === "FMK" ? 
                            "FMK-Invoice-" + pad(invoices.length + 1, 4)
                            :
                            "Robotics-Invoice-" + pad(invoices.length + 1, 4)
                      : 
                      "Invoice"
                  }
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                      >
                        Download
                      </Button>
                    )
                  }
                </PDFDownloadLink>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={() => handleSaveInvoice()}
                >
                  Save
                </Button>
              </Toolbar>
            </AppBar>
            <div style={{ display: "flex" }}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="grouped-select">Client</InputLabel>
                <Select
                  defaultValue=""
                  value={currClientValue}
                  id="grouped-select"
                  onChange={(e) => setCurrentClient(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <ListSubheader>BPDev</ListSubheader>
                  {getDevClients()}
                  <ListSubheader>FetchMyKid</ListSubheader>
                  {getFMKclients()}
                  <ListSubheader>BPDev Robotics</ListSubheader>
                  {getROBclients()}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="grouped-select">
                  Transport Route
                </InputLabel>
                <Select
                  defaultValue={0}
                  value={transportRoute}
                  id="grouped-select-route"
                  onChange={(e) => {
                      setTransportRouteData(e.target.value);
                      setTransportRoute(e.target.value);
                    }
                  }
                >
                  <MenuItem value={0}>To and From School</MenuItem>
                  <MenuItem value={1}>From School to Home</MenuItem>
                  <MenuItem value={2}>From Home to School</MenuItem>
                </Select>
              </FormControl>
            </div>
            <PDFViewer style={{ width: "100%", height: "100vh" }}>
              {
                data ?
                <InvoiceGenerator 
                  currentService={currService} 
                  invoiceNo={
                    currService === "FMK" ?
                      (invoices ? "FMK-" + pad(invoices.length + 1, 4) : null)
                      :
                      (invoices ? "DEV-" + pad(invoices.length + 1, 4) : null)
                  }
                  clientNo={
                    currService === "FMK" ?
                      (currClient ? "FMK-" + currClient.id : null)
                      :
                      (currClient ? "DEV-" + currClient.id : null)
                  }
                  date={new Date()}
                  subTotalAmount={
                    currService === "FMK" ?
                      ccyFormat(subTotalFMK)
                      :
                      ccyFormat(subTotalDEV)
                  }
                  totalAmount={
                    currService === "FMK" ?
                      ccyFormat(totalFMK)
                      :
                      ccyFormat(totalDEV)
                  }
                  businessTypeId={
                    currService === "DEV" ?
                      ratePerHour === 0 ? "Start-Up" : "Corporate"
                      :
                      null
                  }
                  discount={discount}
                  data={JSON.stringify(data)} 
                  client={currClient}
                />
                :
                null
              }
            </PDFViewer>
          </Dialog>
        </div>
      </div>
      <MaterialTable
        title="Invoice"
        columns={currService === "DEV" ? DEVcolumns : currService === "FMK" ? FMKColumns : BPDevRoboticsColumns}
        data={data}
        icons={tableIcons}
        options={{
          search: false,
          actionsColumnIndex: 0,
          exportButton: false,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              var totalV = 0;

              if (currService === "DEV") {
                totalV = newData.hours * ratePerHourValue;
                newData.amount = totalV;
                setSubTotalDEV(subTotalDEV + totalV);
                setTotalDEV(discount !== null && discount !== 0 ? (totalDEV + totalV) * (discount/100) : (totalDEV + totalV));
              } else if (currService === "FMK") {
                totalV =
                  Math.ceil(
                    (newData.distance * ratePerKMValue * frequencyValue) / 10
                  ) * 10;
                newData.amount = totalV;
                setSubTotalFMK(subTotalFMK + totalV);
                setTotalFMK(discount !== null && discount !== 0 ? ((subTotalFMK + totalV) - ((totalFMK + totalV) * (discount/100))) : (totalFMK + totalV));
              } else{
                totalV = newData.months * ratePerMonthValue;
                newData.amount = totalV;
                setSubTotalROB(subTotalROB + totalV);
                setTotalROB(discount !== null && discount !== 0 ? (totalROB + totalV) * (discount/100) : (totalROB + totalV));
              }

              setData([...data, newData]);

              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;

              var totalV = 0;

              if (currService === "DEV") {
                totalV = newData.hours * ratePerHourValue;
                newData.amount = totalV;
              } else if (currService === "FMK") {
                totalV =
                  Math.ceil(
                    (newData.distance * ratePerKMValue * frequencyValue) / 10
                  ) * 10;
                newData.amount = totalV;
              } else {
                totalV = newData.months * ratePerMonthValue;
                newData.amount = totalV;
              }

              dataUpdate[index] = newData;
              setData([...dataUpdate]);

              if (currService === "DEV") {
                calculateTotal();
              } else if (currService === "FMK") {
                calculateFMKTotal();
              } else {
                calculateROBTotal();
              }

              resolve();
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setData([...dataDelete]);

              if (currService === "DEV") {
                calculateTotal();
              } else if (currService === "FMK") {
                calculateFMKTotal();
              } else {
                calculateROBTotal();
              }

              resolve();
            }),
        }}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              var totalV = 0;
              if (columnDef.title === "HOURS") {
                totalV = newValue * ratePerHourValue;
                setSubTotalDEV(subTotalDEV + totalV);
                setTotalDEV(discount !== null && discount !== 0 ? (totalDEV + totalV) * (discount/100) : (totalDEV + totalV));

                rowData.hours = newValue;
                rowData.amount = totalV;

                calculateTotal();
              }

              if (columnDef.title === "MONTHS") {
                totalV = newValue * ratePerMonthValue;
                setSubTotalROB(subTotalROB + totalV);
                setTotalROB(discount !== null && discount !== 0 ? (totalROB + totalV) * (discount/100) : (totalROB + totalV));

                rowData.months = newValue;
                rowData.amount = totalV;

                calculateROBTotal();
              }

              if (columnDef.title === "DISTANCE (KM)") {
                totalV =
                  Math.ceil((newValue * ratePerKMValue * frequencyValue) / 10) *
                  10;
                setSubTotalFMK(subTotalFMK + totalV);
                setTotalFMK(discount !== null && discount !== 0 ? ((subTotalFMK + totalV) - ((totalFMK + totalV) * (discount/100))) : (totalFMK + totalV));

                rowData.distance = newValue;
                rowData.amount = totalV;

                calculateFMKTotal();
              }

              if (columnDef.title === "DESCRIPTION") {
                rowData.desc = newValue;
              }

              const dataUpdate = [...data];
              const index = rowData.tableData.id;
              dataUpdate[index] = rowData;
              setData([...dataUpdate]);
              resolve();
            });
          },
        }}
        components={{
          Pagination: PatchedPagination,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ padding: "0px 10px" }}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Client</InputLabel>
                  <Select
                    defaultValue=""
                    value={currClientValue}
                    id="grouped-select"
                    onChange={(e) => {
                        setCurrentClient(e.target.value);
                        setTransportRoute(-1);
                      }
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <ListSubheader>BPDev</ListSubheader>
                    {getDevClients()}
                    <ListSubheader>FetchMyKid</ListSubheader>
                    {getFMKclients()}
                    <ListSubheader>BPDev Robotics</ListSubheader>
                    {getROBclients()}
                  </Select>
                </FormControl>
                {currService === "DEV" ? (
                  <>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">
                        Business Type
                      </InputLabel>
                      <Select
                        defaultValue={1}
                        value={businessType}
                        onChange={(e) => {
                          setRatePerHour(e.target.value);
                          setBusinessType(e.target.value);
                          reCalculateAmounts();
                        }}
                        id="grouped-select"
                      >
                        <MenuItem value={0}>Start-Up</MenuItem>
                        <MenuItem value={1}>Corporate</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">
                        Rate Per Hour (E)
                      </InputLabel>
                      <Select
                        defaultValue={1}
                        value={ratePerHour}
                        onChange={(e) => {
                          setRatePerHour(e.target.value);
                          setBusinessType(e.target.value);
                        }}
                        id="grouped-select"
                      >
                        <MenuItem value={0}>200</MenuItem>
                        <MenuItem value={1}>300</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">
                        Frequency
                      </InputLabel>
                      <Select
                        defaultValue={3}
                        value={frequency}
                        onChange={(e) => {
                          setRate(e.target.value);
                          setFrequency(e.target.value);
                          reCalculateFMKAmounts();
                        }}
                        id="grouped-select"
                      >
                        {tripFrequencies.length !== 0
                          ? tripFrequencies.map((item) => {
                              return (
                                <MenuItem
                                  key={"Freq" + item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">
                        Rate Per KM (E)
                      </InputLabel>
                      <Select
                        disabled={true}
                        defaultValue={5}
                        value={ratePerKM}
                        id="grouped-select"
                      >
                        {ratePerKMArr.length !== 0
                          ? ratePerKMArr.map((item) => {
                              return (
                                <MenuItem
                                  key={"RPKM" + item.id}
                                  value={item.id}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">
                        Transport Route
                      </InputLabel>
                      <Select
                        defaultValue={0}
                        value={transportRoute}
                        id="grouped-select-route"
                        onChange={(e) => {
                            setTransportRouteData(e.target.value);
                            setTransportRoute(e.target.value);
                          }
                        }
                      >
                        <MenuItem value={-1}>--Select--</MenuItem>
                        <MenuItem value={0}>To and From School</MenuItem>
                        <MenuItem value={1}>From School to Home</MenuItem>
                        <MenuItem value={2}>From Home to School</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Terms</InputLabel>
                  <Select
                    defaultValue={0}
                    onChange={(e) => {
                      e.target.value === 0
                        ? setTerms("Due upon receipt")
                        : setTerms("Due end of the month");
                    }}
                    id="grouped-select"
                  >
                    <MenuItem value={0}>Due upon receipt</MenuItem>
                    <MenuItem value={1}>Due end of the month</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <TextField
                    label="Discount"
                    id="standard-start-adornment"
                    variant="standard"
                    sx={{ m: 1, width: '10ch' }}
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </FormControl>
              </div>
              <div>
                <PDFDownloadLink
                  document={
                    <InvoiceGenerator 
                      currentService={currService} 
                      invoiceNo={
                        currService === "FMK" ?
                          (invoices ? "FMK-" + pad(invoices.length + 1, 4) : null)
                          :
                          currService === "DEV" ?
                            (invoices ? "DEV-" + pad(invoices.length + 1, 4) : null)
                            :
                            (invoices ? "ROB-" + pad(invoices.length + 1, 4) : null)
                      }
                      clientNo={
                        currService === "FMK" ?
                          (currClient ? "FMK-" + currClient.id : null)
                          :
                          currService === "DEV" ?
                            (currClient ? "DEV-" + currClient.id : null)
                            :
                            (currClient ? "ROB-" + currClient.id : null)
                      }
                      date={new Date()}
                      subTotalAmount={
                        currService === "FMK" ?
                          ccyFormat(subTotalFMK)
                          :
                          currService === "DEV" ?
                            ccyFormat(subTotalDEV)
                            :
                            ccyFormat(subTotalROB)
                      }
                      totalAmount={
                        currService === "FMK" ?
                          ccyFormat(totalFMK)
                          :
                          currService === "DEV" ?
                            ccyFormat(totalDEV)
                            :
                            ccyFormat(totalROB)
                      }
                      businessTypeId={
                        currService === "DEV" ?
                          ratePerHour === 0 ? "Start-Up" : "Corporate"
                          :
                          null
                      }
                      discount={discount}
                      data={JSON.stringify(data)} 
                      client={currClient}/>
                  }
                  fileName={
                    invoices
                      ? currService === "DEV" ? 
                          "BPDev-Invoice-" + pad(invoices.length + 1, 4)
                          : 
                          currService === "FMK" ? 
                            "FMK-Invoice-" + pad(invoices.length + 1, 4)
                            :
                            "Robotics-Invoice-" + pad(invoices.length + 1, 4)
                      : 
                      "Invoice"
                  }
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <IconButton
                        variant="contained"
                        color="default"
                        className={classes.button}
                      >
                        <GetAppIcon />
                      </IconButton>
                    )
                  }
                </PDFDownloadLink>
                <IconButton
                  color="default"
                  className={classes.button}
                  onClick={() => handleSaveInvoice()}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  color="default"
                  className={classes.button}
                  onClick={() => window.location.reload()}
                >
                  <RefreshIcon />
                </IconButton>
              </div>
            </div>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <TableMUI className={classes.table} aria-label="spanning table">
          <TableBodyMUI>
            <TableRowMUI>
              <TableCellMUI rowSpan={3} />
              <TableCellMUI colSpan={2} style={{ textAlign: 'right', fontWeight: 900 }}>Sub-Total</TableCellMUI>
              <TableCellMUI align="right" style={{ width: '100px', fontWeight: 900 }}>
                {ccyFormat(currService === "DEV" ? subTotalDEV : currService === "FMK" ? subTotalFMK : subTotalROB)}
              </TableCellMUI>
            </TableRowMUI>
            <TableRowMUI>
              <TableCellMUI colSpan={2} style={{ textAlign: 'right' }}>Discount ({discount+'%'})</TableCellMUI>
              <TableCellMUI align="right" style={{ width: '100px' }}>
                {ccyFormat(currService === "DEV" ? subTotalDEV * (discount/100) : currService === "FMK" ? subTotalFMK * (discount/100) : subTotalROB * (discount/100))}
              </TableCellMUI>
            </TableRowMUI>
            <TableRowMUI>
              <TableCellMUI colSpan={2} style={{ textAlign: 'right', fontWeight: 900 }}>Total</TableCellMUI>
              <TableCellMUI align="right" style={{ width: '100px', fontWeight: 900 }}>
                {ccyFormat(currService === "DEV" ? totalDEV : currService === "FMK" ? totalFMK : totalROB)}
              </TableCellMUI>
            </TableRowMUI>
          </TableBodyMUI>
        </TableMUI>
      </TableContainer>
    </>
  );
}
