import React, { createRef, forwardRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


import Table from '../../elements/Table';

import Logo10 from '../../assets/logos/10.png';
import apiClient from '../../apiClient';
import ClientRoboticsDialog from '../../elements/Dialog/clientROB';
import DeleteDialog from '../../elements/Dialog/delete';

import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function ClientsRobotics() {
    const classes = useStyles();
    const clientTableRef = createRef();
    const [clientRoboticsOpen, setClientRoboticsOpen] = useState(false);
    const [clientRoboticsDeleteOpen, setClientRoboticsDeleteOpen] = useState(false);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [kid, setKid] = useState('');
    const [payment_amount, setPaymentAmount] = useState('0.0');
    const [credit, setCredit] = useState('0.0');
    const [outstanding, setOutstanding] = useState('0.0');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [school, setSchool] = useState('');
    const [clientRoboticsOperation, setClientRoboticsOperation] = useState('Add');
    const [clientRoboticsUuid, setClientRoboticsUuid] = useState('');
    const [clientRoboticsLabel, setClientRoboticsLabel] = useState('');

    const handleClickSaveClient = () => {
      setClientRoboticsOperation('Add');
      setClientRoboticsOpen(true);
    };

    const handleClickEditClient = (rowData) => {
      setClientRoboticsOperation('Edit');

      setClientRoboticsUuid(rowData.uuid);
      setName(rowData.name);
      setSurname(rowData.surname);
      setKid(rowData.kid);
      setPaymentAmount(rowData.payment_amount);
      setCredit(rowData.credit);
      setOutstanding(rowData.outstanding);
      setPhone(rowData.phone);
      setEmail(rowData.email);
      setSchool(rowData.school);

      setClientRoboticsOpen(true);
    };

    const handleCloseDeleteClient = () => {
      setClientRoboticsDeleteOpen(false);
    };

    const handleClickDeleteClient = (rowData) => {
      setClientRoboticsDeleteOpen(true);

        setClientRoboticsOperation('Delete');
        setClientRoboticsUuid(rowData.uuid);
        setClientRoboticsLabel(rowData.name + ' ' + rowData.surname + ' ' + `(${rowData.surname})`);
    };

    const handleCloseClientDialog = () => {
      setClientRoboticsOpen(false);
      setClientRoboticsUuid('');
      setName('');
      setSurname('');
      setKid('');
      setPaymentAmount('0.0');
      setCredit('0.0');
      setOutstanding('0.0');
      setPhone('');
      setEmail('');
      setSchool('');
    };

    const handleSaveClient = () => {
        let newData = {
          name: name,
          surname: surname,
          kid: kid,
          payment_amount: payment_amount,
          credit: credit,
          outstanding: outstanding,
          phone: phone,
          email: email,
          school: school
        };
        if (clientRoboticsOperation === 'Add') {
            apiClient
                .post('/api/clients-rob', newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseClientDialog();
                    clientTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (clientRoboticsOperation === 'Edit') {
            apiClient
                .put('/api/clients-rob/' + clientRoboticsUuid, newData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseClientDialog();
                    clientTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        } else if (clientRoboticsOperation === 'Delete') {
            apiClient
                .delete('/api/clients-rob/' + clientRoboticsUuid, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('Token')}`
                    },
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                })
                .then((data) => {
                    handleCloseDeleteClient();
                    clientTableRef.current.onQueryChange();
                })
                .catch((e) => {});
        }
    };

    useEffect(() => {
        // console.log
      }, [])
  
    const columns = [
        { 
          title: 'Name', 
          field: 'name',
        },
        { 
          title: 'Surname', 
          field: 'surname',
        },
        { title: 'Kid', field: 'kid' },
        { title: 'Payment Amount', field: 'payment_amount' },
        { title: 'Credit', field: 'credit' },  
        { title: 'Outstanding', field: 'outstanding' },
        { title: 'Phone', field: 'phone' },
        { title: 'Email', field: 'email' },
        { title: 'School', field: 'school' }
    ];

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Table 
              title={<>
                <img src={Logo10} style={{width: 40, marginTop: "20px"}} alt=""/>
                <p style={{fontSize: "20px"}}>BPD Robotics Clients</p>
              </>}
              columns={columns}
              api="/api/clients-rob"
              tableRef={clientTableRef}
              actionsColumnIndex={0} 
              customActions={true}
              actions={[
                  {
                      icon: AddBox,
                      tooltip: 'Add Bond',
                      isFreeAction: true,
                      onClick: (event) => handleClickSaveClient()
                  },
                  {
                      icon: Edit,
                      tooltip: 'Edit Bond',
                      onClick: (event, rowData) => {
                        handleClickEditClient(rowData);
                      }
                  },
                  {
                      icon: DeleteOutline,
                      tooltip: 'Delete Bond',
                      onClick: (event, rowData) => {
                        handleClickDeleteClient(rowData);
                      }
                  }
              ]}
            />
            <ClientRoboticsDialog 
              open={clientRoboticsOpen}
              handleClose={handleCloseClientDialog}
              handleSave={handleSaveClient}
              name={name}
              setName={setName}
              surname={surname}
              setSurname={setSurname}
              kid={kid}
              setKid={setKid}
              payment_amount={payment_amount}
              setPaymentAmount={setPaymentAmount}
              credit={credit}
              setCredit={setCredit}
              outstanding={outstanding}
              setOutstanding={setOutstanding}
              phone={phone}
              setPhone={setPhone}
              email={email}
              setEmail={setEmail}
              school={school}
              setSchool={setSchool}
              clientFMKOperation={clientRoboticsOperation}
              setClientRoboticsOperation={setClientRoboticsOperation}
              clientRoboticsUuid={clientRoboticsUuid}
            />
            <DeleteDialog open={clientRoboticsDeleteOpen} handleClose={handleCloseDeleteClient} handleSave={handleSaveClient} label={clientRoboticsLabel} />
        </Container>
    )
}
  