import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@mui/material/Button";
import Badge from "@material-ui/core/Badge";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MediaQuery, { useMediaQuery } from "react-responsive";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  AccountsMainListItems,
  AdminMainListItems,
  MainListItems,
  SecondaryListItems,
  UserListItems,
} from "../../elements/ListItem";
import apiClient from "../../apiClient";

import logo from "../../assets/logos/4.png";
import { Navigate, Redirect, useNavigate } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#fff",
    color: "#000",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const menuId = "primary-search-account-menu";

export default function Navbar() {
  const classes = useStyles();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(isDesktopOrLaptop ? true : false);
  const [loggedIn, setLoggedIn] = useState(true);
  const [user, setUser] = useState({});

  const isMenuOpen = Boolean(anchorEl);

  let history = useNavigate();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("User")));
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    apiClient
      .post(
        "http://admintestapi.bpdteam.com/api/logout",
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      )
      .then((res) => {
        localStorage.clear();
        setLoggedIn(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={() => handleMenuClose()}
    >
      <MenuItem
        onClick={() => {
          history.push("/profile");
        }}
      >
        Profile
      </MenuItem>
      <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      {!loggedIn ? <Navigate to="/signin" /> : null}
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => handleDrawerOpen()}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            alt="BPD"
            width={40}
            style={{ marginRight: "10px" }}
          />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            BPD Team
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary" overlap="rectangular">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="medium"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={(e) => handleProfileMenuOpen(e)}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => handleDrawerClose()}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {user.role_id === 1 ? (
          <>
            <List>
              <AdminMainListItems />
            </List>
            <Divider />
          </>
        ) : user.role_id === 2 ? (
          <>
            <List>
              <MainListItems />
            </List>
            <Divider />
          </>
        ) : user.role_id === 3 ? (
          <>
            <List>
              <AccountsMainListItems />
            </List>
            <Divider />
          </>
        ) : user.role_id === 4 ? (
          <>
            <List>
              <MainListItems />
            </List>
            <Divider />
          </>
        ) : user.role_id === 5 ? (
          <>
            <List>
              <MainListItems />
            </List>
            <Divider />
          </>
        ) : user.role_id === 6 ? (
          <>
            <List>
              <MainListItems />
            </List>
            <Divider />
          </>
        ) : null}

        <List>
          <SecondaryListItems />
        </List>

        {/* <Divider />
        <List>
          <UserListItems/>
        </List> */}
      </Drawer>
    </div>
  );
}
