import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Table from "../../elements/Table";

import Logo11 from "../../assets/logos/4.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Assets() {
  const classes = useStyles();

  useEffect(() => {
    // console.log
  }, []);

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Table
        title={
          <>
            <img src={Logo11} style={{ width: 40, marginTop: "20px" }} alt="" />
            <p style={{ fontSize: "20px" }}>Assets</p>
          </>
        }
        columns={columns}
        //api="/api/clients-dev"
        actionsColumnIndex={0}
      />
    </Container>
  );
}
