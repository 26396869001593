import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import apiClient from "../../apiClient";

import Table from '../../elements/Table';
import moment from 'moment';
import { LinearProgress } from '@mui/material';



const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function Expenses() {
    const classes = useStyles();
    // const [paymentMethods, setPaymentMethods] = useState({});
    var paymentMethods = [];
    var charges = [];
    var subsidiary=['FMK','BPDEV','BPDRobotics'];
    const [columns, setColumns] = useState([]);
    const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true);
    const [loadingCategories, setLoadingCategories] = useState(true);


    useEffect(() => {
        apiClient.get(
          `/api/payment-methods/`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          })
          .then((res) => {
            res.data.map((item) => {
              return paymentMethods[item.id] = item.name;
            });
            setLoadingPaymentMethods(false);
          })
          .catch((e) => {
            console.log(e);
          });

        apiClient.get(
          `/api/charges/`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          })
          .then((res) => {
            res.data.map((item) => {
              return charges[item.id] = item.name;
            });
            setLoadingCategories(false);
          })
          .catch((e) => {
            console.log(e);
          });

        setColumns([
          { 
            title: 'Category', 
            field: 'charge_id',
            lookup: charges,
          },
          { 
            title: 'Subsidiary', 
            field: 'subsidiary',
            lookup: subsidiary,
          },
          { title: 'Description', field: 'description' },
          { title: 'Date', field: 'date', type: 'date', render: rowData => <p>{moment(rowData.date).format("DD MMM YYYY")}</p>,
            customFilterAndSearch: (term, rowData) => term.getMonth() === (new Date(rowData.date)).getMonth()
          },
          { title: 'Amount (E)', field: 'amount', type: 'numeric', align: "left" },
          { 
            title: 'Method', 
            field: 'method_id', 
            lookup: paymentMethods,
            type: 'numeric'
          },
          // { title: 'Deleted At', field: 'deleted_at' },  
        ]);
      }, []);

  
  
    return (
        <Container maxWidth="lg" className={classes.container} id="expenses">
          {
            !loadingPaymentMethods && !loadingCategories ?
              <Table 
                title="Expenses"
                columns={columns}
                filtering={true}
                api="/api/expenses"
                actionsColumnIndex={0}
              />
              :
              <LinearProgress />
          }
        </Container>
    )
}
  