import React, { createRef, forwardRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Table from "../../elements/Table";

import Logo11 from "../../assets/logos/4.png";
import apiClient from "../../apiClient";
import DeleteDialog from "../../elements/Dialog/delete";

import AddBox from "@material-ui/icons/AddBox";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import BillDialog from "../../elements/Dialog/bills";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Bills() {
  const classes = useStyles();
  const clientTableRef = createRef();
  const [billOpen, setBillOpen] = useState(false);
  const [billDeleteOpen, setBillDeleteOpen] = useState(false);
  const [supplier, setSupplier] = useState("");
  const [billDate, setBillDate] = useState("");
  const [billDueDate, setBillDueDate] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [subsidiary, setSubsidiary] = useState("0.0");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("0.0");
  const [billOperation, setBillOperation] = useState("Add");
  const [billUuid, setBillUuid] = useState("");
  const [billLabel, setBillLabel] = useState("");

  const handleClickSaveClient = () => {
    setBillOperation("Add");
    setBillOpen(true);
  };

  const handleClickEditClient = (rowData) => {
    setBillOperation("Edit");

    setBillUuid(rowData.uuid);
    setSupplier(rowData.supplier);
    setBillDate(rowData.billDate);
    setBillDueDate(rowData.billDueDate);
    setBillNumber(rowData.billNumber);
    setSubsidiary(rowData.subsidiary);
    setDescription(rowData.description);
    setAmount(rowData.amount);
    setBillOpen(true);
  };

  const handleCloseDeleteClient = () => {
    setBillDeleteOpen(false);
  };

  const handleClickDeleteClient = (rowData) => {
    setBillDeleteOpen(true);

    setBillOperation("Delete");
    setBillUuid(rowData.uuid);
    setBillLabel(
      rowData.name + " " + rowData.surname + " " + `(${rowData.surname})`
    );
  };

  const handleCloseClientDialog = () => {
    setBillOpen(false);
    setBillUuid("");
    setSupplier("");
    setBillDate("");
    setBillDate("");
    setBillNumber("0.0");
    setSubsidiary("");
    setDescription("1");
    setAmount("0.0");
  };

  const handleSaveClient = () => {
    let newData = {
      supplier: supplier,
      billDate: billDate,
      billDueDate: billDueDate,
      billNumber: billNumber,
      subsidiary: subsidiary,
      description: description,
      amount: amount,
    };
    if (billOperation === "Add") {
      apiClient
        .post("/api/clients-fmk", newData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseClientDialog();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    } else if (billOperation === "Edit") {
      apiClient
        .put("/api/clients-fmk/" + billUuid, newData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseClientDialog();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    } else if (billOperation === "Delete") {
      apiClient
        .delete("/api/clients-fmk/" + billUuid, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseDeleteClient();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    }
  };

  useEffect(() => {
    // console.log
  }, []);

  const columns = [
    { title: "Supplier", field: "supplier" },
    { title: "Bill Date", field: "bill_date" },
    { title: "Due Date", field: "due_date" },
    { title: "Bill No.", field: "bill_no" },
    { title: "Subsidiary", field: "subsidiary" },
    { title: "Description", field: "Description" },
    { title: "Amount", field: "amount" },
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Table
        title={
          <>
            <img src={Logo11} style={{ width: 40, marginTop: "20px" }} alt="" />
            <p style={{ fontSize: "20px" }}>Bills</p>
          </>
        }
        columns={columns}
        api="/api/clients-fmk"
        tableRef={clientTableRef}
        actionsColumnIndex={0}
        customActions={true}
        actions={[
          {
            icon: AddBox,
            tooltip: "Add Bond",
            isFreeAction: true,
            onClick: (event) => handleClickSaveClient(),
          },
          {
            icon: Edit,
            tooltip: "Edit Bond",
            onClick: (event, rowData) => {
              handleClickEditClient(rowData);
            },
          },
          {
            icon: DeleteOutline,
            tooltip: "Delete Bond",
            onClick: (event, rowData) => {
              handleClickDeleteClient(rowData);
            },
          },
        ]}
      />
      <BillDialog
        open={billOpen}
        handleClose={handleCloseClientDialog}
        handleSave={handleSaveClient}
        supplier={supplier}
        setSupplier={setSupplier}
        billDate={billDate}
        setBillDate={setBillDate}
        billDueDate={billDueDate}
        setBillDueDate={setBillDueDate}
        billNumber={billNumber}
        setBillNumber={setBillNumber}
        subsidiary={subsidiary}
        setSubsidiary={setSubsidiary}
        description={description}
        setDescription={setDescription}
        billOperation={billOperation}
        setBillOperation={setBillOperation}
        billUuid={billUuid}
      />
    </Container>
  );
}
