import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import apiClient from "../../apiClient";
// import FNB from 'fnb';

import Table from '../../elements/Table';

import CashBalance from '../DashboardBalances/cash';
import BankBalance from '../DashboardBalances/bank';

import CircleProgress from '../../elements/CircularProgress';
import { Grid, Paper } from '@material-ui/core';
import { LinearProgress } from '@mui/material';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function Balance() {
    const classes = useStyles();

    const [loadingCategories, setLoadingCategories] = useState(true);

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    // const chartHeightPaper = clsx(classes.paper, classes.chartHeight);

    var charges;

    // const username = 'BandilePDlamini';
    // const password = 'Bundidlamz7!';
    
    // (async () => {
    //   const fnb = new FNB({ username, password });
      
    //   const accounts = await fnb.accounts();
    //   console.log(accounts);
    //   const cards = await fnb.cards();
    //   const transactions = await fnb.transactions('account-number');
    //   const balance = await fnb.balance('account-number');
    // })();

    useEffect(() => {
      apiClient.get(
        `/api/balances/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          res.data.map((item) => {
            return charges[item.id] = item.name;
          });
          setLoadingCategories(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }, [charges]);
  
    const columns = [
        { 
            title: 'Used', 
            field: 'usage', 
            initialEditValue: 0,
            align: "center",
            render: rowData => {
                return <CircleProgress value={rowData.usage}/>
            }
        },
        { title: 'Name', field: 'charge_id', lookup: charges },
        { title: 'Amount (E)', field: 'amount', type: 'numeric', align: "left" },
        
    ];
  
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper className={fixedHeightPaper}>
                  <BankBalance balance={10230} lastUpdated={"30 June, 2021"}/>
                </Paper>
              </Grid>
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper className={fixedHeightPaper}>
                  <CashBalance balance={5115} lastUpdated={"30 June, 2021"}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                {
                  !loadingCategories ?
                    <Table
                      title="Balances"
                      columns={columns}
                      api="/api/balances"
                      actionsColumnIndex={0}
                    />
                    :
                    <LinearProgress />
                }
              </Grid>
            </Grid>
        </Container>
    )
}
  