import React, { createRef, forwardRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Table from "../../elements/Table";

import Logo11 from "../../assets/logos/4.png";
import apiClient from "../../apiClient";

import AddBox from "@material-ui/icons/AddBox";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CreditCardCreditDialog from "../../elements/Dialog/creditcardcredit";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function CreditCardCredit() {
  const classes = useStyles();
  const clientTableRef = createRef();
  const [payee, setPayee] = useState("");
  const [payment_date, setPaymentDate] = useState("");
  const [creditAccount, setCreditAccount] = useState("");
  const [refNo, setRefNo] = useState("");
  const [subsidiary, setSubdiary] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("0.0");
  const [creditCardCreditOperation, setCreditCardCreditOperation] = useState("Add");
  const [creditCardCreditUuid, setCreditCardCreditUuid] = useState("");
  const [creditCardCreditLabel, setCreditCardCreditLabel] = useState("");
  const [creditCardCreditOpen, setCreditCardCreditOpen] = useState(false);
  const [creditCardCreditDeleteOpen, setCreditCardCreditDeleteOpen] = useState(false);

  const handleClickSaveClient = () => {
    setCreditCardCreditOperation("Add");
    setCreditCardCreditOpen(true);
  };

  const handleClickEditClient = (rowData) => {
    setCreditCardCreditOperation("Edit");

    setCreditCardCreditUuid(rowData.uuid);
    setPayee(rowData.payee);
    setCreditAccount(rowData.creditAccount)
    setPaymentDate(rowData.payment_date);
    setRefNo(rowData.refNo);
    setSubdiary(rowData.subsidiary);
    setDescription(rowData.description);
    setAmount(rowData.amount);
   
  };

  const handleCloseDeleteClient = () => {
    setCreditCardCreditDeleteOpen(false);
  };

  const handleClickDeleteClient = (rowData) => {
    setCreditCardCreditDeleteOpen(true);

    setCreditCardCreditOperation("Delete");
    setCreditCardCreditUuid(rowData.uuid);
    setCreditCardCreditLabel(
      rowData.payee + " " + rowData.refNo + " " + `(${rowData.refNo})`
    );
  };

  const handleCloseClientDialog = () => {
    setCreditCardCreditOpen(false);
    setCreditCardCreditUuid("");
    setPayee("");
    setPaymentDate("");
    setCreditAccount("");
    setRefNo("");
    setSubdiary("0.0");
    setDescription("");
    setAmount("1");
  };

  const handleSaveClient = () => {
    let newData = {
      payee: payee,
      payment_date: payment_date,
      creditAccount: creditAccount,
      refNo: refNo,
      subsidiary: subsidiary,
      description: description,
      amount: amount,
    };
    if (creditCardCreditOperation === "Add") {
      apiClient
        .post("/api/creditCardCredits", newData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseClientDialog();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    } else if (creditCardCreditOperation === "Edit") {
      apiClient
        .put("/api/creditCardCredits/" + creditCardCreditUuid, newData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseClientDialog();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    } else if (creditCardCreditOperation === "Delete") {
      apiClient
        .delete("/api/creditCardCredits/" + creditCardCreditUuid, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseDeleteClient();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    }
  };

  useEffect(() => {
    // console.log
  }, []);

  const columns = [
    {
      title: "Payee",
      field: "payee",
    },
    {title: "Credit Account",field: "credit_account"},
    { title: "Payment Date", field: "payment_date" },
    { title: "Ref No.", field: "ref_no" },
    { title: "Subsidiary", field: "subsidiary" },
    { title: "Description", field: "description" },
    { title: "Amount", field: "amount" },
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Table
        title={
          <>
            <img src={Logo11} style={{ width: 40, marginTop: "20px" }} alt="" />
            <p style={{ fontSize: "20px" }}>Credit Card Credit</p>
          </>
        }
        columns={columns}
        api="/api/creditCardCredits"
        tableRef={clientTableRef}
        actionsColumnIndex={0}
        customActions={true}
        actions={[
          {
            icon: AddBox,
            tooltip: "Add Bond",
            isFreeAction: true,
            onClick: (event) => handleClickSaveClient(),
          },
          {
            icon: Edit,
            tooltip: "Edit Bond",
            onClick: (event, rowData) => {
              handleClickEditClient(rowData);
            },
          },
          {
            icon: DeleteOutline,
            tooltip: "Delete Bond",
            onClick: (event, rowData) => {
              handleClickDeleteClient(rowData);
            },
          },
        ]}
      />
      <CreditCardCreditDialog
        open={creditCardCreditOpen}
        handleClose={handleCloseClientDialog}
        handleSave={handleSaveClient}
        payee={payee}
        setPayee={setPayee}
        creditAccount={creditAccount}
        setCreditAccount={setCreditAccount}
        payment_date={payment_date}
        setPaymentDate={setPaymentDate}
        refNo={refNo}
        setRefNo={setRefNo}
        subsidiary={subsidiary}
        setSubdiary={setSubdiary}
        description={description}
        setDescription={setDescription}
        amount={amount}
        setAmount={setAmount}
        creditCardCreditOperation={creditCardCreditOperation}
        setCreditCardCreditOperation={setCreditCardCreditOperation}
        creditCardCreditUuid={creditCardCreditUuid}
      />
      {/* <DeleteDialog open={clientFMKDeleteOpen} handleClose={handleCloseDeleteClient} handleSave={handleSaveClient} label={clientFMKLabel} /> */}
    </Container>
  );
}
