import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import apiClient from "../../apiClient";
import moment from 'moment';

import Table from '../../elements/Table';

import CircleProgress from '../../elements/CircularProgress';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function Budget() {
    const classes = useStyles();

    var charges = [];

    useEffect(() => {
        apiClient.get(
          `/api/charges/`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          })
          .then((res) => {
            res.data.map((item) => {
              charges[item.id] = item.name;
              return null;
            });
          })
          .catch((e) => {
            console.log(e);
          });
    }, [charges]);
  
    const columns = [
        { title: 'Category', field: 'charge_id', lookup: charges },
        { 
          title: 'Used', 
          field: 'usage', 
          initialEditValue: 0,
          align: "center", 
          editable: 'never',
          render: rowData => {
              return <><p>E{rowData.usage}</p><CircleProgress value={(rowData.usage/rowData.amount)*100}/></>
          }
        },
        { title: 'Budget (E)', field: 'amount', type: 'numeric', align: "left" },
        
    ];

    const columnsAll = [
      { title: 'Category', field: 'charge_id', lookup: charges },
      { 
        title: 'Used', 
        field: 'usage', 
        initialEditValue: 0,
        align: "center", 
      },
      { title: 'Budget (E)', field: 'amount', type: 'numeric', align: "left" },
      { title: 'Date', field: 'date', type: 'date', render: rowData => <p>{rowData.date? moment(rowData.date).format("DD MMM YYYY") : null}</p>},
      
  ];
  
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Table
                title="Budget"
                columns={columns}
                api="/api/budget-by-month"
                actionsColumnIndex={0}
            />
            <Table
                title="Budget All"
                columns={columnsAll}
                api="/api/budgets"
                actionsColumnIndex={0}
            />
        </Container>
    )
}
  