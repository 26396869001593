import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      valueIsNumericString
      prefix="E"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function ClientFMKDialog(props) {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>FMK Client</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fill in the form to add/edit the FMK Client
        </DialogContentText>
        <Grid style={{ marginTop: "10px" }} container spacing={2}>
          <Grid item xs={6}>
            <TextField
              value={props.name}
              onChange={(e) => props.setName(e.target.value)}
              margin="dense"
              id="name"
              label="First Name"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.surname}
              onChange={(e) => props.setSurname(e.target.value)}
              margin="dense"
              id="surname"
              label="Last Name"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.kid}
              onChange={(e) => props.setKid(e.target.value)}
              margin="dense"
              id="kid"
              label="Kid"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              value={props.payment_amount}
              onChange={(e) => props.setPaymentAmount(e.target.value)}
              name="paymentAmount"
              margin="dense"
              id="paymentAmount"
              label="Payment Amount"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Payment Frequency
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.payment_frequency}
                label="Payment Frequency"
                onChange={(e) => props.setPaymentFrequency(e.target.value)}
              >
                <MenuItem value={"Monthly"}>Monthly</MenuItem>
                <MenuItem value={"Quarterly"}>Quarterly</MenuItem>
                <MenuItem value={"Annually"}>Annually</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.next_payment_due}
              onChange={(e) => props.setNextPaymentDue(e.target.value)}
              margin="dense"
              id="paymentDate"
              label="Payment Date"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              value={props.credit}
              onChange={(e) => props.setCredit(e.target.value)}
              name="credit"
              margin="dense"
              id="credit"
              label="Credit"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              value={props.outstanding}
              onChange={(e) => props.setOutstanding(e.target.value)}
              name="outstanding"
              margin="dense"
              id="outstanding"
              label="Outstanding"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            {/* <TextField margin="dense" id="client" label="Client" type="text" fullWidth variant="outlined" /> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Route Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.route_type}
                label="Route Type"
                onChange={(e) => props.setRouteType(e.target.value)}
              >
                <MenuItem value={"To And From School"}>
                  To And From School
                </MenuItem>
                <MenuItem value={"From School To Home"}>
                  From School To Home
                </MenuItem>
                <MenuItem value={"From Home To School"}>
                  From Home To School
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.phone}
              onChange={(e) => props.setPhone(e.target.value)}
              margin="dense"
              id="phone"
              label="Phone"
              type="tel"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.email}
              onChange={(e) => props.setEmail(e.target.value)}
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.home}
              onChange={(e) => props.setHome(e.target.value)}
              margin="dense"
              id="home"
              label="Home"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.school}
              onChange={(e) => props.setSchool(e.target.value)}
              margin="dense"
              id="school"
              label="School"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">km</InputAdornment>
                ),
              }}
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              value={props.distance}
              onChange={(e) => props.setDistance(e.target.value)}
              margin="dense"
              id="distance"
              label="Distance"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.emergency_person}
              onChange={(e) => props.setEmergencyPerson(e.target.value)}
              margin="dense"
              id="emergencyPerson"
              label="Emergency Person"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.emergency_phone}
              onChange={(e) => props.setemergencyPhone(e.target.value)}
              margin="dense"
              id="emergencyPhone"
              label="Emergency Phone"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.gardian_name}
              onChange={(e) => props.setGardianName(e.target.value)}
              margin="dense"
              id="gardianName"
              label="Gardian Name"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={props.gardian_phone}
              onChange={(e) => props.setGardianPhone(e.target.value)}
              margin="dense"
              id="gardianPhone"
              label="Gardian Phone"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={props.handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
