import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import apiClient from "../../apiClient";
import { PDFViewer } from '@react-pdf/renderer';
import ReceiptGenerator from '../ReceiptGenerator';

import Table from '../../elements/Table';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function ReceiptComponent(props) {
    const classes = useStyles();
    var clientsFMK = {};
    var clientsDev = {};
    var clientsROB = {};
    var businessTypes = {};
    var tripFrequencies = {};
    const [FMKclients, setFMKclients] = useState([]);
    const [DEVclients, setDEVclients] = useState([]);
    const [ROBclients, setROBclients] = useState([]);
    const [clientsFMKs, setClientsFMKs] = useState([]);
    const [clientsDEVs, setClientsDEVs] = useState([]);
    const [clientsROBs, setClientsROBs] = useState([]);
    const [columns, setColumns] = useState([]);
    const [BusinessTypes, setBusinessTypes] = useState([]);
    const [TripFrequencies, setTripFrequencies] = useState([]);

    function getCurrentClient(client){
      var result = client.substring(4, client.length);

      if(client.substring(0, 4) === 'DEV-'){
        return (DEVclients[DEVclients.findIndex(x => x.id === parseInt(result, 10))]);
      }
      else if(client.substring(0, 4) === 'FMK-'){
        return (FMKclients[FMKclients.findIndex(x => x.id === parseInt(result, 10))]);
      }
      else{
        return (ROBclients[ROBclients.findIndex(x => x.id === parseInt(result, 10))]);
      }
    }

    useEffect(() => {
      apiClient.get(
        `/api/clients-fmk/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          setFMKclients(res.data);
          res.data.map((item) => {
            clientsFMK[item.id] = item.name + ' ' + item.surname + ' (' + item.kid + ')';
            return null;
          });
          setClientsFMKs(clientsFMK);
        })
        .catch((e) => {
          console.log(e);
        });

      apiClient.get(
        `/api/clients-dev/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          setDEVclients(res.data);
          res.data.map((item) => {
            clientsDev[item.id] = item.name;
            return null;
          });
          setClientsDEVs(clientsDev);
        })
        .catch((e) => {
          console.log(e);
        });

      apiClient.get(
        `/api/clients-rob/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          setROBclients(res.data);
          res.data.map((item) => {
            clientsROB[item.id] = item.name + ' ' + item.surname + ' (' + item.kid + ')';
            return null;
          });
          setClientsROBs(clientsROB);
        })
        .catch((e) => {
          console.log(e);
        });

      apiClient.get(
        `/api/businessTypes/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          // setBusinessTypes(res.data);
          res.data.map((item) => {
            businessTypes[item.id] = item.name;
            return null;
          });
          setBusinessTypes(businessTypes);
        })
        .catch((e) => {
          console.log(e);
        });

      apiClient.get(
        `/api/tripFrequencies/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          // setTripFrequencies(res.data);
          res.data.map((item) => {
            tripFrequencies[item.id] = item.name;
            return null;
          });
          setTripFrequencies(tripFrequencies);
        })
        .catch((e) => {
          console.log(e);
        });
    }, []);

    useEffect(() => {
      setColumns([
        { title: 'BPDev Payment', field: 'client_dev_id', lookup: clientsDEVs, initialEditValue: null},
        { title: 'FetchMyKid Payment', field: 'client_fmk_id', lookup: clientsFMKs, initialEditValue: null},
        { title: 'BPDev Robotics Payment', field: 'client_rob_id', lookup: clientsROBs, initialEditValue: null},
        { title: 'Client No', field: 'client_no', type: 'string' },
        { title: 'Receipt No', field: 'receipt_no', type: 'string' },
        { title: 'Business Type', field: 'business_type_id', lookup: BusinessTypes, initialEditValue: null},
        { title: 'Trip Frequency', field: 'trip_frequency_id', lookup: TripFrequencies, initialEditValue: null},
        { title: 'Items', field: 'items', type: 'string' },
        { title: 'Date', field: 'date', type: 'datetime', render: rowData => <p>{moment(rowData.date).format("DD MMM YYYY")}</p>},
        { title: 'Amount (E)', field: 'amount', type: 'numeric', align: "left" },
      ]);
    }, [clientsDEVs, clientsFMKs, clientsROBs]);
  
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Table
                title="Receipts"
                columns={columns}
                api="/api/receipts"
                actionsColumnIndex={0}
                detailPanel={data => {
                  return (
                    <PDFViewer style={{ width: "100%", height: '100vh'}}>
                      <ReceiptGenerator rowData={data.rowData} client={getCurrentClient(data.rowData.client_no)}/>
                    </PDFViewer>
                  )
                }}
            />
        </Container>
    )
}
  