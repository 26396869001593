import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  circleGreen: {
    color: 'green',
  },
  circleYellow: {
    color: 'yellow',
  },
  circleRed: {
    color: 'red',
  },
}));

function CircularProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box position="relative" display="inline-flex">
      {props.value <= 100 ?
        props.value < 75 ?
          <CircularProgress className={classes.circleGreen} variant="determinate" {...props} size={70} thickness={7}/>
          :
          <CircularProgress className={classes.circleYellow} variant="determinate" {...props} size={70} thickness={7}/>
        :
        <CircularProgress className={classes.circleRed} variant="determinate" {...props} value={100} size={70} thickness={7}/>
      }
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
              props.value,
            )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic(props) {
  // const [progress, setProgress] = React.useState(10);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return <CircularProgressWithLabel value={props.value} />;
}
