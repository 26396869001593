import React, { useEffect, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import apiClient from "../../apiClient";

import Table from '../../elements/Table';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function Income() {
    const classes = useStyles();
    const tableRef = createRef();
    var paymentMethods = [];
    var clientsFMK = [];
    var clientsDev = [];
    var clientsRobotics = [];

    useEffect(() => {
      apiClient.get(
        `/api/payment-methods/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          res.data.map((item) => {
            paymentMethods[item.id] = item.name;
            return null;
          });
        })
        .catch((e) => {
          console.log(e);
        });

      apiClient.get(
        `/api/clients-fmk/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          res.data.map((item) => {
            clientsFMK[item.id] = item.name + ' ' + item.surname;
            return null;
          });
        })
        .catch((e) => {
          console.log(e);
        });

      apiClient.get(
        `/api/clients-dev/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          res.data.map((item) => {
            clientsDev[item.id] = item.name;
            return null;
          });
        })
        .catch((e) => {
          console.log(e);
        });

        apiClient.get(
          `/api/clients-robotics/`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          })
          .then((res) => {
            res.data.map((item) => {
              clientsDev[item.id] = item.name;
              return null;
            });
          })
          .catch((e) => {
            console.log(e);
          });
    }, [paymentMethods, clientsFMK, clientsDev, ]);
  
    const columns = [
      { title: 'Receipt #', field: 'receipt_no', initialEditValue: null},
      { title: 'BPDev Payment', field: 'client_dev_id', lookup: clientsDev, initialEditValue: null},
      { title: 'FetchMyKid Payment', field: 'client_fmk_id', lookup: clientsFMK, initialEditValue: null},
      { title: 'BPDRobotics Payment', field: 'client_robotics_id', lookup: clientsRobotics, initialEditValue: null},
      { title: 'Date', field: 'date', type: 'datetime', render: rowData => <p>{moment(rowData.date).format("DD MMM YYYY")}</p>,
        customFilterAndSearch: (term, rowData) => term.getMonth() === (new Date(rowData.date)).getMonth()
      },
      { title: 'Amount (E)', field: 'amount', type: 'numeric', align: "left" },
      { 
        title: 'Method', 
        field: 'method_id', 
        lookup: paymentMethods,
      },
    ];
  
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Table
                title="Income"
                columns={columns}
                api="/api/incomes"
                actionsColumnIndex={0}
                tableRef={tableRef}
                filtering={true}
                // actions={[
                //   {
                //     icon: 'refresh',
                //     tooltip: 'Refresh Data',
                //     isFreeAction: true,
                //     onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                //   }
                // ]}
            />
        </Container>
    )
}
  