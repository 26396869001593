import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import apiClient from "../../apiClient";

import { Document, Page, Text, View, Image, StyleSheet, Link, Font } from '@react-pdf/renderer';
import { TableHeader, DataTableCell, Table, TableBody, TableCell } from '@david.kucsai/react-pdf-table';

import Slide from '@material-ui/core/Slide';

import logo from '../../assets/logos/1.png';
import logo1 from '../../assets/logos/2.png';
import logo10 from '../../assets/logos/10.png';
import moment from 'moment';

import source from '../../assets/fonts/Roboto/Roboto-Bold.ttf';

Font.register({
  family: "Roboto",
  src: source,
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingBottom: 55,
  },
  header: {
    width: "100%",
    backgroundColor: "#BDBDBD",
  },
  titleBox: {
    width: "250px",
    textAlign: "center",
    padding: 10,
    position: "absolute",
    top: 20,
    right: 20,
    backgroundColor: "#fed700",
  },
  titleBoxFMK: {
    width: "250px",
    textAlign: "center",
    padding: 10,
    position: "absolute",
    top: 20,
    right: 20,
    backgroundColor: "#4479d9",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: "60px",
    height: "60px",
  },
  company: {
    fontSize: "8px",
    position: "absolute",
    top: 23,
    left: 80,
  },
  links: {
    fontSize: "8px",
    position: "absolute",
    top: 23,
    left: 210,
  },
  link: {
    color: "#ad9300",
    textDecoration: "underline",
    paddingBottom: 3,
  },
  linkAlt: {
    color: "#ad9300",
    textDecoration: "underline",
    paddingBottom: 3,
    textIndent: "80px",
  },
  linkFMK: {
    color: "#2f5598",
    textDecoration: "underline",
    paddingBottom: 3,
  },
  flex: {
    flexDirection: "row",
    margin: "5px",
  },
  infoBox: {
    width: "50%",
    fontSize: "10px",
    padding: 10,
  },
  smallIndent: {
    textIndent: "60px",
  },
  bold: {},
  tableHeader: {
    fontSize: "8px",
    backgroundColor: "#ffec82",
    padding: 5,
    fontFamily: 'Roboto'
  },
  lightYellowBg: {
    backgroundColor: "#ffec82",
  },
  tableHeaderFMK: {
    fontSize: "8px",
    backgroundColor: "#95b4ea",
    padding: 5,
    fontFamily: 'Roboto'
  },
  lightBlueBg: {
    backgroundColor: "#95b4ea",
  },
  tableBody: {
    fontSize: "8px",
    padding: 5,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  blankCell1: {
    border: "unset",
  },
  blankCell2: {
    borderLeft: "unset",
  },
  singleCellHeaderWide: {
    backgroundColor: "#ffec82",
    border: "1px solid #000",
    pading: "10px",
    width: "200px",
    textAlign: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
    marginLeft: "100px"
  },
  singleCellHeaderWideFMK: {
    backgroundColor: "#95b4ea",
    border: "1px solid #000",
    pading: "10px",
    width: "200px",
    textAlign: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
    marginLeft: "100px"
  },
  singleCellHeader: {
    backgroundColor: "#ffec82",
    border: "1px solid #000",
    pading: "10px",
    width: "80px",
    textAlign: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  singleCellHeaderFMK: {
    backgroundColor: "#95b4ea",
    border: "1px solid #000",
    pading: "10px",
    width: "80px",
    textAlign: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  singleCellBody: {
    border: "1px solid #000",
    pading: "10px",
    width: "80px",
    textAlign: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  topInfoBox: {
    paddingBottom: "10px",
    marginTop: "15px",
  },
  paymentInfoBox: {
    width: "290px",
  },
  smallFont: {
    fontSize: "8px",
  },
  topBorder: {
    borderTop: "2px solid #000",
  },
  margLeft: {
    marginLeft: "10px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  invisibleBorder: {
    borderBottom: "3px solid white",
    borderLeft: "2px solid white",
    margin: "0",
    marginBottom: "-2px",
    marginLeft: "-2px",
    borderCollapse: "collapse!important",
    borderStyle: "hidden!important",
    outline: "none!important",
  },
});

function ccyFormat(num) {
  if(typeof num === 'number')
    return `${num.toFixed(2)}`;
  else
    return num;
}



export default function InvoiceGenerator(props) {

  const [currClient, setCurrClient] = useState();
  const [currService, setCurrService] = useState("");
  const [subTotalFMK, setSubTotalFMK] = useState(0);
  const [subTotalDEV, setSubTotalDEV] = useState(0);
  const [subTotalROB, setSubTotalROB] = useState(0);
  const [totalFMK, setTotalFMK] = useState(0);
  const [totalDEV, setTotalDEV] = useState(0);
  const [totalROB, setTotalROB] = useState(0);
  const [data, setData] = useState([]);
  const [ratePerHour, setRatePerHour] = useState(1);
  const [frequency, setFrequency] = useState(2);
  const [ratePerKM, setRatePerKM] = useState(2);
  const [ratePerHourValue, setRatePerHourValue] = useState(300);
  const [ratePerMonthValue, setRatePerMonthValue] = useState(350);
  const [ratePerKMValue, setRatePerKMValue] = useState(1.8);
  const [frequencyValue, setFrequencyValue] = useState(22);
  const [terms, setTerms] = useState("Due upon receipt");
  const [invoiceNo, setInvoiceNo] = useState();
  const [clientNo, setClientNo] = useState();
  const [date, setDate] = useState();
  const [invoiceTotal, setInvoiceTotal] = useState();
  const [invoiceSubTotal, setInvoiceSubTotal] = useState();


  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = (tempSubTotal + (item.hours * ratePerHourValue));
      tempTotal = (tempTotal + (item.hours * ratePerHourValue));
      setSubTotalDEV(tempSubTotal);
      setTotalDEV(tempTotal);
      return null;
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateFMKTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = (tempSubTotal + (Math.ceil((item.distance * ratePerKMValue * frequencyValue)/10)*10));
      tempTotal = (tempTotal + (Math.ceil((item.distance * ratePerKMValue * frequencyValue)/10)*10));
      setSubTotalFMK(tempSubTotal);
      setTotalFMK(tempTotal);
      return null;
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateROBTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = (tempSubTotal + (Math.ceil((item.months * ratePerMonthValue))));
      tempTotal = (tempTotal + (Math.ceil((item.months * ratePerMonthValue))));
      setSubTotalROB(tempSubTotal);
      setTotalROB(tempTotal);
      return null;
    });
  }



  useEffect(() => {
    setData(JSON.parse(props.data));
    setCurrService(props.currentService);
    setInvoiceNo(props.invoiceNo);
    setClientNo(props.clientNo);
    setDate(props.date);
    setCurrClient(props.client);
    setInvoiceTotal(props.totalAmount);
    setInvoiceSubTotal(props.subTotalAmount);

    apiClient.get(
      `/api/businessTypesId/${props.businessTypeId}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        apiClient.get(
          `/api/ratePerHoursId/${res.data.rate_per_hour_id}`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          })
          .then((res) => {
            setRatePerHourValue(parseFloat(res.data.name));
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  
  useEffect(() => {
    if(ratePerHour === 0){
      setRatePerHourValue(200);
    }
    else{
      setRatePerHourValue(300);
    }

    calculateTotal();
    // 

  }, [ratePerHour, calculateTotal]);

  useEffect(() => {
    if(ratePerKM === 0){
      setRatePerKMValue(3);
    }
    else if(ratePerKM === 1){
      setRatePerKMValue(2);
    }
    else if(ratePerKM === 2){
      setRatePerKMValue(1.8);
    }
    else {
      setRatePerKMValue(1);
    }

    calculateFMKTotal();
    // 

  }, [ratePerKM, calculateFMKTotal]);

  useEffect(() => {
    if(frequency === 0){
      setFrequencyValue(1);
    }
    else if(frequency === 1){
      setFrequencyValue(5);
    }
    else if(frequency === 2){
      setFrequencyValue(22);
    }
    else {
      setFrequencyValue(264);
    }

    calculateFMKTotal();
    // 

  }, [frequency, calculateFMKTotal]);



  const DevDoc = () => (
    <Document>
          <Page size="A4" style={styles.page}>
            <Image src={logo} alt="" style={styles.image}/>
            <View style={styles.titleBox}>
              <Text>INVOICE</Text>
            </View>
            <View style={styles.company}>
              <Text style={{ fontFamily: 'Roboto' }}>BPD INVESTMENTS (PTY) LTD</Text>
              <Text>R7/60438</Text>
              <Text>TIN: 106-533-950</Text>
              <Text>Plot 409 Somhlolo Road</Text>
              <Text>Mbabane</Text>
              <Text>Eswatini</Text>
            </View>
            <View style={styles.links}>
              <Link src="https://dev.bpdteam.com" style={styles.link}>dev.bpdteam.com</Link>
              <Link src="mailto:dev@bpdteam.com" style={styles.link}>dev@bpdteam.com</Link>
              <Link src="tel:79360602" style={styles.link}>79360602</Link>
            </View>
            <View style={[styles.topInfoBox, styles.flex]}>
              <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row', marginTop: '20px'}}>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>DATE</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment(date).format("DD-MMM-YYYY")}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>INVOICE No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{invoiceNo}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>CLIENT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{clientNo}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>TERMS</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{terms}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>BUSINESS TYPE</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{ratePerHour === 0 ? "Start-Up" : "Coporate"}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>RATE PER HOUR</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>E{ratePerHour === 0 ? "200" : "300"}</Text>
                  </View>
                  </View>
              </View>
            </View>
            <View style={styles.flex}>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>INVOICE FOR:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.name : ""}</Text>
                {currClient ?
                  currClient.address.split(',').map((item,index) => {
                    return <Text key={"address-key-"+index} style={[styles.smallFont, styles.smallIndent, styles.bold]}>{item}</Text>
                  })
                  :
                  ""
                }
              </View>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
                <Link style={[styles.smallFont, styles.smallIndent, styles.link]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
              </View>
            </View>
            <Table
                data={data}
            >
                <TableHeader textAlign={"center"}>
                    {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                        TASK #
                    </TableCell> */}
                    <TableCell style={styles.tableHeader} weighting={0.7}>
                        DESCRIPTION
                    </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>
                        HOURS
                    </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.2}>
                        AMOUNT (E)
                    </TableCell>
                </TableHeader>
                <TableBody>
                    {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
                    <DataTableCell style={styles.tableBody} weighting={0.7} getContent={(r) => r.desc}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.hours}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightYellowBg, { fontFamily: 'Roboto' }]} weighting={0.1}>Sub-Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder, { fontFamily: 'Roboto' }]} weighting={0.2}>{invoiceSubTotal? ccyFormat(invoiceSubTotal) : 0.00}</TableCell>
                </TableBody>
            </Table>
            <Table
                data={[{ taskNumber: 1, desc: "Test 1", distance: 3, discount: props.discount, subTotalFMK: subTotalFMK }]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.center, styles.invisibleBorder]} weighting={0.7}>Thank you for your business!</TableCell>
                  {
                    props.discount > 0 ?
                      <DataTableCell
                        style={[styles.tableBody, styles.center, styles.lightYellowBg]}
                        weighting={0.1}
                        getContent={(r) => 'Discount ('+r.discount+'%)'}
                      />
                      :
                      <TableCell
                        style={[styles.tableBody, styles.center, styles.lightYellowBg]}
                        weighting={0.1}
                      >
                        |
                      </TableCell>
                  }
                  {
                    props.discount > 0 ?
                      <DataTableCell
                        style={[styles.tableBody, styles.center]}
                        weighting={0.2}
                        getContent={(r) => ccyFormat(invoiceSubTotal * (r.discount/100))}
                      />
                      :
                      <TableCell
                        style={[styles.tableBody, styles.center]}
                        weighting={0.2}
                      ></TableCell>
                  }
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
                style={[styles.blankCell1]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg, { fontFamily: 'Roboto' }]} weighting={0.1}>Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, { fontFamily: 'Roboto' }]} weighting={0.2}>{invoiceTotal? ccyFormat(invoiceTotal) : 0.00}</TableCell>
                </TableBody>
            </Table>
            <Text style={{ fontSize: "8px", marginTop: "40px" }}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
            <Text style={{ fontFamily: 'Roboto', fontSize: '8px', textAlign: 'center' }}>PAYMENT METHODS</Text>
            <View style={[styles.flex]}>
              <View style={[styles.paymentInfoBox]}>
                <Table
                    data={[
                        {lable: "Account Name:", value: "BPD Investments (PTY) LTD"},
                        {lable: "Account Number:", value: "62903359554"},
                        {lable: "Account Type:", value: "Business Cheque Account"},
                        {lable: "Branch Code:", value: "281264"},
                        {lable: "Branch Name:", value: "Coporate Place Branch"},
                        {lable: "Reference:", value: currClient? "BPDev-"+currClient.alias : ""},
                    ]}
                >
                    <TableHeader textAlign={"center"}>
                        <TableCell style={[styles.tableHeader, styles.blankCell1]} weighting={0.3}>
                            
                        </TableCell>
                        <TableCell style={[styles.tableHeader, styles.blankCell1, styles.left, styles.smallFont]} weighting={0.7}>
                            FNB Eswatini
                        </TableCell>
                    </TableHeader>
                    <TableBody>
                        <DataTableCell style={[styles.tableBody, styles.right, styles.smallFont]} weighting={0.3} getContent={(r) => r.lable}/>
                        <DataTableCell style={[styles.tableBody, styles.smallFont]} weighting={0.7} getContent={(r) => r.value}/>
                    </TableBody>
                </Table>
              </View>
              <View style={[styles.paymentInfoBox]}>
                <Table
                    data={[
                        {lable: "Account Name:", value: "BPD Investments (PTY) LTD"},
                        {lable: "Account Number:", value: "9110006094336"},
                        {lable: "Account Type:", value: "Business Cheque/Current Account"},
                        {lable: "Branch Code:", value: "663164"},
                        {lable: "Branch Name:", value: "Coporate Place Branch"},
                        {lable: "Reference:", value: currClient? "BPDev-"+currClient.alias : ""},
                    ]}
                >
                    <TableHeader textAlign={"center"}>
                        <TableCell style={[styles.tableHeader, styles.blankCell1]} weighting={0.3}>
                            
                        </TableCell>
                        <TableCell style={[styles.tableHeader, styles.blankCell1, styles.left, styles.smallFont]} weighting={0.7}>
                          Standard Bank Eswatini
                        </TableCell>
                    </TableHeader>
                    <TableBody>
                        <DataTableCell style={[styles.tableBody, styles.right, styles.smallFont]} weighting={0.3} getContent={(r) => r.lable}/>
                        <DataTableCell style={[styles.tableBody, styles.smallFont]} weighting={0.7} getContent={(r) => r.value}/>
                    </TableBody>
                </Table>
              </View>
            </View>
            <View style={[styles.flex]}>
              <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row', marginLeft: '70px'}}>
                  <View style={{flexDirection: 'row', marginLeft: '20px'}}>
                    <Text style={[{fontFamily: 'Roboto'}, styles.smallFont, styles.singleCellHeader, styles.margLeft]}>Mobile Money</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody]}>76826543</Text>
                  </View>
                  <View style={{flexDirection: 'row', marginLeft: '20px'}}>
                    <Text style={[{fontFamily: 'Roboto'}, styles.smallFont, styles.singleCellHeader, styles.margLeft]}>MoMo Pay</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody]}>*007*1*2*103048#</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.flex]}>
              <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row', marginLeft: '20px'}}>
                  <Text style={[{fontFamily: 'Roboto'}, styles.smallFont, styles.singleCellHeaderWide]}>eWallet / Instant Money / Send Money</Text>
                  <Text style={[styles.smallFont, styles.singleCellBody]}>79360602</Text>
                </View>
              </View>
            </View>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} of ${totalPages}`
              )} fixed />
          </Page>
        </Document>
  );

  const FMKDoc = () => (
    <Document>
    <Page size="A4" style={styles.page}>
      <Image src={logo1} alt="" style={styles.image}/>
      <View style={styles.titleBoxFMK}>
        <Text>INVOICE</Text>
      </View>
      <View style={styles.company}>
        <Text style={{ fontFamily: 'Roboto' }}>BPD INVESTMENTS (PTY) LTD</Text>
        <Text>R7/60438</Text>
        <Text>TIN: 106-533-950</Text>
        <Text>Plot 409 Somhlolo Road</Text>
        <Text>Mbabane</Text>
        <Text>Eswatini</Text>
      </View>
      <View style={styles.links}>
        <Link src="https://fetchmykid.bpdteam.com" style={styles.linkFMK}>fetchmykid.bpdteam.com</Link>
        <Link src="mailto:fetchmykid@bpdteam.com" style={styles.linkFMK}>fetchmykid@bpdteam.com</Link>
        <Link src="tel:79360602" style={styles.linkFMK}>79360602</Link>
      </View>
      <View style={[styles.topInfoBox, styles.flex]}>
        <View style={{flexDirection: 'column'}}>
          <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>DATE</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment(date).format("DD-MMM-YYYY")}</Text>
            </View>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>INVOICE No.</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{invoiceNo}</Text>
            </View>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>CLIENT No.</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{clientNo}</Text>
            </View>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>TERMS</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{terms}</Text>
            </View>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>FREQUENCY</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{ratePerKM === 0 ? "Daily" : ratePerKM === 1 ? "Weekly" : ratePerKM === 2 ? "Monthly" : "Annually"}</Text>
            </View>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>RATE PER KM</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>E{ratePerKM === 0 ? "4.00" : ratePerKM === 1 ? "3.00" : ratePerKM === 2 ? "2.20" : "1.00"}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.flex}>
        <View style={styles.infoBox}>
          <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>INVOICE FOR:</Text>
          <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Parent/Guardian: {currClient ? currClient.name + " " + currClient.surname : ""}</Text>
          <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Kid: {currClient ? currClient.kid : ""}</Text>
          <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.home : ""}</Text>
          <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.phone : ""}</Text>
        </View>
        <View style={styles.infoBox}>
          <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
          <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
          <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
          <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
          <Link style={[styles.smallFont, styles.smallIndent, styles.linkFMK]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
        </View>
      </View>
      <Table
          data={data}
      >
          <TableHeader textAlign={"center"}>
              {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                  TASK #
              </TableCell> */}
              <TableCell style={styles.tableHeaderFMK} weighting={0.65}>
                  DESCRIPTION
              </TableCell>
              <TableCell style={styles.tableHeaderFMK} weighting={0.15}>
                  DISTANCE (KM)
              </TableCell>
              <TableCell style={styles.tableHeaderFMK} weighting={0.2}>
                  AMOUNT (E)
              </TableCell>
          </TableHeader>
          <TableBody>
              {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
              <DataTableCell style={styles.tableBody} weighting={0.65} getContent={(r) => r.desc}/>
              <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.15} getContent={(r) => r.distance}/>
              <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
          </TableBody>
      </Table>
      <Table
          data={[
              {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
          ]}
      >
          <TableBody>
            {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
            <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.65}></TableCell>
            <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightBlueBg, { fontFamily: 'Roboto' }]} weighting={0.15}>Sub-Total</TableCell>
            <TableCell style={[styles.tableBody, styles.center, styles.topBorder, { fontFamily: 'Roboto' }]} weighting={0.2}>{invoiceSubTotal? ccyFormat(invoiceSubTotal) : 0.00}</TableCell>
          </TableBody>
      </Table>
      <Table
          data={[{ taskNumber: 1, desc: "Test 1", distance: 3, discount: props.discount, subTotalFMK: subTotalFMK }]}
      >
          <TableBody>
            {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
            <TableCell style={[styles.tableBody, styles.center, styles.invisibleBorder]} weighting={0.65}>Thank you for your business!</TableCell>
            {
              props.discount > 0 ?
                <DataTableCell
                  style={[styles.tableBody, styles.center, styles.lightBlueBg]}
                  weighting={0.15}
                  getContent={(r) => 'Discount ('+r.discount+'%)'}
                />
                :
                <TableCell
                  style={[styles.tableBody, styles.center, styles.lightBlueBg]}
                  weighting={0.15}
                >
                  |
                </TableCell>
            }
            <DataTableCell
              style={[styles.tableBody, styles.center]}
              weighting={0.2}
              getContent={(r) => ccyFormat(invoiceSubTotal * (r.discount/100))}
            />
          </TableBody>
      </Table>
      <Table
          data={[
              {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
          ]}
          style={[styles.blankCell1]}
      >
          <TableBody>
            {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
            <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.65}></TableCell>
            <TableCell style={[styles.tableBody, styles.center, styles.lightBlueBg, { fontFamily: 'Roboto' }]} weighting={0.15}>Total</TableCell>
            <TableCell style={[styles.tableBody, styles.center, { fontFamily: 'Roboto' }]} weighting={0.2}>{invoiceTotal? ccyFormat(invoiceTotal) : 0.00}</TableCell>
          </TableBody>
      </Table>
      <Text style={{ fontSize: "8px", marginTop: "40px" }}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
      <Text style={{ fontFamily: 'Roboto', fontSize: '8px', textAlign: 'center' }}>PAYMENT METHODS</Text>
      <View style={[styles.flex]}>
        <View style={[styles.paymentInfoBox]}>
          <Table
              data={[
                  {lable: "Account Name:", value: "BPD Investments (PTY) LTD"},
                  {lable: "Account Number:", value: "62903359554"},
                  {lable: "Account Type:", value: "Business Cheque Account"},
                  {lable: "Branch Code:", value: "281264"},
                  {lable: "Branch Name:", value: "Coporate Place Branch"},
                  {lable: "Reference:", value: currClient? "FMK-"+currClient.kid : ""},
              ]}
          >
              <TableHeader textAlign={"center"}>
                  <TableCell style={[styles.tableHeaderFMK, styles.blankCell1]} weighting={0.3}>
                      
                  </TableCell>
                  <TableCell style={[styles.tableHeaderFMK, styles.blankCell1, styles.left, styles.smallFont]} weighting={0.7}>
                    FNB Eswatini
                  </TableCell>
              </TableHeader>
              <TableBody>
                  <DataTableCell style={[styles.tableBody, styles.right, styles.smallFont]} weighting={0.3} getContent={(r) => r.lable}/>
                  <DataTableCell style={[styles.tableBody, styles.smallFont]} weighting={0.7} getContent={(r) => r.value}/>
              </TableBody>
          </Table>
        </View>
        <View style={[styles.paymentInfoBox]}>
          <Table
              data={[
                {lable: "Account Name:", value: "BPD Investments (PTY) LTD"},
                {lable: "Account Number:", value: "9110006094336"},
                {lable: "Account Type:", value: "Business Cheque/Current Account"},
                {lable: "Branch Code:", value: "663164"},
                {lable: "Branch Name:", value: "Coporate Place Branch"},
                {lable: "Reference:", value: currClient? "FMK-"+currClient.kid : ""},
              ]}
          >
              <TableHeader textAlign={"center"}>
                  <TableCell style={[styles.tableHeaderFMK, styles.blankCell1]} weighting={0.3}>
                      
                  </TableCell>
                  <TableCell style={[styles.tableHeaderFMK, styles.blankCell1, styles.left, styles.smallFont]} weighting={0.7}>
                    Standard Bank Eswatini
                  </TableCell>
              </TableHeader>
              <TableBody>
                  <DataTableCell style={[styles.tableBody, styles.right, styles.smallFont]} weighting={0.3} getContent={(r) => r.lable}/>
                  <DataTableCell style={[styles.tableBody, styles.smallFont]} weighting={0.7} getContent={(r) => r.value}/>
              </TableBody>
          </Table>
        </View>
      </View>
      <View style={[styles.flex]}>
        <View style={{flexDirection: 'column'}}>
          <View style={{flexDirection: 'row', marginLeft: '70px'}}>
            <View style={{flexDirection: 'row', marginLeft: '20px'}}>
              <Text style={[{fontFamily: 'Roboto'}, styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>Mobile Money</Text>
              <Text style={[styles.smallFont, styles.singleCellBody]}>76826543</Text>
            </View>
            <View style={{flexDirection: 'row', marginLeft: '20px'}}>
              <Text style={[{fontFamily: 'Roboto'}, styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>MoMo Pay</Text>
              <Text style={[styles.smallFont, styles.singleCellBody]}>*007*1*2*103048#</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.flex]}>
        <View style={{flexDirection: 'column'}}>
          <View style={{flexDirection: 'row', marginLeft: '20px'}}>
            <Text style={[{fontFamily: 'Roboto'}, styles.smallFont, styles.singleCellHeaderWideFMK]}>eWallet / Instant Money / Send Money</Text>
            <Text style={[styles.smallFont, styles.singleCellBody]}>79360602</Text>
          </View>
        </View>
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} fixed />
    </Page>
  </Document>
  );

  const ROBDoc = () => (
    <Document>
    <Page size="A4" style={styles.page}>
      <Image src={logo10} alt="" style={styles.image}/>
      <View style={styles.titleBox}>
        <Text>INVOICE</Text>
      </View>
      <View style={styles.company}>
        <Text style={{ fontFamily: 'Roboto' }}>BPD INVESTMENTS (PTY) LTD</Text>
        <Text>R7/60438</Text>
        <Text>TIN: 106-533-950</Text>
        <Text>Plot 409 Somhlolo Road</Text>
        <Text>Mbabane</Text>
        <Text>Eswatini</Text>
      </View>
      <View style={styles.links}>
        <Link src="https://robotics.bpdteam.com" style={styles.link}>robotics.bpdteam.com</Link>
        <Link src="mailto:robotics@bpdteam.com" style={styles.link}>robotics@bpdteam.com</Link>
        <Link src="tel:79360602" style={styles.link}>79360602</Link>
      </View>
      <View style={[styles.topInfoBox, styles.flex]}>
        <View style={{flexDirection: 'column'}}>
          <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>DATE</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment(date).format("DD-MMM-YYYY")}</Text>
            </View>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>INVOICE No.</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{invoiceNo}</Text>
            </View>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>CLIENT No.</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{clientNo}</Text>
            </View>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>TERMS</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{terms}</Text>
            </View>
            <View>
              <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>RATE PER MONTH</Text>
              <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>E350.00</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.flex}>
        <View style={styles.infoBox}>
          <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>INVOICE FOR:</Text>
          <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Parent/Guardian: {currClient ? currClient.name + " " + currClient.surname : ""}</Text>
          <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Kid: {currClient ? currClient.kid : ""}</Text>
          <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.school : ""}</Text>
          <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.phone : ""}</Text>
        </View>
        <View style={styles.infoBox}>
          <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
          <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
          <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
          <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
          <Link style={[styles.smallFont, styles.smallIndent, styles.linkAlt]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
        </View>
      </View>
      <Table
          data={data}
      >
          <TableHeader textAlign={"center"}>
              {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                  TASK #
              </TableCell> */}
              <TableCell style={styles.tableHeader} weighting={0.65}>
                  DESCRIPTION
              </TableCell>
              <TableCell style={styles.tableHeader} weighting={0.15}>
                  MONTHS
              </TableCell>
              <TableCell style={styles.tableHeader} weighting={0.2}>
                  AMOUNT (E)
              </TableCell>
          </TableHeader>
          <TableBody>
              {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
              <DataTableCell style={styles.tableBody} weighting={0.65} getContent={(r) => r.desc}/>
              <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.15} getContent={(r) => r.months}/>
              <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
          </TableBody>
      </Table>
      <Table
          data={[
              {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
          ]}
      >
          <TableBody>
            {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
            <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.65}></TableCell>
            <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightYellowBg, { fontFamily: 'Roboto' }]} weighting={0.15}>Sub-Total</TableCell>
            <TableCell style={[styles.tableBody, styles.center, styles.topBorder, { fontFamily: 'Roboto' }]} weighting={0.2}>{invoiceSubTotal? ccyFormat(invoiceSubTotal) : 0.00}</TableCell>
          </TableBody>
      </Table>
      <Table
          data={[{ taskNumber: 1, desc: "Test 1", distance: 3, discount: props.discount, subTotalROB: subTotalROB }]}
      >
          <TableBody>
            {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
            <TableCell style={[styles.tableBody, styles.center, styles.invisibleBorder]} weighting={0.65}>Thank you for your business!</TableCell>
            {
              props.discount > 0 ?
                <DataTableCell
                  style={[styles.tableBody, styles.center, styles.lightYellowBg]}
                  weighting={0.15}
                  getContent={(r) => 'Discount ('+r.discount+'%)'}
                />
                :
                <TableCell
                  style={[styles.tableBody, styles.center, styles.lightYellowBg]}
                  weighting={0.15}
                >
                  |
                </TableCell>
            }
            <DataTableCell
              style={[styles.tableBody, styles.center]}
              weighting={0.2}
              getContent={(r) => ccyFormat(invoiceSubTotal * (r.discount/100))}
            />
          </TableBody>
      </Table>
      <Table
          data={[
              {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
          ]}
          style={[styles.blankCell1]}
      >
          <TableBody>
            {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
            <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.65}></TableCell>
            <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg, { fontFamily: 'Roboto' }]} weighting={0.15}>Total</TableCell>
            <TableCell style={[styles.tableBody, styles.center, { fontFamily: 'Roboto' }]} weighting={0.2}>{invoiceTotal? ccyFormat(invoiceTotal) : 0.00}</TableCell>
          </TableBody>
      </Table>
      <Text style={{ fontSize: "8px", marginTop: "40px" }}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
      <Text style={{ fontFamily: 'Roboto', fontSize: '8px', textAlign: 'center' }}>PAYMENT METHODS</Text>
      <View style={[styles.flex]}>
        <View style={[styles.paymentInfoBox]}>
          <Table
              data={[
                  {lable: "Account Name:", value: "BPD Investments (PTY) LTD"},
                  {lable: "Account Number:", value: "62903359554"},
                  {lable: "Account Type:", value: "Business Cheque Account"},
                  {lable: "Branch Code:", value: "281264"},
                  {lable: "Branch Name:", value: "Coporate Place Branch"},
                  {lable: "Reference:", value: currClient? "ROB-"+currClient.kid : ""},
              ]}
          >
              <TableHeader textAlign={"center"}>
                  <TableCell style={[styles.tableHeader, styles.blankCell1]} weighting={0.3}>
                      
                  </TableCell>
                  <TableCell style={[styles.tableHeader, styles.blankCell1, styles.left, styles.smallFont]} weighting={0.7}>
                    FNB Eswatini
                  </TableCell>
              </TableHeader>
              <TableBody>
                  <DataTableCell style={[styles.tableBody, styles.right, styles.smallFont]} weighting={0.3} getContent={(r) => r.lable}/>
                  <DataTableCell style={[styles.tableBody, styles.smallFont]} weighting={0.7} getContent={(r) => r.value}/>
              </TableBody>
          </Table>
        </View>
        <View style={[styles.paymentInfoBox]}>
          <Table
              data={[
                {lable: "Account Name:", value: "BPD Investments (PTY) LTD"},
                {lable: "Account Number:", value: "9110006094336"},
                {lable: "Account Type:", value: "Business Cheque/Current Account"},
                {lable: "Branch Code:", value: "663164"},
                {lable: "Branch Name:", value: "Coporate Place Branch"},
                {lable: "Reference:", value: currClient? "ROB-"+currClient.kid : ""},
              ]}
          >
              <TableHeader textAlign={"center"}>
                  <TableCell style={[styles.tableHeader, styles.blankCell1]} weighting={0.3}>
                      
                  </TableCell>
                  <TableCell style={[styles.tableHeader, styles.blankCell1, styles.left, styles.smallFont]} weighting={0.7}>
                    Standard Bank Eswatini
                  </TableCell>
              </TableHeader>
              <TableBody>
                  <DataTableCell style={[styles.tableBody, styles.right, styles.smallFont]} weighting={0.3} getContent={(r) => r.lable}/>
                  <DataTableCell style={[styles.tableBody, styles.smallFont]} weighting={0.7} getContent={(r) => r.value}/>
              </TableBody>
          </Table>
        </View>
      </View>
      <View style={[styles.flex]}>
        <View style={{flexDirection: 'column'}}>
          <View style={{flexDirection: 'row', marginLeft: '70px'}}>
            <View style={{flexDirection: 'row', marginLeft: '20px'}}>
              <Text style={[{fontFamily: 'Roboto'}, styles.smallFont, styles.singleCellHeader, styles.margLeft]}>Mobile Money</Text>
              <Text style={[styles.smallFont, styles.singleCellBody]}>76826543</Text>
            </View>
            <View style={{flexDirection: 'row', marginLeft: '20px'}}>
              <Text style={[{fontFamily: 'Roboto'}, styles.smallFont, styles.singleCellHeader, styles.margLeft]}>MoMo Pay</Text>
              <Text style={[styles.smallFont, styles.singleCellBody]}>*007*1*2*103048#</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.flex]}>
        <View style={{flexDirection: 'column'}}>
          <View style={{flexDirection: 'row', marginLeft: '20px'}}>
            <Text style={[{fontFamily: 'Roboto'}, styles.smallFont, styles.singleCellHeaderWide]}>eWallet / Instant Money / Send Money</Text>
            <Text style={[styles.smallFont, styles.singleCellBody]}>79360602</Text>
          </View>
        </View>
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} fixed />
    </Page>
  </Document>
  );

  return (
    <>
      {currService === "DEV" ? DevDoc() : currService === "FMK" ? FMKDoc() : ROBDoc()}
    </>
  );
}
