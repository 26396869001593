import axios from 'axios';

 

const apiClient = axios.create({
    // baseURL: 'http://localhost:8000',
    baseURL: 'http://104.248.53.221',

    withCredentials: true,

});

 

export default apiClient;