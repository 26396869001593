import React, {useState, useEffect, forwardRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableMUI from '@material-ui/core/Table';
import TableBodyMUI from '@material-ui/core/TableBody';
import TableCellMUI from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRowMUI from '@material-ui/core/TableRow';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@material-ui/core/Paper';
//import MaterialTable, { MTableToolbar } from 'material-table/core';
import MaterialTable, {MTableToolbar} from '@material-table/core';

import apiClient from "../../apiClient";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { PDFViewer, PDFDownloadLink, Document, Page, Text, View, Image, StyleSheet, Link, Font } from '@react-pdf/renderer';
import { TableHeader, DataTableCell, Table, TableBody, TableCell } from '@david.kucsai/react-pdf-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
// import LanguageIcon from '@material-ui/icons/Language';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import logo from '../../assets/logos/1.png';
import logo1 from '../../assets/logos/2.png';
import logo10 from '../../assets/logos/10.png';
import moment from 'moment';
import PatchedPagination from '../../elements/PatchedPagination';



Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  page: {
    // backgroundColor: '#E4E4E4'
    padding: 20,
    paddingBottom: 55,
  },
  header: {
    width: '100%',
    backgroundColor: "#BDBDBD"
  },
  titleBox:{
    width: '250px',
    textAlign: 'center',
    padding: 10,
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: '#fed700'
  },
  titleBoxFMK:{
    width: '250px',
    textAlign: 'center',
    padding: 10,
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: '#4479d9'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  image: {
    width: '60px',
    height: '60px'
  },
  company: {
    fontSize: '8px',
    position: 'absolute',
    top: 23,
    left: 80
  },
  links: {
    fontSize: '8px',
    position: 'absolute',
    top: 23,
    left: 210
  },
  link: {
    color: '#ad9300',
    textDecoration: 'underline',
    paddingBottom: 3
  },
  linkFMK: {
    color: '#2f5598',
    textDecoration: 'underline',
    paddingBottom: 3
  },
  flex: {
    flexDirection: 'row',
    margin: '10px'
  },
  infoBox: {
    width: '50%',
    fontSize: '10px',
    // marginTop: '10px',
    // border: '0.7px solid #000',
    padding: 10
  },
  smallIndent: {
    textIndent: '60px'
  },
  bold : {
    
  },
  tableHeader: {
    fontSize: '9px',
    backgroundColor: '#ffec82',
    padding: 5,
  },
  lightYellowBg: {
    backgroundColor: '#ffec82',
  },
  tableHeaderFMK: {
    fontSize: '9px',
    backgroundColor: '#95b4ea',
    padding: 5,
  },
  lightBlueBg: {
    backgroundColor: '#95b4ea',
  },
  tableBody: {
    fontSize: '8px',
    padding: 5,
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  blankCell1: {
    border: 'unset'
  },
  blankCell2: {
    borderLeft: 'unset'
  },
  singleCellHeader: {
    backgroundColor: '#ffec82',
    border: '1px solid #000',
    pading: '10px',
    width: '100px',
    heiight: '80px',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  singleCellHeaderFMK: {
    backgroundColor: '#95b4ea',
    border: '1px solid #000',
    pading: '10px',
    width: '100px',
    heiight: '80px',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  singleCellBody: {
    border: '1px solid #000',
    pading: '10px',
    width: '100px',
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  topInfoBox: {
    paddingBottom: '10px',
    marginTop: '15px',
  },
  paymentInfoBox: {
    width: '290px',
  },
  smallFont: {
    fontSize: '8px'
  },
  topBorder: {
    borderTop: '2px solid #000'
  },
  margLeft: {
    marginLeft: '10px'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  invisibleBorder: {
    borderBottom: '3px solid white',
    borderLeft: '2px solid white',
    margin: '0',
    marginBottom: '-2px',
    marginLeft: '-2px',
    // border: '0!important',
    borderCollapse: 'collapse!important',
    borderStyle : 'hidden!important',
    outline: 'none!important'
  },
});


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const TAX_RATE = 0.07;

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

// function subtotal(items) {
//   return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
// }

// const rows = [
//   createRow('Paperclips (Box)', 100, 1.15),
//   createRow('Paper (Case)', 10, 45.99),
//   createRow('Waste Basket', 2, 17.99),
// ];

// const receiptSubtotal = subtotal(rows);
// const receiptTaxes = TAX_RATE * receiptSubtotal;
// const receiptTotal = receiptTaxes + receiptSubtotal;


export default function SpanningTable() {
  const classes = useStyles();

  const [openPreview, setOpenPreview] = React.useState(false);
  const [openQuickReceipt, setOpenQuickReceipt] = React.useState(false);
  const [currClientID, setCurrClientID] = useState();
  const [currClientValue, setCurrClientValue] = useState("");
  const [currClient, setCurrClient] = useState();
  const [currService, setCurrService] = useState("");
  const [FMKclients, setFMKclients] = useState([]);
  const [DEVclients, setDEVclients] = useState([]);
  const [ROBclients, setROBclients] = useState([]);
  const [subTotalFMK, setSubTotalFMK] = useState(0);
  const [subTotalDEV, setSubTotalDEV] = useState(0);
  const [subTotalROB, setSubTotalROB] = useState(0);
  const [totalFMK, setTotalFMK] = useState(0);
  const [totalDEV, setTotalDEV] = useState(0);
  const [totalROB, setTotalROB] = useState(0);
  const [data, setData] = useState([]);
  const [businessType, setBusinessType] = useState(1);
  const [ratePerHour, setRatePerHour] = useState(1);
  const [frequency, setFrequency] = useState(3);
  const [ratePerKM, setRatePerKM] = useState(5);
  const [ratePerKMArr, setRatePerKMArr] = useState([]);
  const [tripFrequencies, setTripFrequencies] = useState([]);
  const [method, setMethod] = useState([]);
  const [methods, setMethods] = useState([]);
  const [ratePerHourValue, setRatePerHourValue] = useState(300);
  const [ratePerMonthValue, setRatePerMonthValue] = useState(350);
  const [ratePerKMValue, setRatePerKMValue] = useState(2.2);
  const [frequencyValue, setFrequencyValue] = useState(22);
  const [frequencyStr, setFrequencyStr] = useState("Monthly");
  const [receipts, setReceipts] = useState();
  const [terms, setTerms] = useState("Due upon receipt");
  const [termsId, setTermsId] = useState(1);
  const [refreshReceipts, setRefreshReceipts] = useState(0);
  const [transportRoute, setTransportRoute] = useState("");
  const [discount, setDiscount] = useState(0);
  const [credit, setCredit] = useState(0);
  const [outstanding, setOutstanding] = useState(0);

  const handleClickOpenPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleClickOpenQuickReceipt = () => {
    setOpenQuickReceipt(true);
  };

  const handleCloseQuickReceipt = () => {
    setOpenQuickReceipt(false);
  };

  const handleSaveReceipt = () => {

    var datapack;
    var datapackIncome;
    var credit = 0;
    var outstanding = 0;
    var valid = false;

    if(currService === "DEV"){
      datapack = {
        client_dev_id: currClientID,
        client_no: "DEV-" + currClient.id,
        receipt_no: "DEV-" + pad(receipts.length+1, 4),
        date: moment().format("DD-MMM-YYYY"),
        items: JSON.stringify(data),
        amount: totalDEV,
        terms_id: termsId,
        discount: discount
      };

      datapackIncome = {
        client_dev_id: currClientID,
        receipt_no: "DEV-" + pad(receipts.length+1, 4),
        date: moment().format("DD-MMM-YYYY"),
        amount: totalDEV,
        method_id: method
      }

      if(totalDEV < currClient.outstanding){
        outstanding = currClient.outstanding - totalDEV; 
      }
      else if(totalDEV > currClient.outstanding){
        credit = totalDEV - currClient.outstanding;
      }

      apiClient.put(
        `/api/clients-dev/${currClient.uuid}`,
        {
          ...currClient,
          credit: credit,
          outstanding: outstanding
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
        .then((res) => {
          valid = true;
        })
        .catch((e) => {
          valid = false;
        });
    }
    else if(currService === "FMK"){
      datapack = {
        client_fmk_id: currClientID,
        client_no: "FMK-" + currClient.id,
        receipt_no: "FMK-" + pad(receipts.length+1, 4),
        date: moment().format("DD-MMM-YYYY"),
        items: JSON.stringify(data),
        amount: totalFMK,
        terms_id: termsId,
        discount: discount,
        method_id: method
      };

      datapackIncome = {
        client_fmk_id: currClientID,
        receipt_no: "FMK-" + pad(receipts.length+1, 4),
        date: moment().format("DD-MMM-YYYY"),
        amount: totalFMK,
        method_id: method
      }

      if(totalFMK < currClient.outstanding){
        outstanding = currClient.outstanding - totalFMK; 
      }
      else if(totalFMK > currClient.outstanding){
        credit = totalFMK - currClient.outstanding;
      }

      apiClient.put(
        `/api/clients-fmk/${currClient.uuid}`,
        {
          ...currClient,
          credit: credit,
          outstanding: outstanding
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
        .then((res) => {
          valid = true;
        })
        .catch((e) => {
          valid = false;
        });
    }
    else{
      datapack = {
        client_rob_id: currClientID,
        client_no: "ROB-" + currClient.id,
        receipt_no: "ROB-" + pad(receipts.length+1, 4),
        date: moment().format("DD-MMM-YYYY"),
        items: JSON.stringify(data),
        amount: totalROB,
        terms_id: termsId,
        discount: discount,
        method_id: method
      };

      datapackIncome = {
        client_rob_id: currClientID,
        receipt_no: "ROB-" + pad(receipts.length+1, 4),
        date: moment().format("DD-MMM-YYYY"),
        amount: totalROB,
        method_id: method
      }

      if(totalROB < currClient.outstanding){
        outstanding = currClient.outstanding - totalROB; 
      }
      else if(totalROB > currClient.outstanding){
        credit = totalROB - currClient.outstanding;
      }

      apiClient.put(
        `/api/clients-rob/${currClient.uuid}`,
        {
          ...currClient,
          credit: credit,
          outstanding: outstanding
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
        .then((res) => {
          valid = true;
        })
        .catch((e) => {
          valid = false;
        });
    }

    // if(valid){
      apiClient.post(
        `/api/receipts/`,
        datapack,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
        .then((res) => {
          alert("Receipt Saved Successfully");

          apiClient.post(
            `/api/incomes/`,
            datapackIncome,
            {
              headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            })
            .then((res) => {
              // alert("Income Saved Successfully");
            })
            .catch((e) => {
              // alert("Something went wrong while saving receipt. Please try again");
            });
        })
        .catch((e) => {
          alert("Something went wrong while saving receipt. Please try again");
        });
    // }
  };


  function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  function setCurrentClient(client){
    setCurrClientValue(client);
    var result;
    if(client.charAt(0) === 'D'){
      result = client.slice(1);
      setCurrClientID(parseInt(result, 10));
      setCurrClient(DEVclients[DEVclients.findIndex(x => x.id === parseInt(result, 10))]);
      setCurrService("DEV");
    }
    else if(client.charAt(0) === 'F'){
      result = client.slice(1);
      setCurrClientID(parseInt(result, 10));
      setCurrClient(FMKclients[FMKclients.findIndex(x => x.id === parseInt(result, 10))]);
      setCurrService("FMK");
    }
    else{
      result = client.slice(1);
      setCurrClientID(parseInt(result, 10));
      setCurrClient(ROBclients[ROBclients.findIndex(x => x.id === parseInt(result, 10))]);
      setCurrService("ROB");
    }
  }

  function setTransportRouteData(index) {
    if (index === 0) {
      setTransportRoute("To and From School");
      setData([
        {
          taskNumber: 1,
          desc: `From ${currClient.home} to ${currClient.school}`,
          distance: currClient.distance,
          amount: Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: 0 },
        },
        {
          taskNumber: 2,
          desc: `From ${currClient.school} to ${currClient.home}`,
          distance: currClient.distance,
          amount: Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: 1 },
        },
      ]);
    }
    else if (index === 1) {
      setTransportRoute("From School to Home");
      setData([
        {
          taskNumber: 1,
          desc: `From ${currClient.school} to ${currClient.home}`,
          distance: currClient.distance,
          amount: currClient.distance <= 5 ? Math.ceil((currClient.distance * (ratePerKMValue + 0.3) * frequencyValue) / 10) * 10 : Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: 0 },
        }
      ]);
    }
    else if (index === 2) {
      setTransportRoute("From Home to School");
      setData([
        {
          taskNumber: 1,
          desc: `From ${currClient.home} to ${currClient.school}`,
          distance: currClient.distance,
          amount: currClient.distance <= 5 ? Math.ceil((currClient.distance * (ratePerKMValue + 0.3) * frequencyValue) / 10) * 10 : Math.ceil((currClient.distance * ratePerKMValue * frequencyValue) / 10) * 10,
          tableData: { id: 0 },
        }
      ]);
    }
  }

  function setRate(id) {
    setRatePerKM(ratePerKMArr[ratePerKMArr.findIndex(x => x.id === tripFrequencies[tripFrequencies.findIndex(y => y.id === id)].rate_per_km_id)].id);
    setRatePerKMValue(parseInt(ratePerKMArr[ratePerKMArr.findIndex(x => x.id === tripFrequencies[tripFrequencies.findIndex(y => y.id === id)].rate_per_km_id)].name));
    setFrequencyStr(tripFrequencies[tripFrequencies.findIndex(y => y.id === id)].name);
    // alert(ratePerKMArr[ratePerKMArr.findIndex(x => x.id === tripFrequencies[tripFrequencies.findIndex(y => y.id === id)].rate_per_km_id)].name);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = (tempSubTotal + (item.hours * ratePerHourValue));
      tempTotal = (tempTotal + (item.hours * ratePerHourValue));
      setSubTotalDEV(tempSubTotal);
      setTotalDEV(discount !== null && discount !== 0 ?  tempTotal * (discount/100) : tempTotal);
      return null;
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateROBTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {
      tempSubTotal = (tempSubTotal + (item.months * ratePerMonthValue));
      tempTotal = (tempTotal + (item.months * ratePerMonthValue));
      setSubTotalROB(tempSubTotal);
      setTotalROB(discount !== null && discount !== 0 ?  tempTotal - (tempTotal * (discount/100)) : tempTotal);
      return null;
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateFMKTotal() {
    var tempTotal = 0;
    var tempSubTotal = 0;
    return data.map((item) => {

      if (transportRoute === "To and From School") {
        tempSubTotal =
          tempSubTotal +
          Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
        tempTotal =
          tempTotal +
          Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
      }
      else {
        if(item.distance <= 5){
          tempSubTotal =
            tempSubTotal +
            Math.ceil((item.distance * (ratePerKMValue + 0.3) * frequencyValue) / 10) * 10;
          tempTotal =
            tempTotal +
            Math.ceil((item.distance * (ratePerKMValue + 0.3) * frequencyValue) / 10) * 10;
        }
        else{
          tempSubTotal =
            tempSubTotal +
            Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
          tempTotal =
            tempTotal +
            Math.ceil((item.distance * ratePerKMValue * frequencyValue) / 10) * 10;
        }
      }

      setSubTotalFMK(tempSubTotal);
      setTotalFMK(discount !== null && discount !== 0 ?  (tempSubTotal - (tempTotal * (discount/100))) : tempTotal);
      return null;
    });
  }

  function reCalculateAmounts(){
    var tempData = [...data];
    
    tempData.map((item) => {
      if(item.hours === 0){
        return null;
      }
      else{
        return item.amount = item.hours * ratePerHourValue;
      }
    });

    setData(tempData);
  }

  function reCalculateROBAmounts(){
    var tempData = [...data];
    
    tempData.map((item) => {
      if(item.months === 0){
        return null;
      }
      else{
        return item.amount = item.months * ratePerMonthValue;
      }
    });

    setData(tempData);
  }

  function reCalculateFMKAmounts(){
    var tempData = [...data];
    
    tempData.map((item) => {
      return item.amount = Math.ceil((item.distance * ratePerKMValue * frequencyValue)/10)*10;
    });

    setData(tempData);
  }

  function getMethods() {
    return methods.map((item, index) => {
      return <MenuItem key={"PaymentMethod" + index} value={item.id}>{item.name}</MenuItem>;
    });
  }
  
  function getFMKclients() {
    return FMKclients.map((item, index) => {
      return <MenuItem key={"FMKClient" + index} value={"F" + item.id}>{item.name + ' ' + item.surname + ' (' + item.kid + ') '}</MenuItem>;
    });
  }

  function getROBclients() {
    return ROBclients.map((item, index) => {
      return <MenuItem key={"ROBClient" + index} value={"R" + item.id}>{item.name + ' ' + item.surname + ' (' + item.kid + ') '}</MenuItem>;
    });
  }

  function getDevClients() {
    return DEVclients.map((item, index) => {
      return <MenuItem key={"DevClient" + index} value={"D" + item.id}>{item.name}</MenuItem>;
    });
  }

  useEffect(() => {
    apiClient.get(
      `/api/clients-fmk/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setFMKclients(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/clients-dev/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setDEVclients(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/clients-rob/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setROBclients(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/receipts/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setReceipts(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/ratePerKms/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setRatePerKMArr(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/tripFrequencies/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setTripFrequencies(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
      `/api/payment-methods/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setMethods(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    apiClient.get(
      `/api/receipts/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setReceipts(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [refreshReceipts]);

  useEffect(() => {
    if(ratePerHour === 0){
      setRatePerHourValue(200);
    }
    else{
      setRatePerHourValue(300);
    }

    calculateTotal();
    // 

  }, [ratePerHour, calculateTotal]);

  useEffect(() => {
    // if(ratePerKM === 0){
    //   setRatePerKMValue(3);
    // }
    // else if(ratePerKM === 1){
    //   setRatePerKMValue(2);
    // }
    // else if(ratePerKM === 2){
    //   setRatePerKMValue(1.8);
    // }
    // else {
    //   setRatePerKMValue(1);
    // }

    calculateFMKTotal();
    // 

  }, [ratePerKM, calculateFMKTotal]);

  useEffect(() => {
    if(frequencyStr === "Daily"){
      setFrequencyValue(1);
    }
    else if(frequencyStr === "Weekly"){
      setFrequencyValue(5);
    }
    else if(frequencyStr === "Monthly"){
      setFrequencyValue(22);
    }
    else {
      setFrequencyValue(264);
    }

    calculateFMKTotal();
    // 

  }, [frequencyStr, calculateFMKTotal]);


  const DEVcolumns = [
    { title: 'TASK #', field: 'taskNumber', initialEditValue: (data.length+1), editable: 'never' },
    { title: 'DESCRIPTION', field: 'desc'},
    { title: 'HOURS', field: 'hours', type: 'numeric', align: 'left' },
    {
      title: 'AMOUNT (E)',
      field: 'amount',
      type: 'numeric',
      align: 'left',
      render: rowData => ccyFormat(rowData.hours === 0 ? rowData.amount : rowData.hours * ratePerHourValue),
      editable: 'onUpdate'
    },
  ];

  const ROBcolumns = [
    { title: 'TASK #', field: 'taskNumber', initialEditValue: (data.length+1), editable: 'never' },
    { title: 'DESCRIPTION', field: 'desc'},
    { title: 'MONTHS', field: 'months', type: 'numeric', align: 'left' },
    {
      title: 'AMOUNT (E)',
      field: 'amount',
      type: 'numeric',
      align: 'left',
      render: rowData => ccyFormat(rowData.months === 0 ? rowData.amount : rowData.months * ratePerMonthValue),
      editable: 'onUpdate'
    },
  ];

  const FMKColumns = [
    { title: 'TASK #', field: 'taskNumber', initialEditValue: (data.length+1), editable: 'never' },
    { title: 'DESCRIPTION', field: 'desc'},
    { title: 'DISTANCE (KM)', field: 'distance', type: 'numeric', align: 'left' },
    {
      title: 'AMOUNT (E)',
      field: 'amount',
      type: 'numeric',
      align: 'left',
      render: rowData => ccyFormat(Math.ceil((rowData.distance * ratePerKMValue * frequencyValue)/10)*10),
      editable: 'never'
    },
  ];

  const DevDoc = () => (
    <Document>
          <Page size="A4" style={styles.page}>
            <Image src={logo} alt="" style={styles.image}/>
            <View style={styles.titleBox}>
              <Text>RECEIPT</Text>
            </View>
            <View style={styles.company}>
              <Text style={styles.bold}>BPD INVESTMENTS (PTY) LTD</Text>
              <Text>R7/60438</Text>
              <Text>TIN: 106-533-950</Text>
              <Text>Plot 409 Somhlolo Road</Text>
              <Text>Mbabane</Text>
              <Text>Eswatini</Text>
            </View>
            <View style={styles.links}>
              <Link src="https://dev.bpdteam.com" style={styles.link}>dev.bpdteam.com</Link>
              <Link src="mailto:dev@bpdteam.com" style={styles.link}>dev@bpdteam.com</Link>
              <Link src="tel:79360602" style={styles.link}>79360602</Link>
            </View>
            <View style={[styles.topInfoBox, styles.flex]}>
              {/* <Table
                  data={[
                      {date: "14 Jul 2021"},
                  ]}
              >
                  <TableHeader textAlign={"center"}>
                      <TableCell style={[styles.tableHeader, styles.smallFont]}>
                          DATE
                      </TableCell>
                  </TableHeader>
                  <TableBody>
                      <DataTableCell style={[styles.tableBody, styles.center, styles.smallFont]} getContent={(r) => r.date}/>
                  </TableBody>
              </Table> */}
              <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>DATE</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment().format("DD-MMM-YYYY")}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>RECEIPT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{receipts ? ("DEV-" + pad(receipts.length+1, 4)) : "DEV-0001"}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>CLIENT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{currClient ? ("DEV-" + currClient.id) : null}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.flex}>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>RECEIPT FOR:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.name : ""}</Text>
                {currClient ?
                  currClient.address.split(',').map((item,index) => {
                    return <Text key={"address-key-"+index} style={[styles.smallFont, styles.smallIndent, styles.bold]}>{item}</Text>
                  })
                  :
                  ""
                }
              </View>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
                <Link style={[styles.smallFont, styles.smallIndent, styles.link]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
              </View>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>BUSINESS TYPE:</Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{ratePerHour === 0 ? "Start-Up" : "Coporate"}</Text>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>RATE PER HOUR (E):</Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{ratePerHour === 0 ? "200" : "300"}</Text>
            </View>
            <Table
                data={data}
            >
                <TableHeader textAlign={"center"}>
                    {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                        TASK #
                    </TableCell> */}
                    <TableCell style={styles.tableHeader} weighting={0.7}>
                        DESCRIPTION
                    </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>
                        HOURS
                    </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.2}>
                        AMOUNT (E)
                    </TableCell>
                </TableHeader>
                <TableBody>
                    {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
                    <DataTableCell style={styles.tableBody} weighting={0.7} getContent={(r) => r.desc}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.hours}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightYellowBg]} weighting={0.1}>Sub-Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder]} weighting={0.2}>{ccyFormat(subTotalDEV)}</TableCell>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.center, styles.invisibleBorder]} weighting={0.7}>Thank you for your business!</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg]} weighting={0.1}>|</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}></TableCell>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", hours: 3, amount: 900 }
                ]}
                style={[styles.blankCell1]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.7}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg]} weighting={0.1}>Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}>{ccyFormat(totalDEV)}</TableCell>
                </TableBody>
            </Table>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} of ${totalPages}`
              )} fixed />
          </Page>
        </Document>
  );

  const FMKDoc = () => (
    <Document>
          <Page size="A4" style={styles.page}>
            <Image src={logo1} alt="" style={styles.image}/>
            <View style={styles.titleBoxFMK}>
              <Text>RECEIPT</Text>
            </View>
            <View style={styles.company}>
              <Text style={styles.bold}>BPD INVESTMENTS (PTY) LTD</Text>
              <Text>R7/60438</Text>
              <Text>TIN: 106-533-950</Text>
              <Text>Plot 409 Somhlolo Road</Text>
              <Text>Mbabane</Text>
              <Text>Eswatini</Text>
            </View>
            <View style={styles.links}>
              <Link src="https://fetchmykid.bpdteam.com" style={styles.linkFMK}>fetchmykid.bpdteam.com</Link>
              <Link src="mailto:fetchmykid@bpdteam.com" style={styles.linkFMK}>fetchmykid@bpdteam.com</Link>
              <Link src="tel:79360602" style={styles.linkFMK}>79360602</Link>
            </View>
            <View style={[styles.topInfoBox, styles.flex]}>
              <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>DATE</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment().format("DD-MMM-YYYY")}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>RECEIPT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{receipts ? ("FMK-" + pad(receipts.length+1, 4)) : "FMK-0001"}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeaderFMK, styles.margLeft]}>CLIENT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{currClient ? ("FMK-" + currClient.id) : null}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.flex}>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>RECEIPT FOR:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Parent/Guardian: {currClient ? currClient.name + " " + currClient.surname : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Kid: {currClient ? currClient.kid : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.home : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.phone : ""}</Text>
              </View>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
                <Link style={[styles.smallFont, styles.smallIndent, styles.linkFMK]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
              </View>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
            <Text style={[styles.smallFont, styles.margLeft]}>FREQUENCY:</Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{frequencyStr}</Text>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>RATE PER KM: </Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{`E${ccyFormat(ratePerKMValue)}`}</Text>
            </View>
            <Table
                data={data}
            >
                <TableHeader textAlign={"center"}>
                    {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                        TASK #
                    </TableCell> */}
                    <TableCell style={styles.tableHeaderFMK} weighting={0.65}>
                        DESCRIPTION
                    </TableCell>
                    <TableCell style={styles.tableHeaderFMK} weighting={0.15}>
                        DISTANCE (KM)
                    </TableCell>
                    <TableCell style={styles.tableHeaderFMK} weighting={0.2}>
                        AMOUNT (E)
                    </TableCell>
                </TableHeader>
                <TableBody>
                    {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
                    <DataTableCell style={styles.tableBody} weighting={0.65} getContent={(r) => r.desc}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.15} getContent={(r) => r.distance}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.65}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightBlueBg]} weighting={0.15}>Sub-Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder]} weighting={0.2}>{ccyFormat(subTotalFMK)}</TableCell>
                </TableBody>
            </Table>
            <Table
              data={[{ taskNumber: 1, desc: "Test 1", distance: 3, discount: discount, subTotalFMK: subTotalFMK }]}
            >
              <TableBody>
                {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                <TableCell
                  style={[styles.tableBody, styles.center, styles.invisibleBorder]}
                  weighting={0.65}
                >
                  Thank you for your business!
                </TableCell>
                {
                  discount > 0 ?
                    <DataTableCell
                      style={[styles.tableBody, styles.center, styles.lightBlueBg]}
                      weighting={0.15}
                      getContent={(r) => 'Discount ('+r.discount+'%)'}
                    />
                    :
                    <TableCell
                      style={[styles.tableBody, styles.center, styles.lightBlueBg]}
                      weighting={0.15}
                    >
                      |
                    </TableCell>
                }
                <DataTableCell
                  style={[styles.tableBody, styles.center]}
                  weighting={0.2}
                  getContent={(r) => ccyFormat(r.subTotalFMK * (r.discount/100))}
                />
              </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
                ]}
                style={[styles.blankCell1]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.65}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightBlueBg]} weighting={0.15}>Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}>{ccyFormat(totalFMK)}</TableCell>
                </TableBody>
            </Table>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} of ${totalPages}`
              )} fixed />
          </Page>
        </Document>
  );

  const ROBDoc = () => (
    <Document>
          <Page size="A4" style={styles.page}>
            <Image src={logo10} alt="" style={styles.image}/>
            <View style={styles.titleBox}>
              <Text>RECEIPT</Text>
            </View>
            <View style={styles.company}>
              <Text style={styles.bold}>BPD INVESTMENTS (PTY) LTD</Text>
              <Text>R7/60438</Text>
              <Text>TIN: 106-533-950</Text>
              <Text>Plot 409 Somhlolo Road</Text>
              <Text>Mbabane</Text>
              <Text>Eswatini</Text>
            </View>
            <View style={styles.links}>
              <Link src="https://robotics.bpdteam.com" style={styles.link}>robotics.bpdteam.com</Link>
              <Link src="mailto:robotics@bpdteam.com" style={styles.link}>robotics@bpdteam.com</Link>
              <Link src="tel:79360602" style={styles.linkFMK}>79360602</Link>
            </View>
            <View style={[styles.topInfoBox, styles.flex]}>
              <View style={{flexDirection: 'column'}}>
                <View style={{flexDirection: 'row', marginTop: '20px', marginLeft: '20px'}}>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>DATE</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{moment().format("DD-MMM-YYYY")}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>RECEIPT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{receipts ? ("ROB-" + pad(receipts.length+1, 4)) : "FMK-0001"}</Text>
                  </View>
                  <View>
                    <Text style={[styles.smallFont, styles.singleCellHeader, styles.margLeft]}>CLIENT No.</Text>
                    <Text style={[styles.smallFont, styles.singleCellBody, styles.margLeft]}>{currClient ? ("ROB-" + currClient.id) : null}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.flex}>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>RECEIPT FOR:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Parent/Guardian: {currClient ? currClient.name + " " + currClient.surname : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Kid: {currClient ? currClient.kid : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.school : ""}</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>{currClient ? currClient.phone : ""}</Text>
              </View>
              <View style={styles.infoBox}>
                <Text style={[styles.smallFont, {paddingBottom: '5px'}]}>PREPARED BY:</Text>
                <Text style={[styles.smallFont, styles.smallIndent, styles.bold]}>Nothando Sihlongonyane</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>Accountant &amp; Clerk</Text>
                <Text style={[styles.smallFont, styles.smallIndent]}>BPD Team</Text>
                <Link style={[styles.smallFont, styles.smallIndent, styles.link]} src="mailto:accounts@bpdteam.com">accounts@bpdteam.com</Link>
              </View>
            </View>
            <View style={{flexDirection: 'row', margin: '5px'}}>
              <Text style={[styles.smallFont, styles.margLeft]}>RATE PER MONTH: </Text>
              <Text style={[styles.smallFont, styles.margLeft]}>{`E${ccyFormat(ratePerMonthValue)}`}</Text>
            </View>
            <Table
                data={data}
            >
                <TableHeader textAlign={"center"}>
                    {/* <TableCell style={styles.tableHeader} weighting={0.1}>
                        TASK #
                    </TableCell> */}
                    <TableCell style={styles.tableHeader} weighting={0.65}>
                        DESCRIPTION
                    </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.15}>
                        MONTH
                    </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.2}>
                        AMOUNT (E)
                    </TableCell>
                </TableHeader>
                <TableBody>
                    {/* <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.1} getContent={(r) => r.taskNumber}/> */}
                    <DataTableCell style={styles.tableBody} weighting={0.65} getContent={(r) => r.desc}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.15} getContent={(r) => r.months}/>
                    <DataTableCell style={[styles.tableBody, styles.center]} weighting={0.2} getContent={(r) => ccyFormat(r.amount)}/>
                </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
                ]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.65}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder, styles.lightYellowBg]} weighting={0.15}>Sub-Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.topBorder]} weighting={0.2}>{ccyFormat(subTotalROB)}</TableCell>
                </TableBody>
            </Table>
            <Table
              data={[{ taskNumber: 1, desc: "Test 1", distance: 3, discount: discount, subTotalROB: subTotalROB }]}
            >
              <TableBody>
                {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                <TableCell
                  style={[styles.tableBody, styles.center, styles.invisibleBorder]}
                  weighting={0.65}
                >
                  Thank you for your business!
                </TableCell>
                {
                  discount > 0 ?
                    <DataTableCell
                      style={[styles.tableBody, styles.center, styles.lightYellowBg]}
                      weighting={0.15}
                      getContent={(r) => 'Discount ('+r.discount+'%)'}
                    />
                    :
                    <TableCell
                      style={[styles.tableBody, styles.center, styles.lightYellowBg]}
                      weighting={0.15}
                    >
                      |
                    </TableCell>
                }
                <DataTableCell
                  style={[styles.tableBody, styles.center]}
                  weighting={0.2}
                  getContent={(r) => ccyFormat(subTotalROB * (r.discount/100))}
                />
              </TableBody>
            </Table>
            <Table
                data={[
                    {taskNumber: 1, desc: "Test 1", distance: 3, amount: 900 }
                ]}
                style={[styles.blankCell1]}
            >
                <TableBody>
                  {/* <TableCell style={[styles.tableBody, styles.blankCell1]} weighting={0.1}></TableCell> */}
                  <TableCell style={[styles.tableBody, styles.invisibleBorder]} weighting={0.65}></TableCell>
                  <TableCell style={[styles.tableBody, styles.center, styles.lightYellowBg]} weighting={0.15}>Total</TableCell>
                  <TableCell style={[styles.tableBody, styles.center]} weighting={0.2}>{ccyFormat(totalROB)}</TableCell>
                </TableBody>
            </Table>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} of ${totalPages}`
              )} fixed />
          </Page>
        </Document>
  );

  return (
    <>
    <div style={{ width: "100%"}}>
    <div>
      <Button variant="outlined" color="primary" onClick={() => handleClickOpenPreview()}>
        Preview Receipt
      </Button>
      <Button variant="outlined" color="primary" onClick={() => handleClickOpenQuickReceipt()}>
        Quick Receipt
      </Button>
      <Dialog fullScreen open={openPreview} onClose={() => handleClosePreview()} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => handleClosePreview()} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {currClient ? currClient.name : "Client "} Receipt
            </Typography>
            <PDFDownloadLink document={currService === "DEV" ? <DevDoc /> : currService === "FMK" ? <FMKDoc /> : <ROBDoc />} fileName={receipts ? currService === "DEV" ? ("BPDev-Receipt-" + pad(receipts.length+1, 4)) : currService === "FMK" ? ("FMK-Receipt-" + pad(receipts.length+1, 4)) : ("ROB-Receipt-" + pad(receipts.length+1, 4)) : "Receipt-0001"}>
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<GetAppIcon />}
                >
                  Download
                </Button>
              }
            </PDFDownloadLink>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={() => handleSaveReceipt()}
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <PDFViewer style={{ width: "100%", height: '100vh'}}>
          {currService === "DEV" ? DevDoc() : currService === "FMK" ? FMKDoc() : ROBDoc()}
        </PDFViewer>
      </Dialog>

      <Dialog
            fullScreen
            open={openQuickReceipt}
            onClose={() => handleCloseQuickReceipt()}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => handleCloseQuickReceipt()}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Quick Receipt
                </Typography>
                <PDFDownloadLink
                  document={currService === "DEV" ? <DevDoc /> : currService === "FMK" ? <FMKDoc /> : <ROBDoc />}
                  fileName={
                    receipts ? 
                      currService === "DEV" ? 
                        "BPDev-Receipt-" + pad(receipts.length + 1, 4)
                        : currService === "FMK" ? 
                          "FMK-Receipt-" + pad(receipts.length + 1, 4)
                          :
                          "ROB-Receipt-" + pad(receipts.length + 1, 4)
                      : "Receipt"
                  }
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                      >
                        Download
                      </Button>
                    )
                  }
                </PDFDownloadLink>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={() => handleSaveReceipt()}
                >
                  Save
                </Button>
              </Toolbar>
            </AppBar>
            <div style={{ display: "flex" }}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="grouped-select">Client</InputLabel>
                <Select
                  defaultValue=""
                  value={currClientValue}
                  id="grouped-select"
                  onChange={(e) => setCurrentClient(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <ListSubheader>BPDev</ListSubheader>
                  {getDevClients()}
                  <ListSubheader>FetchMyKid</ListSubheader>
                  {getFMKclients()}
                  <ListSubheader>BPDev Robotics</ListSubheader>
                  {getROBclients()}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="grouped-select">
                  Transport Route
                </InputLabel>
                <Select
                  defaultValue={0}
                  value={transportRoute}
                  id="grouped-select-route"
                  onChange={(e) => setTransportRouteData(e.target.value)}
                >
                  <MenuItem value={0}>To and From School</MenuItem>
                  <MenuItem value={1}>From School to Home</MenuItem>
                  <MenuItem value={2}>From Home to School</MenuItem>
                </Select>
              </FormControl>
            </div>
            <PDFViewer style={{ width: "100%", height: "100vh" }}>
              {currService === "DEV" ? DevDoc() : currService === "FMK" ? FMKDoc() : ROBDoc()}
            </PDFViewer>
          </Dialog>
    </div>
    
    </div>
      <MaterialTable
        title="Receipt"
        columns={currService === "DEV" ? DEVcolumns : currService === "FMK" ? FMKColumns : ROBcolumns}
        data={data}
        icons={tableIcons}
        options={{
          search: false,
          actionsColumnIndex: 0,
          exportButton: false,
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              var totalV = 0;
              
              if(currService === "DEV"){
                totalV = newData.hours * ratePerHourValue;
                newData.amount = totalV;
                setSubTotalDEV(subTotalDEV + (totalV));
                setTotalDEV(discount !== null && discount !== 0 ? (totalDEV + totalV) * (discount/100) : (totalDEV + totalV));
              }
              else if(currService === "FMK"){
                totalV = Math.ceil((newData.distance * ratePerKMValue * frequencyValue)/10)*10;
                newData.amount = totalV;
                setSubTotalFMK(subTotalFMK + (totalV));
                setTotalFMK(discount !== null && discount !== 0 ? ((subTotalFMK + totalV) - ((totalFMK + totalV) * (discount/100))) : (totalFMK + totalV));
              }
              else{
                totalV = newData.months * ratePerMonthValue;
                newData.amount = totalV;
                setSubTotalROB(subTotalROB + (totalV));
                setTotalROB(discount !== null && discount !== 0 ? (totalROB + totalV) - (totalROB + totalV) * (discount/100) : (totalROB + totalV));
              }

              setData([...data, newData]);
              
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              
              var totalV = 0;
              
              if(currService === "DEV"){
                totalV = newData.hours * ratePerHourValue;
                newData.amount = totalV;
              }
              else if(currService === "FMK"){
                totalV = Math.ceil((newData.distance * ratePerKMValue * frequencyValue)/10)*10;
                newData.amount = totalV;
              }
              else{
                totalV = newData.months * ratePerMonthValue;
                newData.amount = totalV;
              }

              dataUpdate[index] = newData;
              setData([...dataUpdate]);

              
              if(currService === "DEV"){
                calculateTotal();
              }
              else if(currService === "FMK"){
                calculateFMKTotal();
              }
              else{
                calculateROBTotal();
              }

              resolve();
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setData([...dataDelete]);

              if(currService === "DEV"){
                calculateTotal();
              }
              else if(currService === "FMK"){
                calculateFMKTotal();
              }
              else{
                calculateROBTotal();
              }
              
              resolve();
            }),
        }}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              var totalV = 0;
              if(columnDef.title === 'HOURS'){
                totalV = newValue * ratePerHourValue;
                setSubTotalDEV(subTotalDEV + totalV);
                setTotalDEV(discount !== null && discount !== 0 ? (totalDEV + totalV) * (discount/100) : (totalDEV + totalV));

                rowData.hours = newValue;
                rowData.amount = totalV;

                calculateTotal();
              }

              if(columnDef.title === 'DISTANCE (KM)'){
                totalV = Math.ceil((newValue * ratePerKMValue * frequencyValue)/10)*10;
                setSubTotalFMK(subTotalFMK + totalV);
                setTotalFMK(discount !== null && discount !== 0 ? ((subTotalFMK + totalV) - ((totalFMK + totalV) * (discount/100))) : (totalFMK + totalV));

                rowData.distance = newValue;
                rowData.amount = totalV;

                calculateFMKTotal();
              }

              if(columnDef.title === 'MONTHS'){
                totalV = Math.ceil((newValue * ratePerMonthValue));
                setSubTotalROB(subTotalROB + totalV);
                setTotalROB(discount !== null && discount !== 0 ? ((subTotalROB + totalV) - ((subTotalROB + totalV) * (discount/100))) : (subTotalROB + totalV));

                rowData.months = newValue;
                rowData.amount = totalV;

                calculateROBTotal();
              }

              if(columnDef.title === 'DESCRIPTION'){
                rowData.desc = newValue;
              }

              const dataUpdate = [...data];
              const index = rowData.tableData.id;
              dataUpdate[index] = rowData;
              setData([...dataUpdate]);
              resolve();


              // console.log('newValue: ' + newValue);
              // console.log('rowData: ' + JSON.stringify(rowData));
              // console.log('columnDef: ' + JSON.stringify(columnDef));
              // setTimeout(resolve, 1000);
            });
          }
        }}
        components={{
          Pagination: PatchedPagination,
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <div style={{padding: '0px 10px'}}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Client</InputLabel>
                  <Select defaultValue="" value={currClientValue} id="grouped-select" onChange={(e) => setCurrentClient(e.target.value)}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <ListSubheader>BPDev</ListSubheader>
                    {getDevClients()}
                    <ListSubheader>FetchMyKid</ListSubheader>
                    {getFMKclients()}
                    <ListSubheader>BPDev Robotics</ListSubheader>
                    {getROBclients()}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">Payment Method</InputLabel>
                  <Select defaultValue="" value={method} id="grouped-select" onChange={(e) => setMethod(e.target.value)}>
                    {getMethods()}
                  </Select>
                </FormControl>
                {currService === "DEV" ?
                  <>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">Business Type</InputLabel>
                      <Select defaultValue={1} value={businessType} onChange={(e) => {setRatePerHour(e.target.value); setBusinessType(e.target.value); reCalculateAmounts()}} id="grouped-select">
                        <MenuItem value={0}>
                          Start-Up
                        </MenuItem>
                        <MenuItem value={1}>
                          Corporate
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">Rate Per Hour (E)</InputLabel>
                      <Select defaultValue={1} value={ratePerHour} onChange={(e) => {setRatePerHour(e.target.value); setBusinessType(e.target.value)}} id="grouped-select">
                        <MenuItem value={0}>
                          200
                        </MenuItem>
                        <MenuItem value={1}>
                          300
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                  :
                  currService === "FMK" ?
                    <>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="grouped-select">Frequency</InputLabel>
                        <Select defaultValue={3} value={frequency} onChange={(e) => { setRate(e.target.value); setFrequency(e.target.value); reCalculateFMKAmounts()}} id="grouped-select">
                          {
                            tripFrequencies.length !== 0 ? 
                              tripFrequencies.map(item => {
                                return <MenuItem key={"Freq"+item.id} value={item.id}>{item.name}</MenuItem>
                              })
                              :
                              null
                          }
                        </Select>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="grouped-select">Rate Per KM (E)</InputLabel>
                        <Select disabled={true} defaultValue={5} value={ratePerKM}  id="grouped-select">
                          {
                            ratePerKMArr.length !== 0 ? 
                              ratePerKMArr.map(item => {
                                return <MenuItem key={"RPKM"+item.id} value={item.id}>{item.name}</MenuItem>
                              })
                              :
                              null
                          }
                        </Select>
                      </FormControl>
                    </>
                    :
                    null
                }
                <FormControl>
                  <TextField
                    label="Discount"
                    id="standard-start-adornment"
                    variant="standard"
                    sx={{ m: 1, width: '10ch' }}
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </FormControl>
                <PDFDownloadLink document={currService === "DEV" ? <DevDoc /> : currService === "FMK" ? <FMKDoc /> : <ROBDoc />} fileName={receipts ? currService === "DEV" ? ("BPDev-Receipt-" + pad(receipts.length+1, 4)) : currService === "FMK" ? ("FMK-Receipt-" + pad(receipts.length+1, 4)) : ("ROB-Receipt-" + pad(receipts.length+1, 4)) : "Receipt"}>
                  {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 
                    <IconButton
                      variant="contained"
                      color="default"
                      className={classes.button}
                    >
                      <GetAppIcon />
                    </IconButton>
                  }
                </PDFDownloadLink>
                <IconButton
                  color="default"
                  className={classes.button}
                  onClick={() => handleSaveReceipt()}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  color="default"
                  className={classes.button}
                  onClick={() => window.location.reload()}
                >
                  <RefreshIcon />
                </IconButton>
                
              </div>
            </div>
          ),
        }}
      />
      <TableContainer component={Paper}>
        <TableMUI className={classes.table} aria-label="spanning table">
          <TableBodyMUI>
            <TableRowMUI>
              <TableCellMUI rowSpan={3} />
              <TableCellMUI colSpan={2}>Subtotal</TableCellMUI>
              <TableCellMUI align="right">{ccyFormat(currService === "DEV" ? subTotalDEV : currService === "FMK" ? subTotalFMK : subTotalROB)}</TableCellMUI>
            </TableRowMUI>
            <TableRowMUI>
              <TableCellMUI colSpan={2} style={{ textAlign: 'right' }}>Discount ({discount+'%'})</TableCellMUI>
              <TableCellMUI align="right" style={{ width: '100px' }}>
                {ccyFormat(currService === "DEV" ? subTotalDEV * (discount/100) : currService === "FMK" ? subTotalFMK * (discount/100) : subTotalROB * (discount/100))}
              </TableCellMUI>
            </TableRowMUI>
            <TableRowMUI>
              <TableCellMUI colSpan={2}>Total</TableCellMUI>
              <TableCellMUI align="right">{ccyFormat(currService === "DEV" ? totalDEV : currService === "FMK" ? totalFMK : totalROB)}</TableCellMUI>
            </TableRowMUI>
          </TableBodyMUI>
        </TableMUI>
      </TableContainer>
    </>
  );
}
