import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Table from '../../elements/Table';
import apiClient from "../../apiClient";




const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function BusinessType() {
    const classes = useStyles();
    var ratesPerHour;

    useEffect(() => {
        // console.log
        apiClient.get(
          `/api/ratePerHours/`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          })
          .then((res) => {
            res.data.map((item) => {
              ratesPerHour[item.id] = item.name;
              return null;
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }, [ratesPerHour]);
  
    const columns = [
        { 
          title: 'Name', 
          field: 'name',
        },   
        { 
          title: 'Rate Per Hour', 
          field: 'rate_per_hour_id',
          lookup: ratesPerHour
        },   
    ];
  
    return (
        <Container maxWidth="lg" className={classes.container}>
          <Table
           title="Business Types"
           columns={columns}
           api="/api/businessTypes"
           actionsColumnIndex={0}
          />
        </Container>
    )
}
  