// import React, {useEffect, useState, forwardRef} from 'react';
// import MaterialTable from 'material-table';
// import apiClient from "../../apiClient";

// import AddBox from '@material-ui/icons/AddBox';
// import ArrowDownward from '@material-ui/icons/ArrowDownward';
// import Check from '@material-ui/icons/Check';
// import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import ChevronRight from '@material-ui/icons/ChevronRight';
// import Clear from '@material-ui/icons/Clear';
// import DeleteOutline from '@material-ui/icons/DeleteOutline';
// import Edit from '@material-ui/icons/Edit';
// import FilterList from '@material-ui/icons/FilterList';
// import FirstPage from '@material-ui/icons/FirstPage';
// import LastPage from '@material-ui/icons/LastPage';
// import Remove from '@material-ui/icons/Remove';
// import SaveAlt from '@material-ui/icons/SaveAlt';
// import Search from '@material-ui/icons/Search';
// import ViewColumn from '@material-ui/icons/ViewColumn';
// import PatchedPagination from '../PatchedPagination';


// const tableIcons = {
//     Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//     Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//     Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//     Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//     DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//     Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//     Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//     Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//     FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//     LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//     NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//     PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
//     ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//     Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//     SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
//     ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
//     ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
// };



// const Table = (props) => {
//     const [rows, setRows] = useState([]);
//     const tableRef = React.createRef();

//     useEffect(() => {
//         apiClient.get(
//             props.api,
//             {
//               headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
//               'Accept': 'application/json',
//               'Content-Type': 'application/json',
//             })
//             .then((data) => {
//                 setRows(data.data);
//             })
//             .catch((e) => {
//               console.log(e);
//             });
//     }, []);

//     return(
//         <MaterialTable
//             title={props.title}
//             columns={props.columns}
//             data={rows}
//             // data={query =>
//             //     new Promise((resolve, reject) => {
//             //       let url = props.api + '?'
//             //       url += 'per_page=' + query.pageSize
//             //       url += '&page=' + (query.page + 1)
//             //       apiClient.get(
//             //         url,
//             //         {
//             //           headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
//             //           'Accept': 'application/json',
//             //           'Content-Type': 'application/json',
//             //         })
//             //         .then((data) => {
//             //             setRows(data.data);
//             //         })
//             //         .catch((e) => {
//             //           console.log(e);
//             //         });
//             //     })
//             // }
//             icons={tableIcons}
//             options={{
//                 actionsColumnIndex: props.actionsColumnIndex,
//                 exportButton: true,
//                 filtering: props.filtering ? props.filtering : false,
//                 addRowPosition: 'first',
//                 loadingType: 'overlay',
//                 pageSize: props.pageSize ? props.pageSize : 5
//             }}
//             components={{
//                 Pagination: PatchedPagination,
//             }}
//             detailPanel={props.detailPanel}
//             tableRef={tableRef}
//             actions={[
//                 {
//                 icon: 'refresh',
//                 tooltip: 'Refresh Data',
//                 isFreeAction: true,
//                 onClick: () => tableRef.current && tableRef.current.onQueryChange(),
//                 }
//             ]}
//             editable={{
//             onRowAdd: newData =>
//                 new Promise((resolve, reject) => {
//                     apiClient.post(
//                         props.api,
//                         newData,
//                         {
//                           headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
//                           'Accept': 'application/json',
//                           'Content-Type': 'application/json'
//                         })
//                         .then((data) => {
//                             setRows([...data.data.data]);
//                             resolve();
//                         })
//                         .catch((e) => {
//                             reject();
//                         });
//                 }),
//             onRowUpdate: (newData, oldData) =>
//                 new Promise((resolve, reject) => {
//                     apiClient.put(
//                         `${props.api + '/' + oldData.uuid}`,
//                         newData,
//                         {
//                           headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
//                           'Accept': 'application/json',
//                           'Content-Type': 'application/json',
//                         })
//                         .then((data) => {
//                             const dataUpdate = [...data.data.data];
//                             const index = oldData.tableData.id;
//                             dataUpdate[index] = newData;
//                             setRows([...dataUpdate]);
//                             resolve();
//                         })
//                         .catch((e) => {
//                             reject();
//                         });
//                 }),
//             onRowDelete: oldData =>
//                 new Promise((resolve, reject) => {
//                     apiClient.delete(
//                         `${props.api + '/' + oldData.uuid}`,
//                         {
//                           headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
//                           'Accept': 'application/json',
//                           'Content-Type': 'application/json'
//                         })
//                         .then((data) => {
//                             const dataDelete = [...data.data.data];
//                             const index = oldData.tableData.id;
//                             dataDelete.splice(index, 1);
//                             setRows([...dataDelete]);
//                             resolve();
//                         })
//                         .catch((e) => {
//                             reject();
//                         });
//                 }),
//             }}
//         />
//     );
// }

// export default Table;




/* eslint-disable no-unused-vars */
import React, { useEffect, useState, forwardRef, useRef, createRef } from 'react';
//import MaterialTable from 'material-table/core';
import MaterialTable from '@material-table/core';

import apiClient from '../../apiClient';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
// import PatchedPagination from '../patchedPagination';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Table = (props) => {
    const [rows, setRows] = useState();

    useEffect(() => {
        props.data ? setRows(props.data) : console.log('');

        !props.customActions
            ? apiClient
                  .get(props.api ? props.api : props.getApi, {
                      headers: {
                          Authorization: `Bearer ${localStorage.getItem('Token')}`
                      },
                      Accept: 'application/json',
                      'Content-Type': 'application/json'
                  })
                  .then((data) => {
                    setRows(data.data);
                  })
                  .catch((e) => {
                      console.log(e);
                  })
            : console.log('');
    }, []);

    return props.customActions ? (
        <MaterialTable
            title={props.title}
            columns={props.columns}
            data={(query) =>
                new Promise((resolve, reject) => {
                    // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', query);
                    // let url = props.api ? props.api : props.getApi;
                    // url += 'per_page=' + query.pageSize;
                    // url += '&page=' + (query.page + 1);
                    apiClient
                        .get(props.api ? props.api : props.getApi, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('Token')}`
                            },
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        })
                        .then((data) => {
                            resolve({
                                data: data.data
                            });
                        })
                        .catch((e) => {
                            console.log(e);
                            reject();
                        });
                })
            }
            icons={tableIcons}
            options={{
                actionsColumnIndex: props.actionsColumnIndex,
                exportButton: true,
                filtering: props.filtering ? props.filtering : false,
                addRowPosition: 'first',
                loadingType: 'overlay',
               pageSize: props.pageSize ? props.pageSize : 5,
                search: props.search ? props.search : true
            }}
            // components={{
            //     Pagination: PatchedPagination,
            // }}
            components={props.components}
            detailPanel={props.detailPanel}
            onRowClick={props.onRowClick}
            tableRef={props.tableRef}
            actions={props.actions}
        />
    ) : (
        <MaterialTable
            title={props.title}
            columns={props.columns}
            data={rows}
            icons={tableIcons}
            options={{
                actionsColumnIndex: props.actionsColumnIndex,
                exportButton: props.exportButton != null ? props.exportButton : true,
                filtering: props.filtering ? props.filtering : false,
                addRowPosition: 'first',
                loadingType: 'overlay',
                pageSize: props.pageSize ? props.pageSize : 5,
                search: props.search != null ? props.search : true
            }}
            components={props.components}
            detailPanel={props.detailPanel}
            onRowClick={props.onRowClick}
            editable={{
                onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                        apiClient
                            .post(props.api ? props.api : props.baseApi, newData, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('Token')}`
                                },
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            })
                            .then((data) => {
                                setRows([...data.data.data]);
                                resolve();
                            })
                            .catch((e) => {
                                reject();
                            });
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        apiClient
                            .put(`${(props.api ? props.api : props.baseApi) + '/' + oldData.uuid}`, newData, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('Token')}`
                                },
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            })
                            .then((data) => {
                                const dataUpdate = [...data.data.data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                setRows([...dataUpdate]);
                                resolve();
                            })
                            .catch((e) => {
                                reject();
                            });
                    }),
                onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                        apiClient
                            .delete(`${(props.api ? props.api : props.baseApi) + '/' + oldData.uuid}`, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('Token')}`
                                },
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                            })
                            .then((data) => {
                                const dataDelete = [...data.data.data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setRows([...dataDelete]);
                                resolve();
                            })
                            .catch((e) => {
                                reject();
                            });
                    })
            }}
        />
    );
};

export default Table;
