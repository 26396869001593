import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import apiClient from "../../apiClient";
import Table from '../../elements/Table';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function Staff() {
    const classes = useStyles();
  
    var roles = {};

    useEffect(() => {
      apiClient.get(
        `/api/roles/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          res.data.map((item) => {
            roles[item.id] = item.name;
            return null;
          });
        })
        .catch((e) => {
          console.log(e);
        });
      }, [])
  
    const columns = [
        { title: 'Full Name', field: 'name' },
        { title: 'Email', field: 'email' },
        { title: 'Phone', field: 'phone' },
        { title: 'Address', field: 'address' },
        { 
          title: 'Role', 
          field: 'role_id', 
          lookup: roles
        },   
    ];
  
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Table
                title="Staff"
                columns={columns}
                api="/api/users"
                actionsColumnIndex={0}
            />
        </Container>
    )
}
  