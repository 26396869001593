import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import apiClient from "../../apiClient";
import { PDFViewer } from '@react-pdf/renderer';

import Table from '../../elements/Table';
import PayslipGenerator from '../PayslipGenerator';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function Payments() {
    const classes = useStyles();
    var usersArray = [];
    const [users, setUsers] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
      apiClient.get(
        `/api/users/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          res.data.map((item) => {
            usersArray[item.id] = item.name;
            return null;
          });
          setUsers(usersArray);
        })
        .catch((e) => {
          console.log(e);
        });
    }, []);

    useEffect(() => {
      setColumns([
        { title: 'Staff', field: 'user_id', lookup: users, initialEditValue: null},
        { title: 'Date', field: 'date', type: 'datetime', render: rowData => <p>{moment(rowData.date).format("DD MMM YYYY")}</p>},
        { title: 'Gross Salary (E)', field: 'gross_salary', type: 'numeric', align: "left" },
        { title: 'PAYE (E)', field: 'paye', type: 'numeric', align: "left" },
        { title: 'SNPF (E)', field: 'snpf', type: 'numeric', align: "left" },
        { title: 'Net Salary (E)', field: 'net_salary', type: 'numeric', align: "left" },
      ]);
    }, [users]);

  
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Table
                title="Payments"
                columns={columns}
                api="/api/payslips"
                actionsColumnIndex={0}
                detailPanel={rowData => {
                  return (
                    <PDFViewer style={{ width: "100%", height: '100vh'}}>
                      <PayslipGenerator rowData={rowData} />
                    </PDFViewer>
                  )
                }}
            />
        </Container>
    )
}
  