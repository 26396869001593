import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import ChartIncomeExpenses from '../ChartIncomeExpenses';
import ChartBudget from '../ChartBudget';
// import CashBalance from '../DashboardBalances/cash';
// import BankBalance from '../DashboardBalances/bank';
import IncomeRecent from '../DashboardIncomeRecent';
import ExpensesRecent from '../DashboardExpensesRecent';
import DashboardClientsCounter from '../DashboardClientsCounter';
import DashboardChargeCounter from '../DashboardChargeCounter';
import apiClient from "../../apiClient";
import { useState } from 'react';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://bpdteam.com/">
        BPD Team
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 180,
  },
  chartHeight: {
    height: 340,
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const chartHeightPaper = clsx(classes.paper, classes.chartHeight);

  // var charges = {};
  // var clients = {};
  // var income = {};
  // var expenses = {};

  const [chargesCount, setChargesCount] = useState(0);
  const [clientsCount, setClientsCount] = useState(0);


  useEffect(() => {
    apiClient.get(
      `/api/charges/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setChargesCount(res.data.length);
      })
      .catch((e) => {
        console.log(e);
      });

    apiClient.get(
        `/api/clients-dev/`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
        .then((res) => {
          var counter = res.data.length;
          setClientsCount(counter);

          apiClient.get(
            `/api/clients-fmk/`,
            {
              headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            })
            .then((res) => {
              setClientsCount(counter + res.data.length);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
  },[]);

  return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <DashboardClientsCounter clients={clientsCount} lastUpdated={"30 June, 2021"}/>
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <DashboardChargeCounter charges={chargesCount} lastUpdated={"30 June, 2021"}/>
              </Paper>
            </Grid>

            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              {/* <Paper className={fixedHeightPaper}>
                <BankBalance balance={10230} lastUpdated={"30 June, 2021"}/>
              </Paper> */}
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              {/* <Paper className={fixedHeightPaper}>
                <CashBalance balance={5115} lastUpdated={"30 June, 2021"}/>
              </Paper> */}
            </Grid>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={chartHeightPaper}>
                {/* <Typography component="p" variant="h4">
                  2021
                </Typography> */}
                <ChartIncomeExpenses />
              </Paper>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <Paper className={chartHeightPaper}>
                <Typography component="p" variant="h4">
                  2021
                </Typography>
                <ChartBudget />
              </Paper>
            </Grid> */}
            {/* Recent Orders */}
            <Grid item xs={12} md={6} lg={6}>
              <Paper className={classes.paper}>
                <IncomeRecent />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper className={classes.paper}>
                <ExpensesRecent />
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
  );
}