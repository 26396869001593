import React, { createRef, forwardRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Table from "../../elements/Table";
import Logo11 from "../../assets/logos/4.png";
import apiClient from "../../apiClient";

import DeleteDialog from "../../elements/Dialog/delete";

import AddBox from "@material-ui/icons/AddBox";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DelayedCreditDialog from "../../elements/Dialog/delayedcredit";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function DelayedCredit() {
  const classes = useStyles();
  const clientTableRef = createRef();
  const [delayedCreditOpen, setDelayedCreditOpen] = useState(false);
  const [DelayedCreditDeleteOpen, setDelayedCreditDeleteOpen] = useState(false);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [creditNumber, setCreditNumber] = useState("");
  const [subsidiary, setSubsidiary] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("0.0");
  const [delayedCreditOperation, setDelayedCreditOperation] = useState("Add");
  const [delayedCreditUuid, setDelayedCreditUuid] = useState("");
  const [delayedCreditLabel, setDelayedCreditLabel] = useState("");

  const handleClickSaveClient = () => {
    setDelayedCreditOperation("Add");
    setDelayedCreditOpen(true);
  };

  const handleClickEditDelayedCredit = (rowData) => {
    setDelayedCreditOperation("Edit");

    setDelayedCreditUuid(rowData.uuid);
    setName(rowData.name);
    setDate(rowData.date);
    setCreditNumber(rowData.creditNumber)
    setAmount(rowData.amount);
    setDescription(rowData.description);
    setSubsidiary(rowData.subsidiary);

    setDelayedCreditOpen(true);
  };

  const handleCloseDeleteClient = () => {
    setDelayedCreditOpen(false);
  };

  const handleClickDeleteClient = (rowData) => {
    setDelayedCreditOpen(true);
    setDelayedCreditOperation("Delete");
    setDelayedCreditUuid(rowData.uuid);
    setDelayedCreditLabel(
      rowData.name + " " + rowData.date + " " + `(${rowData.date})`
    );
  };

  const handleCloseDelayedCreditDialog = () => {
    setDelayedCreditOpen(false);
    setDelayedCreditUuid("");
    setName("");
    setDate("");
    setDescription("");
    setAmount("0.0");
    setSubsidiary("");
    setCreditNumber("");
  };

  const handleSaveDelayedCredit = () => {
    let newData = {
      name: name,
      date: date,
      creditNumber: creditNumber,
      subsidiary: subsidiary,
      description: description,
      amount: amount,
    };
    if (delayedCreditOperation === "Add") {
      apiClient
        .post("/api/delayedCredits", newData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseDelayedCreditDialog();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    } else if (delayedCreditOperation === "Edit") {
      apiClient
        .put("/api/delayedCredits/" + delayedCreditUuid, newData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseDelayedCreditDialog();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    } else if (delayedCreditOperation === "Delete") {
      apiClient
        .delete("/api/delayedCredits/" + delayedCreditUuid, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
          Accept: "application/json",
          "Content-Type": "application/json",
        })
        .then((data) => {
          handleCloseDeleteClient();
          clientTableRef.current.onQueryChange();
        })
        .catch((e) => {});
    }
  };

  useEffect(() => {}, []);

  const columns = [
    {
      title: "Clint Name",
      field: "name",
    },
    {
      title: "Date",
      field: "date",
    },
    { title: "Delayed Credit No.", field: "creditNumber" },
    { title: "Subsidiary", field: "subsidiary" },
    { title: "Description", field: "description" },
    { title: "Amount", field: "amount" },
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Table
        title={
          <>
            <img src={Logo11} style={{ width: 40, marginTop: "20px" }} alt="" />
            <p style={{ fontSize: "20px" }}> Delayed Credit</p>
          </>
        }
        columns={columns}
        api="/api/delayedCredits/"
        tableRef={clientTableRef}
        actionsColumnIndex={0}
        customActions={true}
        actions={[
          {
            icon: AddBox,
            tooltip: "Add Bond",
            isFreeAction: true,
            onClick: (event) => handleClickSaveClient(),
          },
          {
            icon: Edit,
            tooltip: "Edit Bond",
            onClick: (event, rowData) => {
              handleClickEditDelayedCredit(rowData);
            },
          },
          {
            icon: DeleteOutline,
            tooltip: "Delete Bond",
            onClick: (event, rowData) => {
              handleClickDeleteClient(rowData);
            },
          },
        ]}
      />
      <DelayedCreditDialog
        open={delayedCreditOpen}
        handleClose={handleCloseDelayedCreditDialog}
        handleSave={handleSaveDelayedCredit}
        name={name}
        setName={setName}
        date={date}
        setDate={setDate}
        creditNumber={creditNumber}
        setCreditNumber={setCreditNumber}
        subsidiary={subsidiary}
        setSubsidiary={setSubsidiary}
        description={description}
        setDescription={setDescription}
        amount={amount}
        setAmount={setAmount}
        delayedCreditOperation={delayedCreditOperation}
        setDelayedCreditOperation={setDelayedCreditOperation}
        delayedCreditUuid={delayedCreditUuid}
      />
      {/* <DeleteDialog
        open={delayedCreditOpen}
        handleClose={handleCloseDelayedCreditDialog}
        handleSave={handleSaveDelayedCredit}
        label={delayedCreditLabel}
      /> */}
    </Container>
  );
}
