import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../elements/Title';
import apiClient from "../../apiClient";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function DashboardExpensesRecent() {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    apiClient.get(
      `/api/expenses-recent/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        setData(res.data)
      })
      .catch((e) => {
        console.log(e);
      });
  },[]);

  return (
    <React.Fragment>
      <Title>Recent Expenses</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell>Method</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? 
            data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                <TableCell>{row.paymentMethod}</TableCell>
              </TableRow>
            ))
            :
            null
          }
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" to={`/accounting#expenses`}>
          See more
        </Link>
      </div>
    </React.Fragment>
  );
}