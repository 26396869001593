import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Table from '../../elements/Table';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }));

export default function PaymentMethod() {
    const classes = useStyles();
  
    const columns = [
        { 
          title: 'Method', 
          field: 'name',
        },   
    ];
  
    return (
        <Container maxWidth="lg" className={classes.container}>
          <Table
           title="Payment Methods"
           columns={columns}
           api="/api/payment-methods"
           actionsColumnIndex={0}
          />
        </Container>
    )
}
  